import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  grow: {
    width: '100%',
    height: '100%',
  },
  paper: {
    borderRadius: 0,
  },
  noPadding: {
    padding: '0 !important',
  },
  titleRoot: {
    flex: 1,
  },
  invitationRoot: {
    marginRight: 8,
  },
  invitationButton: {
    marginRight: 8,
  },
  title: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    flex: '0 0 auto',
  },
  noTitle: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px 24px 0px 24px',
    flex: '0 0 auto',
  },
}));
