import moment from 'moment';

const DEFAULT_FORMAT = 'YYYY-MM-DD';

const DISPLAY_FORMAT = 'MMMM DD YYYY';
const DISPLAY_TIME_FORMAT = 'HH:mm';

const zeroPad = (num, places) => String(num).padStart(places, '0');

const DATE = format => moment().format(format);

const format = (date, dateFormat = DEFAULT_FORMAT) =>
  DATE(dateFormat).format(date);

const displayDate = date => moment(date).format(DISPLAY_FORMAT);

const displayTime = date => moment(date).format(DISPLAY_TIME_FORMAT);

const isSameDay = (a, b) => moment(a).isSame(b, 'day');

const today = format => moment().format(format);

const fromNow = (duration = 0, unit = 'days') =>
  moment().add(duration, unit).format();

const displayTodayDate = () => {
  const date = today();
  return displayDate(date);
};

const displayTodayTime = () => {
  const date = today();
  return displayTime(date);
};

const diffInUnit = (a, b, unit = 'd') =>
  moment.utc(b).startOf(unit).diff(moment.utc(a).startOf(unit), unit);

const add = (date, duration, unit = 's') =>
  moment.utc(date).add(duration, unit);

const dateNowUtc = () => moment.utc();
const dateUtcInTimestamp = timestamp => moment.utc(timestamp);

const countdown = (a, b) => {
  const diffTime = diffInUnit(a, b, 'milliseconds');
  const duration = moment.duration(diffTime, 'milliseconds');

  return {
    days: duration.days(),
    hours: zeroPad(duration.hours(), 2),
    minutes: zeroPad(duration.minutes(), 2),
    seconds: zeroPad(duration.seconds(), 2),
  };
};

const getEstimatedEndDate = (a, b) => {
  const diffTime = (b - a) * 16;
  const endDate = moment().add(diffTime, 's').format('MMMM DD, YYYY');
  return endDate;
};

export const DATE_FORMAT = {
  date: DISPLAY_FORMAT,
  time: DISPLAY_TIME_FORMAT,
  default: DEFAULT_FORMAT,
};

export const MOMENT = {
  add,
  today,
  format,
  fromNow,
  isSameDay,
  countdown,
  dateNowUtc,
  diffInUnit,
  displayTime,
  displayDate,
  displayTodayDate,
  displayTodayTime,
  dateUtcInTimestamp,
  getEstimatedEndDate,
};
