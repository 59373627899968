import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Footer, Navbar, Topbar } from './components';
import Particles from 'react-tsparticles';
import useAuth from 'hooks/useAuth';
import Background from 'assets/images/body-background.png';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    minHeight: '100vh',
    fontWeight: 200,
    color: '#fff',
    height: '100%',
    position: 'relative',
  },
  main: {
    top: 0,
    left: 0,
    zIndex: 3,
    width: '100%',
    position: 'absolute',
  },
  background: {
    top: 0,
    left: 0,
    zIndex: 1,
    minHeight: '100vh',
    width: '100%',
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundColor: '#000000',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center',
    backgroundImage: `url(${Background})`,
  },
  content: {
    zIndex: 1,
    width: '100%',
  },
  shiftContent: {
    paddingLeft: 200,
  },
  netContainer: {},
  particles: {
    bottom: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    maxHeight: '60vh',
    overflow: 'hidden',
  },
  particleCanvas: {
    position: 'absolute !important',
  },
}));

const Main = props => {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  return (
    <div className={classes.root}>
      <Topbar
        open={openNavBarMobile}
        onSidebarOpen={() => setOpenNavBarMobile(true)}
      />
      <Navbar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />
      <main className={clsx(classes.main, classes.background)}>
        <div
          className={clsx(classes.content, {
            [classes.shiftContent]: isDesktop,
          })}
        >
          {children}
        </div>
        <Particles
          className={classes.particles}
          canvasClassName={classes.particleCanvas}
          style={{
            width: '100%',
            overflow: 'hidden',
            inset: 0,
            position: 'absolute',
          }}
          options={{
            fpsLimit: 60,
            particles: {
              number: {
                value: 88,
                density: {
                  enable: true,
                  value_area: 700,
                },
              },
              color: {
                value: '#e6007a',
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#e6007a',
                },
                polygon: {
                  nb_sides: 15,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 1.5,
                  opacity_min: 0.15,
                  sync: false,
                },
              },
              size: {
                value: 2.5,
                random: false,
                anim: {
                  enable: true,
                  speed: 2,
                  size_min: 0.15,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 110,
                color: '#e6007a',
                opacity: 0.25,
                width: 1,
              },
              move: {
                enable: true,
                speed: 1.6,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: false,
                  mode: 'repulse',
                },
                onclick: {
                  enable: false,
                  mode: 'push',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
      </main>
      {/* <img className={classes.background} src={Background} alt="main-bg" /> */}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
