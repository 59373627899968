export const NFT = [
  {
    id: 0,
    name: 'Iris',
    location: 'From Cape Town, South Africa',
    description:
      'She is sensational-looking. She must be in her thirties, but she dresses young, trendily, and gets away with it',
    supply: 3200,
  },
  {
    id: 1,
    name: 'Felicity',
    location: 'From Tegucigalpa, Honduras',
    description:
      "Felicity is 19, small and delicate-featured. Pretty in a flawed, accessible way. She doesn't stop the party when she walks in, butyou'd like to get to know her.",
    supply: 3200,
  },
  {
    id: 2,
    name: 'Saho',
    location: 'Eastern, Land of Boo',
    description:
      'His family was taken when their village was invaded by an enemy tribe. As a samurai, he vowed to find his loved ones, even if that means making the ultimate sacrifice.',
    supply: 751,
  },
  {
    id: 3,
    name: 'CP 5-29',
    location: 'From Valletta, Malta',
    description:
      "Friends call him Dessy. He has many abilities and he's armed with self-defense tech. He often takes jobs in casinos just for fun, without any compensation.",
    supply: 0,
  },
  {
    id: 4,
    name: 'C 7-14',
    location: 'Z City, 2048',
    description:
      "She moves, behaves, and thinks as a human would, but her creator, who has kept her isolated since she was built, does not think she's human enough to have replaced his deceased daughter, since she can transform to any human as well.",
    supply: 0,
  },
  {
    id: 8,
    name: 'Nenita',
    location: '',
    description: '',
    supply: 0,
  },
];
