import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { AppBar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 999,
    display: 'flex',
    boxShadow: 'none',
    position: 'absolute',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  },
  flexGrow: {
    flexGrow: 1,
  },
  icon: {
    fontSize: 48,
  },
  buttons: {
    marginLeft: 16,
    color: theme.palette.primary.main,
  },
  logoMobileLink: {
    flex: 1,
    textAlign: 'center',
  },
  logoMobile: {
    flex: 1,
    width: 80,
  },
  logo: {
    width: 90,
  },
}));

const Topbar = props => {
  const { className, open, onSidebarOpen } = props;
  const classes = useStyles();

  if (open) {
    return null;
  }

  return (
    <Hidden mdUp>
      <IconButton
        className={clsx(classes.root, classes.buttons, className)}
        onClick={onSidebarOpen}
      >
        <MenuIcon className={classes.icon} />
      </IconButton>
    </Hidden>
  );
};

Topbar.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default withRouter(Topbar);
