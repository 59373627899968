import React from 'react';
import useStyles from './styles';
// import Profile from '../Profile';
// import Download from '../Download';
import ConnectWalletButton from '../ConnectWallet';

const Wallet = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.item}>
          {/* <Download /> */}
          <ConnectWalletButton />
          {/* <Profile className={classes.profile} /> */}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
