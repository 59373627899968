import React, { useEffect, useState } from 'react';
import {
  VALID_NETWORK_IDS_DEV,
  VALID_NETWORK_IDS_PROD,
  STAKING_NETWORK_IDS_DEV,
  STAKING_NETWORK_IDS_PROD,
} from 'constants/networkConstants';
import { get } from 'lodash';
import { STORE } from 'store';
import { ethers } from 'ethers';
import StakingContext from 'contexts/StakingContext';
import { useGetAndSet, useStoreValue } from 'react-context-hook';

const StakingProvider = props => {
  const {
    children,
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    isFaceWallet,
    faceWallet,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [wallet, setWallet] = useGetAndSet(STORE.STAKING_WALLET);
  const [validNetwork, setValidNetwork] = useGetAndSet(
    STORE.VALID_STAKING_NETWORK,
  );
  const VALID_NETWORK_IDS =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_ENV === 'production'
      ? VALID_NETWORK_IDS_PROD
      : VALID_NETWORK_IDS_DEV;

  useEffect(() => {
    const checkNetworkValidity = async () => {
      const network = await provider.getNetwork();
      const networkId = network?.chainId || 0;
      // const expectedNetworkId = get(
      //   STAKING_NETWORK_IDS,
      //   stakingNetwork, // ethereum or bsc
      //   STAKING_NETWORK_IDS.ethereum,
      // );
      const isValid = VALID_NETWORK_IDS.includes(networkId);
      await setValidNetwork(isValid);

      setTimeout(() => setLoaded(true), 500);
      return [isValid, networkId];
    };

    const onLogout = async () => {
      setWallet({});
      setValidNetwork(true);
      await logoutOfWeb3Modal();
      window.location.reload();
    };

    const onAccountChanged = async data => {
      if (data.length) {
        await onInitAccount();
      } else {
        await onLogout();
      }
    };

    const onNetworkChanged = async () => {
      if (loaded) {
        await onLogout();
      } else {
        window.location.reload();
      }
    };

    const onInitAccount = async () => {
      setValidNetwork(true);
      const [isValid, networkId] = await checkNetworkValidity();
      if (isValid) {
        const accounts = await provider.listAccounts();
        const wallet = get(accounts, '0', '');
        setWallet({ wallet, networkId });
      }
    };

    if (provider && !isFaceWallet) {
      const p = provider.provider;
      onInitAccount();

      // Subscribe to accounts change
      p.on('accountsChanged', onAccountChanged);

      // Subscribe to network change
      p.on('chainChanged', onNetworkChanged);

      p.on('connect', data => {
        // console.log('Connect', { data });
      });

      // Subscribe to provider disconnection
      p.on('disconnect', () => {
        setWallet({});
      });
    }

    if (isFaceWallet) {
      onInitAccount();
    }
  }, [provider]);

  const onLogout = async () => {
    try {
      setWallet({});
      setLoaded(false);
      setValidNetwork(true);
      await logoutOfWeb3Modal();
    } catch (error) {
      console.error('Logout error:', error);
    }
    // ? Temporary remove reload when clicking Disconnect btn to wait displaying the new modal for disconnect
    // window.location.reload();
  };

  return (
    <StakingContext.Provider
      value={{
        wallet,
        loaded,
        provider,
        faceWallet,
        isFaceWallet,
        validNetwork,
        loadWeb3Modal,
        logoutOfWeb3Modal: onLogout,
      }}
    >
      {children}
    </StakingContext.Provider>
  );
};

export default StakingProvider;
