import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const Toggle = props => {
  const { className, onChange, value, option1, option2 } = props;
  const classes = useStyles();

  const onToggle = value => () => {
    onChange(value);
  };

  return (
    <ul className={clsx(classes.toggle, className)}>
      <li onClick={onToggle(option1)}>
        <Typography
          id={option1}
          variant="body2"
          className={clsx(classes.choice, {
            [classes.activeChoice]: value === option1,
          })}
        >
          {option1}
        </Typography>
      </li>
      <li onClick={onToggle(option2)}>
        <Typography
          id={option2}
          variant="body2"
          className={clsx(classes.choice, {
            [classes.activeChoice]: value === option2,
          })}
        >
          {option2}
        </Typography>
      </li>
      <li
        className={clsx(classes.indicator, {
          [classes.indicator2]: value === option2,
        })}
      />
    </ul>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  option1: PropTypes.string.isRequired,
  option2: PropTypes.string.isRequired,
};

export default Toggle;
