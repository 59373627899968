import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    height: '100%',
    fontWeight: 200,
    color: '#fff',
  },
  content: {
    zIndex: 1,
    display: 'flex',
    borderRadius: 8,
    margin: '0 auto',
    padding: '48px 32px',
    flexDirection: 'column',
    backgroundColor: 'rgb(255, 255, 255, 0.15)',
    alignItems: 'center',
  },
  proceedBtn: {
    fontSize: 16,
    marginTop: 16,
    marginBottom: 12,
    fontWeight: 'bold',
  },
  subtitle: {
    marginTop: 4,
    color: '#FECB00',
  },
  label: {
    minWidth: 170,
    fontWeight: 'bold',
  },
  items: {
    width: '100%',
    margin: '24px 0',
  },
  item: {
    display: 'flex',
  },
}));

export default useStyles;
