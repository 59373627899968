import React, { useContext } from 'react';
import useStyles from '../styles';
import { Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LOGIC_HELPERS } from 'helpers/logic';
import StakingContext from 'contexts/StakingContext';
import { get } from 'lodash';

const Home = props => {
  const { handleNext, handleRedirect } = props;
  const classes = useStyles();

  const { validNetwork, wallet } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();

  const renderWallet = () => {
    const connectedWallet = LOGIC_HELPERS.ifElse(
      address,
      address,
      'WALLET NOT CONNECTED',
    );
    const invalidNetwork = LOGIC_HELPERS.ifElse(
      !validNetwork,
      <b>(Invalid Network)</b>,
      '',
    );

    return (
      <Grid direction="column" container alignItems="center">
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Connected wallet:</b> {connectedWallet} {invalidNetwork}
        </Typography>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Claim PKR - Polygon Upgrade
      </Typography>
      <Button className={classes.button} onClick={handleRedirect}>
        PKR & Claim Contract Audit Report
      </Button>
      <div className={classes.claimContainer}>
        <Typography>
          This is the Claim Portal to replace the PKR you currently have in DEX
          wallet. Please <b>verify link (each character) is correct</b> with the
          Admins on the social media.
        </Typography>
        <Typography className={classes.coloredText}>
          If an Admin/someone DM you FIRST, that is a SCAM.
        </Typography>
        <Typography>
          These tokens are <b>ONLY</b> available to wallet holders as of the
          snapshot time (Monday 24th July 23:59 EDT/03:59 UTC Tuesday 25th
          July), while the exchanges will distribute to their users.
        </Typography>
        <Typography className={classes.process}>The process:</Typography>
        <div className={classes.row}>
          <Typography className={classes.processNum}>1.</Typography>
          <Typography className={classes.processText}>
            Connect your wallet
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.processNum}>2. </Typography>
          <Typography className={classes.processText}>
            Check Eligibility
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.processNum}>3. </Typography>
          <Typography className={classes.processText}>Claim PKR</Typography>
        </div>

        <div className={classes.footer}>
          <Typography>Click next to continue</Typography>
          <ArrowDropDownIcon />
        </div>
      </div>
      <Button
        onClick={handleNext}
        className={clsx(classes.button, classes.nextButton)}
      >
        Next
      </Button>
      {renderWallet()}
    </div>
  );
};

export default Home;
