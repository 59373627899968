import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Modal,
  Input,
  Grid,
  Link,
  Typography,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import { STORE } from 'store';
import Loading from 'components/Loading';
import Paper from '@material-ui/core/Paper';
import { LOGIC_HELPERS } from 'helpers/logic';
import { ETHERS_SERVICE } from 'services/ethers';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import StakingContext from 'contexts/StakingContext';
import { withStyles } from '@material-ui/core/styles';
import { useGetAndSet, useSetStoreValue } from 'react-context-hook';
import TimerIcon from '@material-ui/icons/Timer';
import {
  STAKING_NETWORK_IDS_DEV,
  STAKING_NETWORK_IDS_PROD,
  VALID_NETWORK_IDS_DEV,
  VALID_NETWORK_IDS_PROD,
} from 'constants/networkConstants';
import { MOMENT } from 'helpers/moment';
import TermsModal from 'components/TermsModal';
import { useTheme } from '@material-ui/styles';
import NetworkSwitch from 'components/NetworkSwitch';
import { STRING_HELPERS } from 'helpers/stringAdditions';
import Coin from './assets/coin.png';
import Logo from './assets/logo.svg';
import Metamask from './assets/metamask.svg';
import Torus from './assets/torus.svg';
import WalletConnect from './assets/walletconnect.svg';
import useStyles from './styles';

const Stake = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [showStakeModal, setShowStakeModal] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [unstakeAmount, setUnstakeAmount] = useState(0);
  const [showUnstakeWarning, setShowUnstakeWarning] = useState(false);
  const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false);
  const [balance, setBalance] = useState('0.0');
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEnablePool, setLoadingEnablePool] = useState(false);
  const [loadingStake, setLoadingStake] = useState(false);
  const [loadingUnstake, setLoadingUnstake] = useState(false);
  const [loadingCollect, setLoadingCollect] = useState(false);
  const [loadingDisconnect, setLoadingDisconnect] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [termOpen, setTermOpen] = useState(false);
  const [enabledPool, setEnabledPool] = useState(true);
  const [poolLoading, setPoolLoading] = useState(true);
  const [totalStakers, setTotalStakers] = useState(0);
  const [initialize, setInitialize] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [stakingNetwork, setStakingNetwork] = useGetAndSet(
    STORE.STAKING_NETWORK,
  );
  const [poolInfo, setPoolInfo] = useState({
    countdownUrl: '',
    totalStaked: 0,
    allowance: 0,
    endBlock: 0,
    endsIn: 0,
    earned: 0,
    staked: 0,
    apr: 0,
  });
  const customMdDown = useMediaQuery('@media (max-width: 570px)');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const VALID_NETWORK_IDS =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_ENV === 'production'
      ? VALID_NETWORK_IDS_PROD
      : VALID_NETWORK_IDS_DEV;
  const STAKING_NETWORK_IDS =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_ENV === 'production'
      ? STAKING_NETWORK_IDS_PROD
      : STAKING_NETWORK_IDS_DEV;
  const {
    provider,
    loaded,
    wallet,
    validNetwork,
    loadWeb3Modal,
    logoutOfWeb3Modal,
  } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const maxValue = balance;
  const displayAddress = STRING_HELPERS.renderWalletAddress(address);
  const PinkCheckbox = withStyles({
    root: {
      color: '#e6007a',
      '&$checked': {
        color: '#e6007a',
      },
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);
  const flexBoxStyle = clsx({
    [classes.flexBox]: true,
    [classes.center]: provider && loaded,
  });
  const rootStyle = clsx({
    [classes.root]: true,
    [classes.flexColumn]: provider && loaded,
  });

  const [timeRemaining, setTimeRemaining] = useState(0);
  const [timeRemainingBSC, setTimeRemainingBSC] = useState(0);

  useEffect(async () => {
    const now = MOMENT.dateNowUtc();
    const stakingTime = MOMENT.dateUtcInTimestamp(1640430000000);
    const diffInSeconds = MOMENT.diffInUnit(now, stakingTime, 's');
    if (diffInSeconds > 0) {
      setTimeRemainingBSC(diffInSeconds); //convert to setTimeRemaining for old countdown
    }
  }, []);

  useEffect(() => {
    if (timeRemainingBSC > 0) {
      setTimeout(() => {
        setTimeRemainingBSC(timeRemainingBSC - 1);
      }, 1000);
    }
  }, [timeRemainingBSC]);

  useEffect(() => {
    if (timeRemaining > 0) {
      setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (address) {
      setRefresh(true);
    }
  }, [address]);

  useEffect(() => {
    if (!initialize) {
      setTimeout(() => {
        setInitialize(true);
      }, 500);
    }
  }, [initialize]);

  useEffect(() => {
    const fetchPoolInfo = async () => {
      try {
        setPoolLoading(true);
        const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
        const allowance = await ethersHelper.methods.getStakingAllowance(
          address,
        );
        const totalStaked = await ethersHelper.methods.getTotalStaked();
        const staked = await ethersHelper.methods.getUserInfo(address);
        const earned = await ethersHelper.methods.getPendingReward(address);
        const endsIn = await ethersHelper.methods.getRemainingBlocks();
        const currentBlock = await ethersHelper.methods.getCurrentBlock();
        const endBlock = await ethersHelper.methods.getBonusEndBlock();
        const apr = await ethersHelper.methods.getAPR();
        const countdownUrl = await ethersHelper.methods.getBlockCountdown(
          endBlock,
        );
        const endDate = MOMENT.getEstimatedEndDate(currentBlock, endBlock);
        setPoolInfo({
          apr,
          totalStaked,
          staked,
          earned,
          endsIn,
          endBlock,
          allowance,
          countdownUrl,
          endDate,
        });

        if (parseFloat(allowance)) {
          setEnabledPool(true);
        } else {
          setEnabledPool(false);
        }
        setPoolLoading(false);
        setConfirmTerms(false);
      } catch (e) {
        setPoolLoading(false);
        console.log('ERROR: ', e);
      }
    };

    const fetchUserBalance = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.checkPKRBalance();
      setBalance(res);
    };

    const fetchTotalStaker = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.getTotalStakers();
      setTotalStakers(res);
    };

    const startFetching = async () => {
      const network = await provider.getNetwork();
      const networkId = network?.chainId || 0;
      const isValid = VALID_NETWORK_IDS.includes(networkId);
      const initialized = LOGIC_HELPERS.ifElse(
        [isValid, validNetwork],
        true,
        false,
      );
      if (initialized && refresh) {
        await fetchPoolInfo();
        await fetchUserBalance();
        try {
          await fetchTotalStaker();
        } catch (e) {
          console.log(
            "Total stakers can't be displayed, maximum block height reached.",
          );
        }
        setRefresh(false);
      }
    };

    if (provider && loaded) {
      startFetching();
    }
  }, [provider, loaded, validNetwork, networkId, address, refresh]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseModal = () => {
    setShowStakeModal(false);
    setShowUnstakeModal(false);
    setShowConfirmDisconnect(false);
    setStakeAmount(0);
    setUnstakeAmount(0);
  };

  const handleCloseWarning = () => {
    setShowUnstakeWarning(false);
    setShowUnstakeModal(true);
  };

  const handleOpenWarning = () => {
    setShowUnstakeModal(false);
    setShowUnstakeWarning(true);
  };

  const onMaxInput = () => {
    setStakeAmount(balance);
  };

  const onMaxWithdraw = () => {
    const { staked } = poolInfo;
    setUnstakeAmount(staked);
  };

  const onPoolChange = async () => {
    const expectedNetwork = LOGIC_HELPERS.ifElse(
      stakingNetwork === 'bsc',
      'ethereum',
      'bsc',
    );
    setSwitching(true);
    setStakingNetwork(expectedNetwork);
    window.location.reload();
  };

  const handleChangeStake = e => {
    const pattern =
      /^((\d{1,3})([0-9]{3}){0,1}|(\d{1})([0-9]{3}){0,2}|(\d{1,7}))(\.\d{1,18})?$/i;
    const value = e.target.value;
    const match = value.match(pattern);

    if (match) {
      if (parseFloat(match) > 0 && parseFloat(match) <= parseFloat(maxValue)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    setStakeAmount(value);
  };

  const handleChangeUnstake = e => {
    const pattern =
      /^((\d{1,3})([0-9]{3}){0,1}|(\d{1})([0-9]{3}){0,2}|(\d{1,7}))(\.\d{1,18})?$/i;
    const value = e.target.value;
    const match = value.match(pattern);

    if (match) {
      if (parseFloat(match) > 0 && parseFloat(match) <= parseFloat(maxValue)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }

    setUnstakeAmount(value);
  };

  const onShowStakeModal = () => {
    setShowStakeModal(true);
  };

  const onShowUnstakeModal = () => {
    setShowUnstakeModal(true);
  };

  const onSuccess = message => {
    setRefresh(true);
    setLoading(false);
    setLoadingEnablePool(false);
    setLoadingStake(false);
    setLoadingUnstake(false);
    setLoadingCollect(false);
    setShowStakeModal(false);
    setShowUnstakeModal(false);
    setShowUnstakeWarning(false);
    setUnstakeAmount(0);
    setStakeAmount(0);
    setSnackbar({
      variant: 'success',
      message: message || 'Success!',
    });
  };

  const onError = message => {
    setLoading(false);
    setLoadingEnablePool(false);
    setLoadingStake(false);
    setLoadingUnstake(false);
    setLoadingCollect(false);
    setUnstakeAmount(0);
    setStakeAmount(0);
    setSnackbar({
      variant: 'error',
      message: message || 'Success!',
    });
  };

  const onEnablePool = async () => {
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      setLoadingEnablePool(true);
      await ethersHelper.methods.approveStakingPool();
      setEnabledPool(true);
      onSuccess('Pool enabled successfully!');
    } catch (e) {
      onError('Pool not enabled! Try again later.');
    }
  };

  const onStakePKR = async () => {
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      setLoadingStake(true);
      await ethersHelper.methods.stakePKR(stakeAmount);
      onSuccess('Staked PKR successfully!');
    } catch (e) {
      if (e.code === 4001) {
        onError('User has cancelled/rejected the transaction.');
      } else {
        onError('Staking PKR failed! Try again later.');
      }
    }
  };

  const onDisconnect = async () => {
    setLoadingDisconnect(true);
    await logoutOfWeb3Modal();
    setShowConfirmDisconnect(false);
    setLoadingDisconnect(false);
    setConfirmTerms(false);
  };

  const handleDisconnect = async () => {
    setShowConfirmDisconnect(true);
  };

  const onWithdrawPKR = collect => async () => {
    const amountToWidthdraw = LOGIC_HELPERS.ifElse(collect, 0, unstakeAmount);
    const successMessage = LOGIC_HELPERS.ifElse(
      collect,
      'Reward has been collected successfully!',
      'PKR has been withdrawn successfully!',
    );
    const errorMessage = LOGIC_HELPERS.ifElse(
      collect,
      'Collecting reward failed! Try again later.',
      'Failed to withdraw PKR! Try again later.',
    );
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      if (collect) {
        setLoadingCollect(true);
      } else {
        setLoadingUnstake(true);
      }
      await ethersHelper.methods.withdrawStake(amountToWidthdraw);
      onSuccess(successMessage);
    } catch (e) {
      if (e.code === 4001) {
        onError('User has cancelled/rejected the transaction.');
      } else {
        onError(errorMessage);
      }
    }
  };

  const onConfirmTerms = () => {
    setConfirmTerms(!confirmTerms);
  };

  const onTerms = () => {
    setTermOpen(true);
  };

  const renderAssetHeader = () => {
    if (stakingNetwork === 'bsc') {
      return null;
    }

    return (
      <Grid item className={classes.headerItem}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.stakeHeader}
        >
          STAKERS
        </Typography>
        <Typography
          color="textPrimary"
          variant="inherit"
          className={classes.stakeHeaderSubTitle}
        >
          {totalStakers}
        </Typography>
      </Grid>
    );
  };

  const renderAPR = () => {
    const { apr } = poolInfo;
    const secondExtension = 3;
    const ethMultiplier = 12 / (6 + secondExtension); // 12 months / total staking duration in months
    const bscMultiplier = 12 / (3 + secondExtension); // 12 months / total staking duration in months
    const multiplier = LOGIC_HELPERS.ifElse(
      stakingNetwork === 'ethereum',
      ethMultiplier,
      bscMultiplier,
    );
    const apyValue = apr * multiplier;
    const displayAPY = LOGIC_HELPERS.ifElse(apr === 'N/A', apr, `${apyValue}%`);

    return (
      <div className={classes.headerItem}>
        <Typography color="textSecondary" className={classes.stakeHeaderTitle}>
          APY
        </Typography>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.stakeHeaderSubTitle}
        >
          {displayAPY}
        </Typography>
      </div>
    );
  };

  const renderTotalStaked = () => {
    const { totalStaked } = poolInfo;
    const displayStaked = Math.floor(totalStaked * 100000) / 100000;
    return (
      <div className={classes.headerItem}>
        <Typography color="textSecondary" className={classes.stakeHeaderTitle}>
          TOTAL PKR STAKED
        </Typography>
        <Typography
          variant="h5"
          color="textPrimary"
          className={classes.stakeHeaderSubTitle}
        >
          {displayStaked}
        </Typography>
      </div>
    );
  };

  const renderBlockCountdown = () => {
    const { endBlock, countdownUrl } = poolInfo;

    if (!endBlock || !countdownUrl) {
      return null;
    }

    return (
      <a
        target="_blank"
        href={countdownUrl}
        rel="noopener noreferrer"
        className={classes.countdown}
      >
        <TimerIcon />
      </a>
    );
  };

  const renderEndsIn = () => {
    const { endsIn, endBlock, endDate } = poolInfo;
    const displayText = LOGIC_HELPERS.ifElse(endsIn, endDate, '-');
    const displayCountdown = LOGIC_HELPERS.ifElse(
      endsIn,
      renderBlockCountdown(),
      null,
    );
    const block = LOGIC_HELPERS.pluralise('block', endsIn, true);

    return (
      <div className={classes.headerItem}>
        <Typography color="textSecondary" className={classes.stakeHeaderTitle}>
          ENDS IN
        </Typography>
        <div className={classes.endsBlock}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classes.stakeHeaderSubTitle}
          >
            {/* {displayText} */}
            June 25, 2022
          </Typography>
          {displayCountdown}
        </div>
      </div>
    );
  };

  const renderStakeHeader = () => (
    <>
      {renderAssetHeader()}
      {renderAPR()}
      {renderTotalStaked()}
      {renderEndsIn()}
    </>
  );

  const renderEarned = () => {
    const { earned } = poolInfo;
    const disabledBtn = LOGIC_HELPERS.ifElse(parseFloat(earned), false, true);
    const displayEarned = Math.floor(earned * 100000) / 100000;

    return (
      <Grid container className={classes.paper}>
        <Grid item className={classes.titleHeader}>
          <Typography
            color="textSecondary"
            variant="inherit"
            className={classes.paperHeader}
          >
            PKR EARNED
          </Typography>
          <Typography color="textPrimary" variant="h5">
            {displayEarned}
          </Typography>
        </Grid>
        <Grid item className={classes.paperStakeContent}>
          {loadingCollect ? (
            <Loading
              loading={loadingCollect}
              loadingText="Loading..."
              className={classes.startStakingBtn}
            />
          ) : (
            <Button
              color="textPrimary"
              onClick={onWithdrawPKR(true)}
              className={classes.startStakingBtn}
              disabled={disabledBtn}
            >
              Collect
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderEnablePool = () => {
    const { endsIn } = poolInfo;
    if (!endsIn) {
      return null;
    }
    return (
      <>
        <TermsModal open={termOpen} setOpen={setTermOpen} />
        <Grid container className={classes.enablePoolBtnContainer}>
          <Grid container direction="row" className={classes.termsContainer}>
            <PinkCheckbox
              checked={confirmTerms}
              onChange={onConfirmTerms}
              className={classes.cbox}
            />
            <Typography
              variant="inherit"
              color="textPrimary"
              className={classes.read}
            >
              I have read and agree to the&nbsp;
            </Typography>
            <a onClick={onTerms} className={classes.terms}>
              <Typography variant="inherit">Terms and Conditions</Typography>
            </a>
          </Grid>
          {loadingEnablePool ? (
            <Loading
              loading={loadingEnablePool}
              loadingText="Enabling Pool..."
              className={classes.enablePoolBtn}
            />
          ) : (
            <Button
              className={classes.enablePoolBtn}
              disabled={!confirmTerms}
              onClick={onEnablePool}
              fullWidth
            >
              Enable Pool
            </Button>
          )}
        </Grid>
      </>
    );
  };

  const renderStartStake = () => {
    const { staked } = poolInfo;
    const displayStaked = Math.floor(staked * 100000) / 100000;

    return (
      <Grid container className={classes.paper}>
        <Grid item className={classes.titleHeader}>
          <Typography variant="inherit" className={classes.paperHeader}>
            PKR STAKED
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {displayStaked}
          </Typography>
        </Grid>
        <Grid item className={classes.paperStakeContent}>
          <Button
            className={classes.startStakingBtn}
            color="textPrimary"
            onClick={onShowStakeModal}
          >
            Stake
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderStaking = () => {
    const { staked } = poolInfo;
    const displayStaked = Math.floor(staked * 100000) / 100000;

    return (
      <Grid container className={classes.paper}>
        <Grid item className={classes.titleHeader}>
          <Typography variant="caption" className={classes.paperHeader}>
            PKR STAKED
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {displayStaked}
          </Typography>
        </Grid>
        <div className={classes.stakeButtonContainer}>
          <Button
            className={classes.stakeButton}
            color="textPrimary"
            onClick={onShowStakeModal}
            fullWidth
          >
            Add
          </Button>
          <Button
            className={classes.stakeButton}
            color="textPrimary"
            onClick={onShowUnstakeModal}
            fullWidth
          >
            Unstake
          </Button>
        </div>
      </Grid>
    );
  };

  const renderStakeModalContent = () => {
    const { staked } = poolInfo;
    const displayStaked = Math.floor(staked * 100000) / 100000;

    const { endsIn } = poolInfo;
    const disabledBtn = LOGIC_HELPERS.ifElse(
      Math.ceil(stakeAmount) === 0 || stakeAmount === '' || endsIn === 0,
      true,
      false,
    );
    const displayPoolEnded = LOGIC_HELPERS.ifElse(endsIn === 0, true, false);
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>STAKE</Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseModal}
          />
        </Paper>
        <Typography
          className={classes.modalTitle}
          variant="caption"
          color="textPrimary"
        >
          Amount to stake:
        </Typography>
        <div className={classes.inputRoot}>
          <Input
            type="number"
            value={stakeAmount}
            aria-valuemax={balance}
            onChange={handleChangeStake}
            disableUnderline
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onMaxInput}
                >
                  <Typography variant="subtitle2" className={classes.max}>
                    MAX
                  </Typography>
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography className={classes.splitter}>|</Typography>
          <Typography variant="body2" className={classes.pkr}>
            PKR
          </Typography>
        </div>
        <Typography color="textPrimary" className={classes.balance}>
          Balance: <b>{balance}</b>
        </Typography>
        <>
          {loadingStake ? (
            <Loading
              loading={loadingStake}
              loadingText="Staking PKR..."
              className={classes.confirmButton}
            />
          ) : (
            <Button
              color="textPrimary"
              onClick={onStakePKR}
              className={classes.confirmButton}
              disabled={disabledBtn}
            >
              Confirm
            </Button>
          )}
          {displayPoolEnded ? (
            <Typography className={classes.noteDeposit}>
              <small>Note: End block has been met. Pool has ended.</small>
            </Typography>
          ) : displayStaked ? (
            <Typography className={classes.noteDeposit}>
              <small>
                Note: You will withdraw your earned PKR when you deposit.
              </small>
            </Typography>
          ) : null}
        </>
      </div>
    );
  };

  const renderUnstakeWarning = () => {
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>
            Unstaking Notification
          </Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseWarning}
          />
        </Paper>
        <Typography
          className={classes.warningMessage}
          variant="caption"
          color="textPrimary"
        >
          Unstaking will also withdraw your PKR earned rewards. You are about to
          unstake <b>{unstakeAmount} PKR</b>. Do you want to proceed?
        </Typography>
        {loadingUnstake ? (
          <Loading
            loading={loadingUnstake}
            loadingText="Unstaking PKR..."
            className={classes.confirmButton}
          />
        ) : (
          <Button
            color="textPrimary"
            onClick={onWithdrawPKR(false)}
            className={classes.confirmButton}
          >
            Proceed
          </Button>
        )}
      </div>
    );
  };

  const renderUnstakeModalContent = () => {
    const { staked } = poolInfo;
    const overUnstake = LOGIC_HELPERS.ifElse(
      parseFloat(unstakeAmount) > parseFloat(staked),
      true,
      false,
    );
    const disabledBtn = LOGIC_HELPERS.ifElse(
      Math.ceil(unstakeAmount) === 0 || unstakeAmount === '' || overUnstake,
      true,
      false,
    );
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>UNSTAKE</Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseModal}
          />
        </Paper>
        <Typography
          className={classes.modalTitle}
          variant="caption"
          color="textPrimary"
        >
          Amount to unstake:
        </Typography>
        <div className={classes.inputRoot}>
          <Input
            type="number"
            value={unstakeAmount}
            aria-valuemax={balance}
            onChange={handleChangeUnstake}
            disableUnderline
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onMaxWithdraw}
                >
                  <Typography variant="subtitle2" className={classes.max}>
                    MAX
                  </Typography>
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography className={classes.splitter}>|</Typography>
          <Typography variant="body2" className={classes.pkr}>
            PKR
          </Typography>
        </div>
        <Typography color="textPrimary" className={classes.balance}>
          Balance: <b>{staked}</b>
        </Typography>
        <Button
          color="textPrimary"
          onClick={handleOpenWarning}
          className={classes.confirmButton}
          disabled={disabledBtn}
        >
          Confirm
        </Button>
        {overUnstake ? (
          <Typography className={classes.noteDeposit}>
            <small>Note: You are unstaking greater than your staked PKR</small>
          </Typography>
        ) : (
          <Typography className={classes.noteDeposit}>
            <small>
              Note: You will withdraw your earned PKR alongside your staked PKR.
            </small>
          </Typography>
        )}
      </div>
    );
  };

  const renderConfirmDisconnect = () => {
    return (
      <div className={classes.modalRoot}>
        <Paper elevation={0} className={classes.modalHeader}>
          <Typography className={classes.modalHeaderText}>
            DISCONNECT
          </Typography>
          <ClearIcon
            className={classes.modalHeaderClose}
            onClick={handleCloseModal}
          />
        </Paper>
        <Typography
          className={classes.warningMessage}
          variant="caption"
          color="textPrimary"
        >
          You will disconnect your wallet. Do you want to proceed?
        </Typography>
        <Button
          color="textPrimary"
          onClick={onDisconnect}
          className={classes.confirmButton}
        >
          Proceed
        </Button>
        <Modal open={loadingDisconnect} className={classes.loaderModal}>
          <Typography className={classes.loadingText}>
            Disconnecting Wallet...
          </Typography>
        </Modal>
      </div>
    );
  };

  const renderStakeContent = () => {
    const { staked } = poolInfo;
    const renderCard = LOGIC_HELPERS.ifElse(
      parseFloat(staked),
      renderStaking,
      renderStartStake,
    );

    return (
      <Grid container spacing={0} className={classes.stakeContent}>
        <Grid item className={classes.card}>
          {renderCard()}
        </Grid>
        <Grid item className={classes.card}>
          {renderEarned()}
        </Grid>
      </Grid>
    );
  };

  const renderStakeEntry = id => {
    const isExpanded = expanded === id;
    const displayText = LOGIC_HELPERS.ifElse(isExpanded, 'Hide', 'Details');
    const displayAdd = LOGIC_HELPERS.ifElse(
      customMdDown,
      displayAddress,
      address + ' -',
    );
    const { endsIn } = poolInfo;
    const displayPoolMessage = LOGIC_HELPERS.ifElse(
      enabledPool,
      ' Please unstake your tokens.',
      '',
    );
    const displayPoolEnded = LOGIC_HELPERS.ifElse(
      endsIn,
      null,
      `Pool is no longer distributing rewards.${displayPoolMessage}`,
    );

    const networkPool = LOGIC_HELPERS.ifElse(
      stakingNetwork === 'ethereum',
      'ETH',
      'BSC',
    );

    return (
      <Grid container className={clsx(classes.note, classes.flexColumn)}>
        <div className={classes.mainHeader}>
          <img className="text-logo" src={Logo} />
          <Typography variant="h6" className={classes.title}>
            {networkPool} STAKING POOL
          </Typography>
        </div>
        {timeRemainingBSC ? renderBSCCountdownInContent() : null}
        <div className={classes.textLine} />
        {!endsIn && !poolLoading && (
          <Typography variant="subtitle1" className={classes.poolEnded}>
            {displayPoolEnded}
          </Typography>
        )}
        <Grid container className={clsx(classes.content, classes.flexColumn)}>
          <Grid className={classes.head}>
            <Grid
              container
              className={clsx(classes.headerRoot, classes.headerContent)}
            >
              {renderStakeHeader()}
            </Grid>
            <div
              className={classes.noteBody}
              style={{ textAlign: enabledPool ? 'end' : 'center' }}
            >
              {poolLoading ? (
                <Loading
                  className={classes.loadingMargin}
                  loading={loading}
                  loadingText="Please wait..."
                />
              ) : enabledPool ? (
                renderStakeContent()
              ) : (
                renderEnablePool()
              )}
              {!poolLoading ? (
                <div className={classes.connectedWalletContainer}>
                  <Typography
                    className={classes.connectedWallet}
                    variant="inherit"
                    color="textPrimary"
                    gutterBottom
                  >
                    <b>Wallet:</b> {displayAdd} &nbsp;
                  </Typography>
                  <Link
                    href="#"
                    onClick={handleDisconnect}
                    className={classes.disconnect}
                    variant="body1"
                    gutterBottom
                  >
                    Disconnect
                  </Link>
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <div className={{ [classes.modalContainer]: isDesktop }}>
          <Modal open={showStakeModal} onClose={handleCloseModal}>
            {renderStakeModalContent()}
          </Modal>
          <Modal open={showUnstakeModal} onClose={handleCloseModal}>
            {renderUnstakeModalContent()}
          </Modal>
          <Modal open={showUnstakeWarning} onClose={handleCloseWarning}>
            {renderUnstakeWarning()}
          </Modal>
          <Modal open={showConfirmDisconnect} onClose={handleCloseModal}>
            {renderConfirmDisconnect()}
          </Modal>
        </div>
      </Grid>
    );
  };

  const renderInvalidNetwork = () => {
    const networkPool = LOGIC_HELPERS.ifElse(
      stakingNetwork === 'ethereum',
      'ETH',
      'BSC',
    );
    const expectedNetwork = LOGIC_HELPERS.ifElse(
      stakingNetwork === 'ethereum',
      'Polygon',
      'Binance Smart Chain',
    );
    const switchPool = LOGIC_HELPERS.ifElse(
      stakingNetwork === 'ethereum',
      'BSC',
      'ETH',
    );

    return (
      <Grid container className={clsx(classes.note, classes.flexLeft)}>
        <div className={classes.mainHeader}>
          <img className="text-logo" src={Logo} />
          <Typography variant="h6" className={classes.title}>
            {networkPool} STAKING POOL
          </Typography>
        </div>
        <Typography
          variant="h6"
          color="textPrimary"
          className={classes.networkInvalid}
        >
          WRONG NETWORK!
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
          className={classes.invalidText}
        >
          Please change to {expectedNetwork} network if you want to stake in{' '}
          {networkPool} staking pool.
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          Wrong staking pool?
        </Typography>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={onPoolChange}
        >
          Switch to {switchPool} Staking Pool
        </Button>
      </Grid>
    );
  };

  const renderWalletToggle = () => {
    if (timeRemainingBSC) {
      return null;
    }
    return (
      <>
        <Typography variant="inherit" className={classes.padded}>
          Select staking network
        </Typography>
        <NetworkSwitch reload={false} />
      </>
    );
  };

  const renderConnectProvider = () => {
    let content;
    if (timeRemaining) {
      content = renderStartCountdown();
    } else {
      content = (
        <div className={classes.flexColumn}>
          <Typography variant="inherit" className={classes.padded}>
            Select staking network
          </Typography>
          <NetworkSwitch reload={false} />
          <Typography
            variant="inherit"
            className={clsx(classes.grayText, classes.padded)}
          >
            Connect to a wallet provider first
          </Typography>
          <div className={classes.walletRoot}>
            <Typography className={classes.walletHeader}>
              Supported Wallets:
            </Typography>
            <div className={classes.wallets}>
              <div className={classes.wallet}>
                <img
                  className={classes.walletLogo}
                  src={Metamask}
                  alt="metamask-logo"
                />
                <Typography className={classes.walletLabel}>
                  Metamask
                </Typography>
              </div>
              <div className={classes.wallet}>
                <img
                  className={classes.walletLogo}
                  src={WalletConnect}
                  alt="walletconnect-logo"
                />
                <Typography className={classes.walletLabel}>
                  Wallet Connect
                </Typography>
              </div>
              {stakingNetwork === 'ethereum' ? (
                <div className={classes.wallet}>
                  <img
                    className={classes.walletLogo}
                    src={Torus}
                    alt="torus-logo"
                  />
                  <Typography className={classes.walletLabel}>Torus</Typography>
                </div>
              ) : null}
            </div>
          </div>
          <Button
            fullWidth
            variant="contained"
            onClick={loadWeb3Modal}
            className={clsx(classes.button, classes.connect)}
            disabled={timeRemaining}
          >
            Connect Wallet
          </Button>
        </div>
      );
    }

    return (
      <div className={clsx(classes.note, classes.flexColumn)}>
        <div className={classes.mainHeader}>
          <img className="text-logo" src={Logo} />
          <Typography variant="h6" className={classes.title}>
            STAKING POOL
          </Typography>
        </div>
        <div className={classes.textLine} />
        {content}
      </div>
    );
  };

  const renderStartCountdownBSC = () => {
    if (!timeRemainingBSC) {
      return null;
    }
    const now = MOMENT.dateNowUtc();
    // const endDate = MOMENT.dateUtcInTimestamp(1632585600000);
    const endDate = MOMENT.add(now, timeRemainingBSC, 's');
    const countdown = MOMENT.countdown(now, endDate);
    const daysText =
      STRING_HELPERS.pluralise('Day', parseInt(countdown.days)) || 'Day';
    const days = countdown.days;

    const hoursText =
      STRING_HELPERS.pluralise('Hour', parseInt(countdown.hours)) || 'Hour';
    const hours = countdown.hours;

    const minutesText =
      STRING_HELPERS.pluralise('Minute', parseInt(countdown.minutes)) ||
      'Minute';
    const minutes = countdown.minutes;

    const secondsText =
      STRING_HELPERS.pluralise('Second', parseInt(countdown.seconds)) ||
      'Second';
    const seconds = countdown.seconds;
    return (
      <div className={classes.countdownTimer}>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{days}</div>
          <div className={classes.timeLabel}>{daysText}</div>
        </div>
        <div className={classes.timeSeparator}>:</div>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{hours}</div>
          <div className={classes.timeLabel}>{hoursText}</div>
        </div>
        <div className={classes.timeSeparator}>:</div>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{minutes}</div>
          <div className={classes.timeLabel}>{minutesText}</div>
        </div>
        <div className={classes.timeSeparator}>:</div>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{seconds}</div>
          <div className={classes.timeLabel}>{secondsText}</div>
        </div>
      </div>
    );
  };

  const renderStartCountdown = () => {
    if (!timeRemaining) {
      return null;
    }
    const now = MOMENT.dateNowUtc();
    // const endDate = MOMENT.dateUtcInTimestamp(1632585600000);
    const endDate = MOMENT.add(now, timeRemaining, 's');
    const countdown = MOMENT.countdown(now, endDate);
    const daysText =
      STRING_HELPERS.pluralise('Day', parseInt(countdown.days)) || 'Day';
    const days = countdown.days;

    const hoursText =
      STRING_HELPERS.pluralise('Hour', parseInt(countdown.hours)) || 'Hour';
    const hours = countdown.hours;

    const minutesText =
      STRING_HELPERS.pluralise('Minute', parseInt(countdown.minutes)) ||
      'Minute';
    const minutes = countdown.minutes;

    const secondsText =
      STRING_HELPERS.pluralise('Second', parseInt(countdown.seconds)) ||
      'Second';
    const seconds = countdown.seconds;
    return (
      <div className={classes.countdownTimer}>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{days}</div>
          <div className={classes.timeLabel}>{daysText}</div>
        </div>
        <div className={classes.timeSeparator}>:</div>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{hours}</div>
          <div className={classes.timeLabel}>{hoursText}</div>
        </div>
        <div className={classes.timeSeparator}>:</div>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{minutes}</div>
          <div className={classes.timeLabel}>{minutesText}</div>
        </div>
        <div className={classes.timeSeparator}>:</div>
        <div className={classes.timeContainer}>
          <div className={classes.time}>{seconds}</div>
          <div className={classes.timeLabel}>{secondsText}</div>
        </div>
      </div>
    );
  };

  const renderBSCCountdown = () => {
    let content = renderStartCountdownBSC();
    if (provider) {
      return null;
    }
    return (
      <div>
        <div className={clsx(classes.alignCenter, classes.titleHeader)}>
          <Typography variant="inherit" className={classes.padded}>
            BSC Staking will go live in:
          </Typography>
        </div>
        {content}
      </div>
    );
  };

  const renderBSCCountdownInContent = () => {
    let content = renderStartCountdownBSC();
    if (!provider) {
      return null;
    }
    return (
      <div>
        <div className={clsx(classes.alignCenter, classes.titleHeader)}>
          <Typography variant="inherit" className={classes.padded}>
            BSC staking will go live in
          </Typography>
        </div>
        {content}
      </div>
    );
  };

  const renderContent = () => {
    if (!loaded) {
      return renderConnectProvider();
    }

    if (switching) {
      return (
        <Loading loading={switching} loadingText="Switching staking pool..." />
      );
    }

    if (!validNetwork) {
      return renderInvalidNetwork();
    }

    if (!provider) {
      return renderConnectProvider();
    }

    return <div className={classes.container}>{renderStakeEntry(1)}</div>;
  };

  if (!initialize) {
    return (
      <Loading
        loadingText="Please wait..."
        loading
        className={clsx(classes.root, classes.flexColumn)}
        iconOnly
      />
    );
  }

  return (
    <div className={rootStyle}>
      {/* <!-- STAR ANIMATION --> */}
      <div className={classes.bgAnimation}>
        <div className={classes.stars2} />
      </div>
      {/* <!-- / STAR ANIMATION --> */}
      {provider && loaded ? null : (
        <section className={classes.coinSection}>
          <img src={Coin} />
        </section>
      )}
      {timeRemainingBSC ? renderBSCCountdown() : null}
      <section className={flexBoxStyle}>{renderContent()}</section>
    </div>
  );
};

export default Stake;
