import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid, Button, Typography, useMediaQuery } from '@material-ui/core';
import { get } from 'lodash';
import { useBalances } from 'hooks/balances';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSetStoreValue } from 'react-context-hook';
import { usePasses } from 'hooks/passes';
import { LOGIC_HELPERS } from 'helpers/logic';
import Loading from 'components/Loading';
import { useTheme } from '@material-ui/styles';
import { MOMENT } from 'helpers/moment';
import { CHIPS_API } from 'apis/chips';
import { STORE } from 'store';
import Wallet from 'views/Shop/components/Wallet';
import useStyles from './styles';

const BuyChips = () => {
  const classes = useStyles();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const { data: userHasPass, refetch: refretchUserHasPass } = usePasses();
  const { data: balances, refetch: refetchBalances } = useBalances();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const chipBalance = get(balances, '1.amount', '0.0');

  useEffect(() => {
    const setDealValues = async () => {
      if (userHasPass?.length > 0) {
        const ownedPass = get(userHasPass, '0.pkrPasses.name', '');
        if (ownedPass === 'Bronze') {
          setDeals([
            {
              id: 1,
              chips: '2,000',
              value: 194,
              disabled: true,
              image: '1.png',
            },
            {
              id: 2,
              chips: '5,000',
              value: 475,
              disabled: true,
              image: '2.png',
            },
            {
              id: 3,
              chips: '20,000',
              value: 1940,
              disabled: true,
              image: '4.png',
            },
            {
              id: 4,
              chips: '50,000',
              value: 4850,
              disabled: true,
              image: '3.png',
            },
            {
              id: 5,
              chips: '100,000',
              value: 9700,
              disabled: true,
              image: '5.png',
            },
            {
              id: 6,
              chips: '200,000',
              value: 19400,
              disabled: true,
              image: '6.png',
            },
            {
              id: 7,
              chips: '500,000',
              value: 48500,
              disabled: true,
              image: '7.png',
            },
            {
              id: 8,
              chips: '1,000,000',
              value: 97000,
              disabled: true,
              image: '7.png',
            },
          ]);
        } else if (ownedPass === 'Silver') {
          setDeals([
            {
              id: 1,
              chips: '2,000',
              value: 190,
              disabled: true,
              image: '1.png',
            },
            {
              id: 2,
              chips: '5,000',
              value: 475,
              disabled: true,
              image: '2.png',
            },
            {
              id: 3,
              chips: '20,000',
              value: 1900,
              disabled: true,
              image: '4.png',
            },
            {
              id: 4,
              chips: '50,000',
              value: 4750,
              disabled: true,
              image: '3.png',
            },
            {
              id: 5,
              chips: '100,000',
              value: 9500,
              disabled: true,
              image: '5.png',
            },
            {
              id: 6,
              chips: '200,000',
              value: 19000,
              disabled: true,
              image: '6.png',
            },
            {
              id: 7,
              chips: '500,000',
              value: 47500,
              disabled: true,
              image: '7.png',
            },
            {
              id: 8,
              chips: '1,000,000',
              value: 95000,
              disabled: true,
              image: '7.png',
            },
          ]);
        } else if (ownedPass === 'Gold') {
          setDeals([
            {
              id: 1,
              chips: '2,000',
              value: 160,
              disabled: true,
              image: '1.png',
            },
            {
              id: 2,
              chips: '5,000',
              value: 400,
              disabled: true,
              image: '2.png',
            },
            {
              id: 3,
              chips: '20,000',
              value: 1600,
              disabled: true,
              image: '4.png',
            },
            {
              id: 4,
              chips: '50,000',
              value: 4000,
              disabled: true,
              image: '3.png',
            },
            {
              id: 5,
              chips: '100,000',
              value: 8000,
              disabled: true,
              image: '5.png',
            },
            {
              id: 6,
              chips: '200,000',
              value: 16000,
              disabled: true,
              image: '6.png',
            },
            {
              id: 7,
              chips: '500,000',
              value: 40000,
              disabled: true,
              image: '7.png',
            },
            {
              id: 8,
              chips: '1,000,000',
              value: 80000,
              disabled: true,
              image: '7.png',
            },
          ]);
        } else if (ownedPass === 'Platinum') {
          setDeals([
            {
              id: 1,
              chips: '2,000',
              value: 140,
              disabled: true,
              image: '1.png',
            },
            {
              id: 2,
              chips: '5,000',
              value: 350,
              disabled: true,
              image: '2.png',
            },
            {
              id: 3,
              chips: '20,000',
              value: 1400,
              disabled: true,
              image: '4.png',
            },
            {
              id: 4,
              chips: '50,000',
              value: 3500,
              disabled: true,
              image: '3.png',
            },
            {
              id: 5,
              chips: '100,000',
              value: 7000,
              disabled: true,
              image: '5.png',
            },
            {
              id: 6,
              chips: '200,000',
              value: 14000,
              disabled: true,
              image: '6.png',
            },
            {
              id: 7,
              chips: '500,000',
              value: 35000,
              disabled: true,
              image: '7.png',
            },
            {
              id: 8,
              chips: '1,000,000',
              value: 70000,
              disabled: true,
              image: '7.png',
            },
          ]);
        } else if (ownedPass === 'Diamond') {
          setDeals([
            {
              id: 1,
              chips: '2,000',
              value: 100,
              disabled: true,
              image: '1.png',
            },
            {
              id: 2,
              chips: '5,000',
              value: 250,
              disabled: true,
              image: '2.png',
            },
            {
              id: 3,
              chips: '20,000',
              value: 1000,
              disabled: true,
              image: '4.png',
            },
            {
              id: 4,
              chips: '50,000',
              value: 2500,
              disabled: true,
              image: '3.png',
            },
            {
              id: 5,
              chips: '100,000',
              value: 5000,
              disabled: true,
              image: '5.png',
            },
            {
              id: 6,
              chips: '200,000',
              value: 10000,
              disabled: true,
              image: '6.png',
            },
            {
              id: 7,
              chips: '500,000',
              value: 25000,
              disabled: true,
              image: '7.png',
            },
            {
              id: 8,
              chips: '1,000,000',
              value: 50000,
              disabled: true,
              image: '7.png',
            },
          ]);
        }
      } else {
        setDeals([
          {
            id: 1,
            chips: '2,000',
            value: 200,
            disabled: true,
            image: '1.png',
          },
          {
            id: 2,
            chips: '5,000',
            value: 500,
            disabled: true,
            image: '2.png',
          },
          {
            id: 3,
            chips: '20,000',
            value: 2000,
            disabled: true,
            image: '4.png',
          },
          {
            id: 4,
            chips: '50,000',
            value: 5000,
            disabled: true,
            image: '3.png',
          },
          {
            id: 5,
            chips: '100,000',
            value: 10000,
            disabled: true,
            image: '5.png',
          },
          {
            id: 6,
            chips: '200,000',
            value: 20000,
            disabled: true,
            image: '6.png',
          },
          {
            id: 7,
            chips: '500,000',
            value: 50000,
            disabled: true,
            image: '7.png',
          },
          {
            id: 8,
            chips: '1,000,000',
            value: 100000,
            disabled: true,
            image: '7.png',
          },
        ]);
      }
    };
    setDealValues();
  }, [userHasPass]);

  const isDisabledButton = () => {
    const today = MOMENT.dateNowUtc();
    const endDate = MOMENT.dateUtcInTimestamp(1647270000000);
    const diffTime = MOMENT.diffInUnit(today, endDate, 's');

    return diffTime > 0;
  };

  const onSelectBundle = i => {
    const updatedDeals = deals.map((item, index) =>
      LOGIC_HELPERS.ifElse(
        index === i,
        { ...item, disabled: false },
        { ...item, disabled: true },
      ),
    );
    setDeals(updatedDeals);
  };

  const onSubmit = item => async () => {
    try {
      setLoading(true);
      const payload = {
        bundle_id: item.id,
      };
      const res = await CHIPS_API.buy(payload);
      setLoading(false);
      if (res.success) {
        setSnackbar({
          variant: 'success',
          message: 'Success buying chips!',
        });
      } else {
        if (res.error.code === 'INSUFFICIENT_SPADES_BALANCE') {
          setSnackbar({
            variant: 'error',
            message: 'Insufficient SPADES balance',
          });
        } else {
          setSnackbar({
            variant: 'error',
            message: 'Failed buying chips!',
          });
        }
      }
      refetchBalances();
    } catch (e) {
      console.log(e);
      if (e.code === 4001) {
        setSnackbar({
          variant: 'error',
          message: 'User has cancelled/rejected the transaction.',
        });
      } else {
        setSnackbar({
          variant: 'error',
          message: 'Failed buying chips!',
        });
      }
      setLoading(false);
    }
  };

  const renderBalance = () => (
    <Grid direction="row" xs={12} container className={classes.walletRoot}>
      <div className={classes.walletContainer}>
        <Grid direction="column" container>
          <Typography color="textPrimary" className={classes.displayWallet}>
            <b>Balance:</b> {parseInt(chipBalance)} CHIPS
          </Typography>
        </Grid>
      </div>
    </Grid>
  );

  const renderDeals = () => {
    return (
      <Grid xs={12} item className={classes.dealsRoot}>
        <div className={clsx({ [classes.dealsContent]: isDesktop })}>
          <Typography className={classes.dealHeader}>BUY PLAY CHIPS</Typography>
          <Grid
            spacing={4}
            alignItems="center"
            direction="column"
            container
            className={classes.dealsContainer}
          >
            {deals.map((item, index) => {
              return (
                <div className={classes.radioContainer}>
                  <input
                    id={index}
                    type="radio"
                    name="priceId"
                    value={item.value}
                    className={classes.radioButton}
                    onClick={() => onSelectBundle(index)}
                    autoComplete="off"
                  />
                  <div className={classes.dealsCard}>
                    <div className={classes.chipPrice}>
                      <div className={classes.chipRoot}>
                        <Typography variant="h6" className={classes.chipValue}>
                          {item.chips}
                        </Typography>
                        <Typography variant="h6" className={classes.chipText}>
                          Play Chips
                        </Typography>
                      </div>
                      <div className={classes.imageRoot}>
                        <LazyLoadImage
                          width={200}
                          height={242}
                          effect="blur"
                          visibleByDefault
                          className={classes.image}
                          src={`/images/chips/${item.image}`}
                        />
                        <div className={classes.priceRoot}>
                          <Typography
                            color="textPrimary"
                            className={classes.usd}
                          >
                            {item.value.toLocaleString()} SPADES
                          </Typography>
                          {/* <Typography
                            color="textPrimary"
                            className={classes.approx}
                            variant="subtitle2"
                          >
                            {renderConversion(item)}
                          </Typography> */}
                        </div>
                        <div className={classes.buttonRoot}>
                          {loading && !item.disabled ? (
                            <Loading loadingText="Please wait..." />
                          ) : (
                            <Button
                              onClick={onSubmit(item)}
                              className={classes.buyChips}
                              disabled={item.disabled || isDisabledButton()}
                            >
                              Buy Now
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        md
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <div className={classes.wallet}>
          <Wallet />
        </div>
        {renderBalance()}
        {renderDeals()}
      </Grid>
    </>
  );
};

export default BuyChips;
