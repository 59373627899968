import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  root: {
    '& > *': {
      textAlign: 'justify',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  title: {
    fontSize: 21,
  },
  indent: {
    marginLeft: 36,
  },
}));
