import PKRStaking from './PKRStaking.json';
import PKRTokenERC20 from './PKRTokenERC20.json';
import PKRTokenBEP20 from './PKRTokenBEP20.json';
import PKRDealerNFT from './PKRDealerNFT.json';
import PKRClaimToken from './PKRClaimToken.json';

export const ABI = {
  PKRStaking: PKRStaking.abi,
  PKRDealerNFT: PKRDealerNFT.abi,
  PKRTokenERC20: PKRTokenERC20.abi,
  PKRTokenBEP20: PKRTokenBEP20.abi,
  PKRClaimToken: PKRClaimToken.abi,
};
