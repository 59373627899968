import { URL_HELPERS } from 'helpers/url';

export default [
  {
    id: 1,
    items: [
      // {
      //   id: 0,
      //   anchor: true,
      //   title: 'STAKING',
      //   href: URL_HELPERS.stakingPortal,
      // },
      {
        id: 7,
        title: 'CLAIM PKR',
        href: URL_HELPERS.claimToken,
      },
      {
        id: 5,
        title: 'NFT WALLET',
        href: URL_HELPERS.nftWallet,
      },
      // {
      //   id: 1,
      //   title: 'MARKETPLACE',
      //   href: URL_HELPERS.shop,
      //   disabled: true,
      // },
      // {
      //   id: 2,
      //   title: 'PKR PASS',
      //   href: URL_HELPERS.pkrPass,
      // },
      // {
      //   id: 3,
      //   title: 'SPADES',
      //   href: URL_HELPERS.spades,
      // },
      // {
      //   id: 4,
      //   title: 'CHIPS',
      //   href: URL_HELPERS.chips,
      // },
      // {
      //   id: 6,
      //   title: 'REFERRALS',
      //   href: URL_HELPERS.referrals,
      // },
    ],
  },
];
