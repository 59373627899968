import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { find, get, isArray, isEmpty } from 'lodash';
import { LOGIC_HELPERS } from 'helpers/logic';
import { ETHERS_SERVICE } from 'services/ethers';
import { useConnectWalletMutation, useWallets } from 'hooks/wallets';
import { useSetStoreValue, useStoreValue } from 'react-context-hook';
import { TRANSACTIONS_CONNECT_API } from 'apis/Transactions/connect';
import StakingContext from 'contexts/StakingContext';
import Loading from 'components/Loading';
import { STORE } from 'store';
import useStyles from './styles';
import clsx from 'clsx';

const ConnectWallet = () => {
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const classes = useStyles();

  const {
    provider,
    wallet,
    loaded,
    faceWallet,
    isFaceWallet,
    validNetwork,
    loadWeb3Modal,
    logoutOfWeb3Modal,
  } = useContext(StakingContext);
  const [loading, setLoading] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const stakingNetwork = useStoreValue(STORE.STAKING_NETWORK);
  const walletNetworkId = LOGIC_HELPERS.ifElse(
    stakingNetwork === 'ethereum',
    1,
    2,
  );
  const switchNetwork = LOGIC_HELPERS.ifElse(
    stakingNetwork === 'ethereum',
    'ethereum',
    'bsc',
  );
  const { data: wallets, isLoading, isError } = useWallets(walletNetworkId);
  const { mutateAsync: onConnectWallet } = useConnectWalletMutation();

  const fetchedWallets = isArray(wallets);
  const connected = find(wallets, { address });
  const status = !isEmpty(connected);

  const hasWallet = !isEmpty(wallet);

  useEffect(() => {
    const startConnecting = LOGIC_HELPERS.ifElse(
      [hasWallet, fetchedWallets, !connecting, !status],
      true,
      false,
    );
    if (startConnecting) {
      setConnecting(true);
    }
  }, [connecting, hasWallet, fetchedWallets, status]);

  useEffect(() => {
    const onConnect = async () => {
      try {
        const [ethers, signer] = ETHERS_SERVICE(provider, address, networkId);
        const isTorus = get(provider, 'provider.isTorus', false);
        setLoading(true);

        const requestPayload = {
          network_type_id: walletNetworkId,
        };

        const res = await TRANSACTIONS_CONNECT_API.request(requestPayload);
        let message = res.message;
        let rawSignature;
        if (isTorus) {
          // 66 byte string, which represents 32 bytes of data
          const messageHash = ethers.utils.hashMessage(res.message);
          // 32 bytes of data in Uint8Array
          message = ethers.utils.arrayify(messageHash);
          rawSignature = await provider.getSigner().signMessage(message);
        } else {
          rawSignature = await signer.signMessage(message);
        }
        const signature = await ethers.utils.splitSignature(rawSignature);
        const payload = {
          address,
          nonce: res.nonce,
          v: signature.v,
          r: signature.r,
          s: signature.s,
        };
        await onConnectWallet(payload);
        setSnackbar({
          variant: 'success',
          message: 'Wallet connected successfully!',
        });
        setLoading(false);
        setConnecting(false);
      } catch (e) {
        const code = get(e, 'response.data.code', '');

        let message = '';
        switch (code) {
          case 'E_WALLET_ALREADY_EXISTS':
            message = 'Wallet already used';
            break;
          default:
            message = 'Failed connecting wallet';
        }
        setSnackbar({
          variant: 'error',
          message: message,
        });
        setLoading(false);
        setTimeout(async () => {
          await logoutOfWeb3Modal();
        }, 800);
      }
    };

    if (connecting) {
      onConnect();
    }
  }, [connecting]);

  const disabled = LOGIC_HELPERS.ifElse(
    [isLoading, isError],
    true,
    false,
    true,
  );

  const handleWallet = async () => {
    if (!validNetwork) {
      setSnackbar({
        variant: 'error',
        message: 'Network is invalid! Please change to polygon network.',
      });
    }
    if (!provider) {
      await loadWeb3Modal();
    }
  };

  const onDisconnect = async () => {
    await logoutOfWeb3Modal();
  };

  const onClickShowWallet = async () => {
    await faceWallet.wallet.home();
  };

  if (provider && loaded && validNetwork && !isEmpty(wallet)) {
    if (loading) {
      return (
        <Button
          className={clsx(classes.flexCenter, classes.button)}
          disabled={disabled}
          variant="contained"
          color="primary"
          size="medium"
        >
          <Loading className={classes.loadingIcon} iconOnly />
          Connecting
        </Button>
      );
    }
    return (
      <>
        <Button
          onClick={onDisconnect}
          disabled={disabled}
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
        >
          Disconnect Wallet
        </Button>
        {isFaceWallet ? (
          <Button
            onClick={onClickShowWallet}
            variant="contained"
            color="primary"
            size="small"
            className={clsx(classes.button, classes.showWalletButton)}
          >
            Show face wallet
          </Button>
        ) : null}
      </>
    );
  }
  const buttonText = validNetwork ? 'Connect Wallet' : 'Change Network';

  return (
    <Button
      onClick={handleWallet}
      disabled={disabled}
      variant="contained"
      color="primary"
      size="small"
      className={classes.button}
    >
      {buttonText}
    </Button>
  );
};

export default ConnectWallet;
