import { useQuery } from 'react-query';
import { PKR_PASS_API } from 'apis/PKRPass/pass';

export const PASSES_CACHE_KEY = 'passes';
export const PASSES_LIST_CACHE_KEY = 'passesList';
export const CHECK_PENDING_CACHE_KEY = 'checkPending';
export const PASSES_FIRST_PURCHASE = 'passesFirstPurchase';

export const usePasses = () => useQuery(PASSES_CACHE_KEY, PKR_PASS_API.browse);
export const usePassesList = () =>
  useQuery(PASSES_LIST_CACHE_KEY, PKR_PASS_API.browsePKRPass);
export const useCheckPending = () =>
  useQuery(CHECK_PENDING_CACHE_KEY, PKR_PASS_API.checkPending);
