import { get } from 'lodash';
import { requestAPI } from 'services/request';

const requestConvertPKRToUSDAPI = async ({ ...rest }) =>
  requestAPI('GET', '/assets/convert/pkr-usd', {}, { ...rest });

const convertPKRToUSD = async () => {
  const res = await requestConvertPKRToUSDAPI({});
  return get(res, 'data', {});
};

export const ASSETS_CONVERT_API = {
  convertPKRToUSD,
};
