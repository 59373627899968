import axios from 'axios';
import { isEmpty } from 'lodash';
import { getToken } from 'store';
import authAxios from './interceptedAxios';

const fullUrl = (url, params = '') => {
  // eslint-disable-next-line
  return `${process.env.REACT_APP_POLKER_SERVER_URL}${url}${params}`;
};

export const requestAPI = (
  verb,
  url,
  payload,
  { auth = true, token, params = '' },
) => {
  const URL = fullUrl(url, params);
  let Auth = {};
  let Axios = axios;
  const payloadObject = isEmpty(payload) ? {} : { data: payload };

  if (auth) {
    const jwtToken = token || getToken();
    Auth = { Authorization: `Bearer ${jwtToken}` };
    Axios = authAxios;
  }

  const config = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...Auth,
    },
  };

  return Axios({
    method: verb.toLowerCase(),
    url: URL,
    ...payloadObject,
    ...config,
  });
};

// Upload debugger
// const onUpload = data => console.log('onUpload', { data });

const createFormData = payload => {
  const data = new FormData();

  Object.keys(payload).forEach(key => {
    data.append(key, payload[key]);
  });

  return data;
};

export const uploadAPI = (verb, url, payload = {}, { auth = true }) => {
  const URL = fullUrl(url);

  const action = verb.toLowerCase();

  const formData = createFormData(payload);

  let Auth = {};
  let Axios = axios;

  if (auth) {
    const jwtToken = getToken();
    Auth = {
      Authorization: `Bearer ${jwtToken}`,
    };
    Axios = authAxios;
  }

  return Axios[action](URL, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      ...Auth,
    },
    // onUploadProgress: onUpload
  });
};
