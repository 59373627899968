import { get } from 'lodash';
import { requestAPI } from 'services/request';

const fetchDealersAPI = async ({ ...rest }) =>
  requestAPI('GET', '/dealers/fetch', {}, { ...rest });

const syncDealerAPI = async ({ ...rest }) =>
  requestAPI('POST', '/dealers/sync-dealer', {}, { ...rest });

const fetch = async () => {
  const res = await fetchDealersAPI({});
  return get(res, 'data', {});
};

const sync = async () => {
  const res = await syncDealerAPI({});
  return get(res, 'data', {});
};

export const DEALERS_API = {
  fetch,
  sync,
};
