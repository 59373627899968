import { get } from 'lodash';
import { requestAPI } from 'services/request';

const withdrawNftAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/dealers/withdraw', payload, { ...rest });

const depositNftAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/dealers/deposit', payload, { ...rest });

const withdrawNft = async payload => {
  const res = await withdrawNftAPI({ payload });
  return get(res, 'data', {});
};

const depositNft = async payload => {
  const res = await depositNftAPI({ payload });
  return get(res, 'data', {});
};

export const P2E_API = {
  depositNft,
  withdrawNft,
};
