import React, { useState, useEffect } from 'react';
import { Backdrop, Typography, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';

const Loading = ({
  loading,
  loadingText,
  size = 18,
  className,
  textClassName,
  iconOnly = false,
  determinate = false,
  color = 'secondary',
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10,
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (determinate) {
    return (
      <div className={clsx(classes.root, className)}>
        <CircularProgress variant="determinate" size={size} value={progress} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      {iconOnly ? (
        <CircularProgress size={size} color={color} />
      ) : (
        <>
          <CircularProgress
            size={size}
            color={color}
            className={classes.circle}
          />
          <Typography
            className={clsx(classes.text, textClassName)}
            variant="h6"
          >
            {loadingText}
          </Typography>
        </>
      )}
    </div>
  );
};

export default Loading;
