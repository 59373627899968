import { schema } from 'normalizr';

const newSchema = (key, id = 'id') =>
  new schema.Entity(key, {}, { idAttribute: id });

const balance = newSchema('balance', 'assetId');

const balances = [balance];

export const SCHEMA = {
  newSchema,
  balances,
};
