import React from 'react';
import Page from 'components/Page';
import { URL_HELPERS } from 'helpers/url';
import { LOGIC_HELPERS } from 'helpers/logic';
import { Grid, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const PaymentPending = props => {
  const { history, location } = props;
  const classes = useStyles();

  const params = LOGIC_HELPERS.getQueryParams(location.search);

  const renderForm = () => {
    const onRedirect = () => {
      history.push(URL_HELPERS.shop);
    };

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h4">Payment Pending!</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            We're still processing your payment. Please wait for a few minutes
            for your purchase to reflect
          </Typography>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={onRedirect}
            className={classes.proceedBtn}
          >
            CONTINUE
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Page title="Payment Pending" className={classes.root}>
        <Grid item className={classes.contentRoot}>
          {renderForm()}
        </Grid>
      </Page>
    </>
  );
};

export default PaymentPending;
