import clsx from 'clsx';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LOGIC_HELPERS } from 'helpers/logic';
import useStyles from './styles';

const SimpleDialog = ({
  open,
  grow,
  setOpen,
  title,
  children,
  onCancel,
  onConfirm,
  noPadding,
  customText = {},
  hideTitleOnMobile = false,
  noCancel = false,
  noConfirm = false,
  autoClose = false,
  noButtons = false,
  noCloseButton = true,
  paperClassName,
  contentClassName,
  confirmButtonClassName,
  cancelButtonClassName,
  breakpoint = 'xs',
  maxWidth = 'sm',
  onClose,
  titleSize = 'h5',
  titleColor = '#263238',
  titleClassName,
  fullScreen,
  noTitle,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { confirm = 'Confirm', cancel = 'Cancel', content } = customText;
  const breakpointDown = useMediaQuery(theme.breakpoints.down(breakpoint));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: false,
  });
  const isFullScreen = LOGIC_HELPERS.ifElse(
    [fullScreen, breakpointDown],
    true,
    false,
    true,
  );
  const classes = useStyles();

  const handleLoading = () => {
    setLoading(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    if (onClose) {
      onClose();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    handleClose();
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm({
        onLoading: handleLoading,
        onSuccess: handleClose,
        onError: handleClose,
      });
    }
  };

  const closeProps = autoClose ? { onClose: handleClose } : {};

  const renderTitle = () => {
    const hideOnMobile = LOGIC_HELPERS.ifElse(
      [isMobile, hideTitleOnMobile],
      true,
      false,
    );

    if (!title) return null;

    if (hideOnMobile) {
      return <div className={classes.titleRoot} />;
    }

    return (
      <div className={classes.titleRoot}>
        <Typography style={{ color: titleColor }} variant={titleSize}>
          {title}
        </Typography>
      </div>
    );
  };

  const renderCloseButton = () => {
    if (noCloseButton) return null;

    return (
      <IconButton size="small" className={classes.close} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

  const renderActionButtons = () => {
    if (noButtons) return null;

    return (
      <DialogActions>
        {noCancel ? null : (
          <Button
            className={clsx(cancelButtonClassName)}
            onClick={handleCancel}
            disabled={loading}
            autoFocus
          >
            {cancel}
          </Button>
        )}
        {noConfirm ? null : (
          <Button
            className={clsx(confirmButtonClassName)}
            onClick={handleConfirm}
            disabled={loading}
            active
          >
            {confirm}
          </Button>
        )}
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      fullScreen={isFullScreen}
      {...closeProps}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        className: clsx(classes.paper, paperClassName, {
          [classes.grow]: grow,
        }),
      }}
    >
      {noTitle ? null : (
        <div
          className={clsx({
            [classes.title]: !!title,
            [titleClassName]: titleClassName,
            [classes.noTitle]: !title,
          })}
          id="responsive-dialog-title"
        >
          {renderTitle()}
          {renderCloseButton()}
        </div>
      )}
      <DialogContent
        className={clsx({
          [classes.noPadding]: noPadding,
          [contentClassName]: contentClassName,
        })}
      >
        {content ? <DialogContentText>{content}</DialogContentText> : null}
        {children}
      </DialogContent>
      {renderActionButtons()}
    </Dialog>
  );
};

export default SimpleDialog;
