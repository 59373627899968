import theme from 'theme';
import get from 'lodash/get';
import { STORE } from 'store';
import { USERS_API } from 'apis/user';
import { LOGIC_HELPERS } from 'helpers/logic';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import useAuth from 'hooks/useAuth';
import {
  Tab,
  Tabs,
  Badge,
  Button,
  Avatar,
  Dialog,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSetStoreValue } from 'react-context-hook';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Wallet from '../Shop/components/Wallet';
import useStyles from './styles';

const StyledBadge = withStyles(theme => ({
  badge: {
    color: '#44b700',
    backgroundColor: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}))(Badge);

const Referrals = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const [hasPromo, setHasPromo] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);

  const code = get(auth, 'user.p2p_code', '');

  useEffect(() => {
    const fetchData = async () => {
      const res = await USERS_API.referrals();
      setData(res.referrals);
    };

    fetchData();
  }, []);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { index } = props;

    return LOGIC_HELPERS.switchCase(value, {
      0: renderRegistrations(value, index),
    });
  };

  const onCopy = () => {
    navigator.clipboard.writeText(code);
    setSnackbar({
      variant: 'success',
      message: 'Copied referral code to clipboard!',
    });
  };

  const renderModal = () => {
    if (!openModal) {
      return null;
    }

    return (
      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        classes={{ paper: classes.paper }}
      >
        <Typography variant="h6" className={classes.dialogTitle}>
          <b>REFERRAL PROGRAM</b>
        </Typography>
        <hr className={classes.hr} />
        <Typography variant="subtitle1">
          <b>
            The Polker referral system allows you to earn SPADES by bringing new
            users to the game - it will also give those new users bonus chips
            when they register!
          </b>
        </Typography>
        <div className={classes.howItWorks}>
          <Typography variant="subtitle1">How Does it Work?</Typography>
          <ol>
            <li>
              <Typography>
                All accounts will be issued a unique code (you can find this in
                the account page in the game, and also in the web portal)
              </Typography>
            </li>
            <li>
              <Typography>
                The referral code needs to be added when the user register to
                validate it!
              </Typography>
            </li>
            <li>
              <Typography>
                Accounts who register with the code will be given 2000 extra
                play chips upon registration!
              </Typography>
            </li>
            <li>
              <Typography>
                Account who provided the code will receive 20 Spades per
                registration with the code once the user has registered and made
                a purchase!
              </Typography>
            </li>
          </ol>
        </div>
        <Typography>
          <b>
            For every 10th person who uses the code the provider will get 100
            Spades!
          </b>
        </Typography>
        <Button
          size="large"
          className={classes.closeButton}
          onClick={() => {
            setOpenModal(false);
          }}
        >
          OK
        </Button>
      </Dialog>
    );
  };

  const renderImage = image => {
    if (!image) {
      return null;
    }

    return <img src={`/images/pass/${image}`} className={classes.tableImage} />;
  };

  const renderTier = tier => {
    if (!tier || tier === 0) {
      return <Typography>No PKR Pass</Typography>;
    }

    const image = LOGIC_HELPERS.switchCase(tier, {
      1: 'bronze-pass-icon.png',
      2: 'silver-pass-icon.png',
      3: 'gold-pass-icon.png',
      default: null,
    });
    const description = LOGIC_HELPERS.switchCase(tier, {
      1: 'Bronze Pass',
      2: 'Silver Pass',
      3: 'Gold Pass',
      4: 'Platinum Pass',
      5: 'Diamond Pass',
      default: 'No PKR Pass',
    });

    return (
      <div className={classes.flexCenter}>
        {renderImage(image)}
        <Typography className={classes.text}>{description}</Typography>
      </div>
    );
  };

  const renderAvatar = item => {
    if (!item.is_online) {
      return <Avatar sizes={24} variant="circle" className={classes.avatar} />;
    }

    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar sizes={24} variant="circle" className={classes.avatar} />
      </StyledBadge>
    );
  };

  const renderReferrals = () => {
    if (!data.length) {
      return 'No referrals yet';
    }

    const rowBreak = (
      <div className={classes.rowBreak}>
        <div />
      </div>
    );

    return data.map(item => {
      return (
        <tr className={classes.tableItem}>
          <td data-label="Username">
            <div className={classes.flexCenter}>
              {renderAvatar(item)}
              <Typography className={classes.username}>
                {item.username}
              </Typography>
            </div>
            {rowBreak}
          </td>
          <td data-label="Tiers">
            {renderTier(item.tier)}
            {rowBreak}
          </td>
          <td data-label="Spades Purchased">
            <div className={classes.flexCenter}>
              <img src="/images/spades.png" className={classes.tableImage} />
              <Typography className={classes.text}>
                {item.spades_purchased}
              </Typography>
            </div>

            {rowBreak}
          </td>
          <td data-label="Earned Back">
            <Typography className={classes.text}>
              {item.spades_earned} Spades
            </Typography>
          </td>
        </tr>
      );
    });
  };

  const renderRegistrationTable = () => {
    return (
      <div className={classes.registrationTableRoot} tabIndex="0">
        <table className={classes.registrationTable}>
          <thead>
            <tr>
              <th scope="col">
                <Typography>Username</Typography>
              </th>
              <th scope="col">
                <Typography>Tiers</Typography>
              </th>
              <th scope="col">
                <Typography>Spades Purchased</Typography>
              </th>
              <th scope="col">
                <div className={classes.relative}>
                  <Typography>Earned Back</Typography>
                  <img
                    alt="pass-button"
                    src="/images/info-icon.png"
                    onClick={() => setOpenModal(true)}
                    className={classes.infoIcon}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{renderReferrals()}</tbody>
        </table>
      </div>
    );
  };

  const renderExportButton = () => {
    if (!data.length) {
      return <div />;
    }

    return (
      <CSVLink
        data={data}
        filename={`registration-referrals-${Date.now()}.csv`}
        className={classes.exportButton}
        target="_blank"
      >
        Export CSV
      </CSVLink>
    );
  };

  const renderRegistrations = (value, index) => () => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        className={classes.registrationRoot}
      >
        <div className={classes.registrationHeader}>
          <Typography className={classes.text}>
            REFERRAL CODE: {code}
            <FileCopyIcon onClick={onCopy} className={classes.copyIcon} />
          </Typography>
          <Typography color="textPrimary">REGISTRATION</Typography>
          {renderExportButton()}
        </div>
        {renderRegistrationTable()}
      </div>
    );
  };

  const renderHeader = () => (
    <div className={classes.header}>
      <Wallet hasPromo={hasPromo} setHasPromo={setHasPromo} />
    </div>
  );

  const renderContent = () => (
    <>
      <Tabs
        className={classes.tabRoot}
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            height: 4,
          },
        }}
      >
        <Tab
          value={0}
          label={
            <Typography className={value === 0 ? classes.tab : ''}>
              REFERRALS
            </Typography>
          }
        />
      </Tabs>
      <TabPanel value={value} index={0} />
      {renderHeader()}
    </>
  );

  return (
    <div className={classes.root}>
      {renderContent()}
      {renderModal()}
    </div>
  );
};

export default Referrals;
