import { ethers } from 'ethers';
import { uniqBy, get } from 'lodash';
import { SMART_CONTRACTS } from 'constants/addresses';
// import { ABI } from 'contracts/artifacts';

export const ETHERS_SERVICE = (provider, address, networkId) => {
  // Initialize Ethers
  const signer = provider.getSigner(address); // signer account`
  const CONTRACT = SMART_CONTRACTS[networkId];

  const formatBigNumber = (value, unit = 'ether') => {
    const bn = ethers.BigNumber.from(value);
    return ethers.utils.formatUnits(bn, unit);
  };

  const formatBigNumberToNumber = value => {
    const bn = ethers.BigNumber.from(value);
    return bn.toNumber();
  };

  const parseUnit = (amount, unit = 'ether') =>
    ethers.utils.parseUnits(amount, unit);

  const checkPKRBalance = async (walletAddress = address) => {
    const tokenAbi = get(CONTRACT, 'pkr.abi', null);
    const tokenAddress = get(CONTRACT, 'pkr.address', null);

    if (!tokenAbi || !tokenAddress) {
      return 0;
    }

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const balanceBN = await tokenContract.balanceOf(walletAddress);
    const balance = await formatBigNumber(balanceBN);

    return balance;
  };

  const depositPKR = async amount => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const amountToTransfer = parseUnit(amount);
    return await tokenContract.transfer(
      CONTRACT.serverAddress,
      amountToTransfer,
    );
  };

  const getUserInfo = async address => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.userInfo(address);
    return formatBigNumber(data[0]);
  };

  const getPendingReward = async address => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.pendingReward(address);
    return formatBigNumber(data);
  };

  const getBonusEndBlock = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.bonusEndBlock();
    return formatBigNumberToNumber(data);
  };

  const getStartBlock = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const data = await stakingContract.startBlock();
    return formatBigNumberToNumber(data);
  };

  const getRemainingBlocks = async () => {
    const endBlock = await getBonusEndBlock();
    const currentBlock = await provider.getBlockNumber();

    const remainingBlocks = endBlock - currentBlock;

    if (remainingBlocks < 0) {
      return 0;
    }

    return remainingBlocks;
  };

  const getRewardPerBlock = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );
    const data = await stakingContract.rewardPerBlock();

    return parseFloat(formatBigNumber(data));
  };

  const getTotalStaked = async () => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );
    const data = await stakingContract.totalStaked();

    return parseFloat(formatBigNumber(data));
  };

  const getBlockCountdown = block => `${CONTRACT.countdownUrl}/${block}`;

  const getAPR = async () => {
    const totalStaked = await getTotalStaked();

    if (totalStaked <= 0) {
      return 'N/A';
    }

    const endBlock = await getBonusEndBlock();
    const startBlock = await getStartBlock();
    const rewardPerBlock = await getRewardPerBlock();
    const totalCummulativeReward = parseFloat(
      (endBlock - startBlock) * rewardPerBlock,
    );

    return parseFloat((totalCummulativeReward / totalStaked) * 100).toFixed(2);
  };

  const getTotalStakers = async () => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const filters = await tokenContract.filters.Transfer(
      null,
      CONTRACT.stakePKR.address,
    );
    const filter = {
      fromBlock: 0,
      toBlock: 'latest',
      ...filters,
    };
    const rawLogs = await provider.getLogs(filter);

    if (!rawLogs.length) {
      return 0;
    }

    const iface = new ethers.utils.Interface(CONTRACT.pkr.abi);
    const logs = rawLogs.slice(1); // remove reward provider
    const parsedLogs = logs.map(log => iface.parseLog(log));
    const uniqueStakers = uniqBy(parsedLogs, 'args.0');

    return uniqueStakers.length;
  };

  const stakePKR = async amount => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    const amountToDeposit = parseUnit(amount);
    const { wait: onStakePKR } = await stakingContract.deposit(amountToDeposit);
    return await onStakePKR();
  };

  const withdrawStake = async amount => {
    const contractAbi = CONTRACT.stakePKR.abi;
    const contractAddress = CONTRACT.stakePKR.address;

    const stakingContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer,
    );

    let amountToWithdraw = 0;
    if (amount) {
      amountToWithdraw = parseUnit(amount.toString());
    }

    const { wait: onWithdrawStakedPKR } = await stakingContract.withdraw(
      amountToWithdraw,
    );
    return await onWithdrawStakedPKR();
  };

  const getStakingAllowance = async address => {
    const contractAddress = CONTRACT.stakePKR.address;
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);
    const allowance = await tokenContract.allowance(address, contractAddress);
    return formatBigNumber(allowance);
  };

  const approveStakingPool = async () => {
    const tokenAbi = CONTRACT.pkr.abi;
    const tokenAddress = CONTRACT.pkr.address;
    const contractAddress = CONTRACT.stakePKR.address;

    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, signer);

    const amount = parseUnit('999999999', 'ether');
    const { wait: onApprove } = await tokenContract.approve(
      contractAddress,
      amount,
    );
    return await onApprove();
  };

  const getFutureBlockCountdown = async startBlock => {
    const currentBlockData = await provider.getBlock();
    const currentBlock = currentBlockData.number;
    if (currentBlock >= startBlock) {
      return 0;
    }
    const remainingBlocks = startBlock - currentBlock;
    const currentTimeStamp = currentBlockData.timestamp;
    const blockData = await provider.getBlock(currentBlock - 1000);
    const timeStampDifference = currentTimeStamp - blockData.timestamp;
    const averageTimeStamp = parseInt(timeStampDifference / 1000);
    const remainingTimeStamp = averageTimeStamp * remainingBlocks;
    return remainingTimeStamp;
  };

  const getCurrentBlock = async () => {
    const currentBlock = provider.getBlockNumber();
    return currentBlock;
  };

  const depositDealerNFT = async tokenId => {
    const nftAbi = CONTRACT.dealerNFT.abi;
    const nftAddress = CONTRACT.dealerNFT.address;

    const nftContract = new ethers.Contract(nftAddress, nftAbi, signer);
    return await nftContract.safeTransferFrom(
      address,
      CONTRACT.serverAddress,
      tokenId,
      1,
      '0x00',
    );
  };

  const getBalance = async () => {
    const claimTokenAbi = CONTRACT.claimToken.abi;
    const claimTokenAddresses = CONTRACT.claimToken.addresses;
    let balance = 0;
    let index = -1;

    for (const [i, address] of claimTokenAddresses.entries()) {
      const claimTokenContract = new ethers.Contract(
        address,
        claimTokenAbi,
        signer,
      );

      const tokenBalance = await claimTokenContract.getBalance();
      if (parseFloat(formatBigNumber(tokenBalance))) {
        balance = parseFloat(formatBigNumber(tokenBalance));
        index = i;
        break;
      }
    }

    return { balance, index };
  };

  const claimToken = async index => {
    const claimTokenAbi = CONTRACT.claimToken.abi;
    const claimTokenAddresses = CONTRACT.claimToken.addresses;

    const claimTokenContract = new ethers.Contract(
      claimTokenAddresses[index],
      claimTokenAbi,
      signer,
    );

    const { wait: onClaimToken } = await claimTokenContract.claimToken();

    return await onClaimToken();
  };

  return [
    ethers,
    signer,
    {
      contract: CONTRACT,
      methods: {
        claimToken,
        getBalance,
        depositPKR,
        getUserInfo,
        stakePKR,
        getAPR,
        withdrawStake,
        getTotalStaked,
        getTotalStakers,
        checkPKRBalance,
        getCurrentBlock,
        getPendingReward,
        getBonusEndBlock,
        getBlockCountdown,
        getRemainingBlocks,
        approveStakingPool,
        getStakingAllowance,
        getFutureBlockCountdown,
        depositDealerNFT,
        getStartBlock,
      },
      utils: {
        parseUnit,
        formatBigNumber,
      },
    },
  ];
};
