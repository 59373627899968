import { get } from 'lodash';
import useStyles from './styles';
import { NFT } from 'constants/nft';
import useAuth from 'hooks/useAuth';
import { NFT_API } from 'apis/claimnft';
import { getRefreshToken } from 'store';
import Loading from 'components/Loading';
import { BALANCES_API } from 'apis/balances';
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

const ClaimNFT = () => {
  const auth = useAuth();
  const classes = useStyles();
  const [nft, setNft] = useState('');
  const [claim, setClaim] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [claimedAll, setClaimedAll] = useState(false);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);
  const [emptyRes, setEmptyRes] = useState(false);
  const [disabledReload, setDisabledReload] = useState(false);

  useEffect(() => {
    setDisabledReload(true);
    const claimBetaNFTDetails = async () => {
      setLoading(true);
      const res = await NFT_API.claimBeta();
      return res;
    };

    const claimNFT = async () => {
      try {
        const claimnftres = await claimBetaNFTDetails();
        if (claimnftres.is_transferred_all === true) {
          setClaimedAll(true);
        }
        if (claimnftres.is_transferred === true) {
          setTimeout(async () => await getNFTBalance(), 3000);
          setClaim(true);
        }
        if (claimnftres.is_already_transferred === true) {
          setTimeout(async () => await getNFTBalance(), 3000);
          setAlreadyClaimed(true);
        }
        if (claimnftres.is_transferred === false) {
          setError(true);
        }
      } catch (e) {
        console.log({ e });
        setError(true);
      }
    };

    const getNFTBalance = async () => {
      const res = await BALANCES_API.nft();
      const latestNft = get(res, 'result.0.nft', null);
      if (latestNft) {
        const nftId = get(latestNft, 'metadata.properties.dealer_type', 0);
        const nft = NFT[nftId - 1];
        setNft(nft);
        setClaim(true);
      } else {
        setEmptyRes(true);
      }
      setLoading(false);
    };

    claimNFT();
    setTimeout(() => {
      setDisabledReload(false);
    }, 10000);
  }, []);

  const onReload = async () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  const onLogout = async () => {
    const refresh_token = getRefreshToken();
    const payload = { refresh_token, app: 'store' };
    await auth.signOut(payload);
  };

  const renderLogout = () => (
    <div className={classes.logoutRoot}>
      <Button
        onClick={onLogout}
        variant="text"
        className={classes.logoutButton}
      >
        Logout
      </Button>
    </div>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Loading
          loading={loading}
          loadingText="Claiming nft, this may take a while, please be patient..."
        />
      );
    }

    if (emptyRes) {
      return (
        <div>
          <Typography variant="subtitle1">
            Transaction still in progress...
          </Typography>
          <Button
            onClick={onReload}
            variant="text"
            className={classes.lightButton}
            disabled={disabledReload}
          >
            Reload
          </Button>
        </div>
      );
    }

    if (claimedAll) {
      return <Typography>All NFTs have already been claimed.</Typography>;
    }

    if (error) {
      return (
        <Typography>
          An error was encountered during claiming, please try again later.
        </Typography>
      );
    }

    //Update back once fix in server is implemented
    // if (alreadyClaimed) {
    //   return (
    //     <>
    //       <Typography>
    //         NFT has already been claimed for this account.
    //       </Typography>
    //       <div className={classes.nftContainer}>
    //         <img className={classes.nft} src={nft.src} />
    //         <Typography variant="subtitle2">{nft.name}</Typography>
    //       </div>

    //       <div>{renderLogout()}</div>
    //     </>
    //   );
    // }

    if (claim || alreadyClaimed) {
      return (
        <>
          <Typography>
            NFT has been successfully claimed for this account.
          </Typography>
          <div className={classes.nftContainer}>
            <img className={classes.nft} src={nft.src} />
            <Typography variant="subtitle2">{nft.name}</Typography>
          </div>

          <div>{renderLogout()}</div>
        </>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item className={classes.note}>
          {renderContent()}
        </Grid>
      </Grid>
    </div>
  );
};

export default ClaimNFT;
