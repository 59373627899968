import React from 'react';
import useStyles from './styles';

const Terms = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>
        <strong className={classes.title}>1. General Conditions</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>1.1</strong>&nbsp;These terms and conditions (“Terms”) govern
        the Rakeback Bonuses and complement Polker´s website General Terms and
        Conditions for the use of the Polker Platform, web and services.
      </p>
      <p>
        These Terms constitute a binding and enforceable legal contract between
        Polker and its affiliates and subsidiaries worldwide
        (“Polker”&nbsp;“we”&nbsp;“us”) and you, an end user of the Services
        (“you” or “User”) in relation to the Services.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1.2</strong>&nbsp;By using the Services, you agree that you have
        read, understood, and to are bound by these Terms, as amended from time
        to time, and that you comply with the requirements listed herein. If you
        do not agree to all of these Terms or comply with the requirements
        herein, please do not access or use the Services.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong className={classes.title}>2. User Provisions</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>2.1</strong>&nbsp;Modification: POLKER reserves the right at any
        time to modify these Terms and to add new or additional terms or
        conditions on your use of the Services. Such modifications and
        additional terms and conditions will be communicated to you and, unless
        expressly rejected (in which these Terms shall terminate), will be
        effective immediately and will be incorporated into these Terms. In the
        event, you refuse to accept such changes, these Terms and license will
        terminate.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>2.2</strong>&nbsp;Severability: If any provision of these Terms
        is determined by any court or other competent authority to be unlawful
        or unenforceable, the other provisions of these Terms will continue in
        effect. If any unlawful or unenforceable provision would be lawful or
        enforceable if part of it were deleted, that part will be deemed to be
        deleted, and the rest of the provision will continue in effect (unless
        that would contradict the clear intention of the clause, in which case
        the entirety of the relevant provision will be deemed to be deleted).
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>2.3</strong>&nbsp;Notices: All notices, requests, demands, and
        determinations for POLKER under these Terms (other than routine
        operational communications) shall be sent to hello@polker.game
      </p>
      <p>
        <br />
      </p>
      <p>
        Third Party Rights: No third party shall have any rights to enforce any
        terms contained herein.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong className={classes.title}>
          3. Restrictions and Obligations
        </strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1</strong>&nbsp;You agree that You will not do any of the
        following while using or accessing the Services:
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.1</strong>&nbsp;Impersonate or misrepresent Your affiliation
        with any person or entity or otherwise commit fraud;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.2</strong>&nbsp;Reproduce, modify, adapt, prepare derivative
        works based on, perform, display, publish, distribute, transmit,
        broadcast, sell, license or otherwise exploit the Website or App;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.3</strong>&nbsp;Use the Website, App or Services in a
        commercial manner;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.4</strong>&nbsp;Circumvent, disable or otherwise interfere
        with security related features of the Website, App or Services, or
        features that prevent or restrict use or copying of any Content;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.5</strong>&nbsp;Interfere with or disrupt (or attempt to
        interfere with or disrupt) any web pages available on the Website,
        servers or networks connected to the Website, or the technical delivery
        systems of our providers or disobey any requirements, procedures,
        policies or regulations of networks connected to the Website or App;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.6</strong>&nbsp;Attempt to probe, scan or test the
        vulnerability of any our system or network or breach or impair or
        circumvent any security or authentication measures protecting the
        Website, App and Services;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.7</strong>&nbsp;Attempt to decipher, decompile, disassemble
        or reverse engineer any of the software used to provide the Website, App
        and Services;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.8</strong>&nbsp;Attempt to scrape, parse, access, search or
        meta-search the Website or App with any engine, software, tool, agent,
        device or mechanism other than software and/or search agents provided by
        Polker or other generally available third-party web browsers;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.9</strong>&nbsp;Use the Website or App to send altered,
        deceptive or false source-identifying information;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.10</strong>&nbsp;Use the Website, App or Services in any
        manner not permitted by these Terms;
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.1.11</strong>&nbsp;Encourage or instruct any other individuals
        to do any of the foregoing or to violate these Terms.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.2</strong>&nbsp;You will not assign these Terms or assign any
        rights or delegate any obligations hereunder, in whole or in part,
        whether voluntarily or by operation of law, without our prior written
        consent. Any purported assignment or delegation by You without our
        appropriate prior written consent will be null and void.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>3.3</strong>&nbsp;We may assign these Terms or any rights
        hereunder without Your consent.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong className={classes.title}>
          4. Geographical and Other Restrictions
        </strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>4.1</strong>&nbsp;The Platform may not be available or
        appropriate for use in all jurisdictions. By accessing or using the
        Platform, you agree that you are solely and entirely responsible for
        compliance with all laws and regulations that may apply to you. You
        further agree that we have no obligation to inform you of any potential
        liabilities or violations of law or regulation that may arise in
        connection with your access and use of the Interface and that we are not
        liable in any respect for any failure by you to comply with any
        applicable laws or regulations.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>4.1.1</strong>&nbsp;The Platform may not be available or
        appropriate for use in all jurisdictions. By accessing or using the
        Platform, you agree that you are solely and entirely responsible for
        compliance with all laws and regulations that may apply to you. You
        further agree that we have no obligation to inform you of any potential
        liabilities or violations of law or regulation that may arise in
        connection with your access and use of the Interface and that we are not
        liable in any respect for any failure by you to comply with any
        applicable laws or regulations.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>4.1.2</strong>&nbsp;You must be of legal age in the jurisdiction
        in which You reside but not younger than 18 years old, and You have the
        legal capacity to enter into the Terms and be bound by them.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>4.3</strong>&nbsp;If the Customer is a legal entity then You
        accept the Terms on behalf of the legal entity, You must have the legal
        authority to accept the Terms on that entity’s behalf, and You confirm
        that the legal entity has been established lawfully and acts according
        to the law.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong className={classes.title}>5. Intellectual Property</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>5.1</strong>&nbsp;Unless otherwise clearly stated, all copyright
        and other IP rights present in the Services or displayed in connection
        with the Services and on the Website or App, registered or not, are
        owned by or licensed to Polker. The foregoing also applies towards any
        software solutions or parts of it, programs and code present in the
        Service.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>5.2</strong>&nbsp;Subject to Your compliance with these Terms,
        we give You a personal, worldwide, royalty-free, non-assignable and
        non-exclusive license to access and use the Website, App and Services
        for Your own personal use. This license is for the sole purpose of
        enabling You to use and enjoy the benefits of the Website, App and
        Services in the manner permitted by these Terms.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>5.3</strong>&nbsp;The right to access our Services and our
        Platform will automatically terminate upon the closing of the Account.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>5.4</strong>&nbsp;Any other use of the Website, App and Services
        that not the intended according to this Terms requires our prior written
        consent.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>5.5</strong>&nbsp;The Website, App and Services may be used and
        accessed for lawful purposes only. You agree to abide by all applicable
        local, state, national and foreign laws and regulations in connection
        with Your use of the Website, App and Services.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong className={classes.title}>6. No Warranties</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>6.1</strong>&nbsp;The Services are provided on an “as is” and
        “as available” basis, without any warranty or representation expressed
        or implied. Polker does not make any representations or give warranties
        that the access to the Platform or use of the Services will be
        continuous, uninterrupted, timely or error-free.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>6.2</strong>&nbsp;Polker does not warrant the accuracy of the
        data gathered by using the Platform.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>6.3</strong>&nbsp;Polker does not assume any obligation to and
        does not warrant that it will create or include additional features or
        functionality for the Services besides the existing ones.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>6.4</strong>&nbsp;Polker does not warrant that access to the
        Platform will be uninterrupted or error-free, neither does it warrant
        that the Content will be permanently stored totally or partially in the
        cloud software, without modifications or alterations, because although
        Polker will do its best to adopt adequate security measures to preserve
        Content accuracy and integrity, as well as the Your access to your data,
        security measures on the Internet are not impenetrable.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong className={classes.title}>7. Your Responsibilities</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>7.1</strong>&nbsp;You shall not use the Services in any manner
        except as expressly permitted in these Terms. Without limiting the
        generality of the preceding sentence, you may not:
      </p>
      <p>
        <br />
      </p>
      <p className={classes.indent}>
        I.&nbsp;infringe any proprietary rights, including but not limited to
        copyrights, patents, trademarks, or trade secrets of Polker;
      </p>
      <p className={classes.indent}>
        <br />
      </p>
      <p className={classes.indent}>
        II.&nbsp;use the Services to transmit any data or send or upload any
        material that contains viruses, Trojan horses, worms, time-bombs,
        keystroke loggers, spyware, adware, or any other harmful programmes or
        similar computer code designed to adversely affect the operation of any
        computer software or hardware;
      </p>
      <p className={classes.indent}>
        <br />
      </p>
      <p className={classes.indent}>
        III.&nbsp;use any robot, spider, other automated device, or manual
        process to monitor or copy the Services or any portion thereof other
        than copying or exporting as permitted by the Terms;
      </p>
      <p className={classes.indent}>
        <br />
      </p>
      <p className={classes.indent}>
        IV.&nbsp;make any back-up or archival copies of the Platform or any part
        thereof, including disassembling or de-compilation of the Platform;
      </p>
      <p className={classes.indent}>
        <br />
      </p>
      <p className={classes.indent}>
        V.&nbsp;use the Services in (A) any unlawful manner, (B) for fraudulent
        or malicious activities, or (C) in any manner inconsistent with these
        Terms;
      </p>
      <p className={classes.indent}>
        <br />
      </p>
      <p className={classes.indent}>
        VI.&nbsp;or violate applicable laws in any manner.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>7.1.1</strong>&nbsp;You warrant that you are aware of applicable
        laws and regulations governing your use of the Services. You shall be
        solely responsible for ensuring compliance with the various applicable
        laws, and you shall be solely liable for any liability that may arise
        due to a breach of your obligations in this regard.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>7.2</strong>&nbsp;You shall extend all cooperation, at your
        cost, to POLKER in its defense of any proceedings that may be initiated
        against it due to a breach of your obligations or covenants under these
        Terms.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong className={classes.title}>8. Tax Payments</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>8.1</strong>&nbsp;Polker is not liable for any tax payments in
        the name of the user (you).
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>8.2</strong>&nbsp;This is users’ obligation to make Tax payments
        in accordance to their country’s law.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong className={classes.title}>9. Liability</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>9.1</strong>&nbsp;Polker does not offer professional advice in
        relation to investments. It is the user’s responsibility to seek
        professional investment-related advice before and during using the
        Services and engaging in staking.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>9.2</strong>&nbsp;Polker is not liable for any damage connected
        with the temporary impossibility of accessing the Website or using the
        Services.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>9.3</strong>&nbsp;You must take into account all the risks
        related to the nature of the Services (including the risk of slashing).
        Polker will not be liable in case such risks materialise.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>9.4</strong>&nbsp;Polker may not be held responsible for the
        Content disseminated by any user that could infringe the rights of other
        users or third parties.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>9.5</strong>&nbsp;Polker assumes no liability for any damages
        caused by third parties or by circumstances outside the control of
        Polker (force majeure).
      </p>
      <p>&nbsp;</p>
      <p>
        <strong className={classes.title}>10. Other</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>10.1</strong>&nbsp;Polker may make amendments to these Terms and
        the Privacy Policy by informing the You of the amendments at least 60
        calendar days prior to the amendments entering into force. If the user
        does not object to the amendments, then it shall be considered that the
        user has accepted the amendments.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>10.2</strong>&nbsp;If any of the provisions of these Terms is
        found to be null and void or otherwise invalid, this shall not have an
        effect on the validity of the other provisions of these Terms.
      </p>
    </div>
  );
};

export default Terms;
