import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  toggle: {
    // marginBottom: 16,
    borderRadius: 0,
    width: 120,
    zIndex: 1,
  },
}));

export default useStyles;
