import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    marginLeft: 'auto',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  },
  profile: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '18px !important',
  },
  disabledButton: {
    marginRight: 8,
    color: '#b7b7b7',
    cursor: 'default',
    backgroundColor: '#625a5f',
    '&:hover': {
      backgroundColor: '#625a5f',
    },
  },
  promotion: {
    fontSize: 17,
    display: 'flex',
    borderRadius: 4,
    fontWeight: 'bold',
    padding: '12px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
    },
    marginBottom: -32,
  },
  promoContent: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoIcon: {
    width: 24,
    marginRight: 8,
  },
  close: {
    marginLeft: 16,
    '& svg': {
      fill: 'white',
    },
  },
}));

export default useStyles;
