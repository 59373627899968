export { default as Home } from './Home';
export { default as BuyChips } from './BuyChips';
export { default as PKRPass } from './PKRPass';
export { default as BuySpades } from './BuySpades';
export { default as Stake } from './Stake';
export { default as Settings } from './Settings';
export { default as LogIn } from './LogIn';
export { default as Store } from './Store';
export { default as Wallet } from './Wallet';
export { default as NotFound } from './NotFound';
export { default as ClaimNFT } from './NFT/Claim';
export { default as Shop } from './Shop';
export { default as Auth } from './LogIn';
export { default as Dealers } from './Dealers';
export { default as NFTWallet } from './NFTWallet';
export { default as Referrals } from './Referrals';
export { default as InvalidAccount } from './InvalidAccount';
export { default as PaymentFailed } from './PaymentFailed';
export { default as PaymentSuccess } from './PaymentSuccess';
export { default as PaymentPending } from './PaymentPending';
export { default as ClaimToken } from './ClaimToken';
