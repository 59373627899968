export default {
  accent: '#8746f4',
  dark: '#0f0f0f',
  black: '#000000',
  white: '#ffffff',
  border: '#353535',
  primary: {
    main: '#f31f90',
    dark: '#373737',
  },
  secondary: {
    main: '#ffffff',
    dark: '#838383',
  },
  background: {
    default: 'rgba(29, 25, 27, .5)',
    paper: 'rgba(29, 25, 27, 1)',
    paperLight: '#323336',
  },
  text: {
    primary: '#fff4f4',
    secondary: 'rgba(255,255,255,0.7)',
  },
  error: {
    main: '#f31f90',
  },
  warning: {
    main: '#ffb405',
  },
  info: {
    main: '#0e7ded',
  },
  success: {
    main: '#80db25',
  },
  button: {
    main: '#e6007a',
    secondary: '#830045',
  },
};
