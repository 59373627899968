import { get } from 'lodash';
import { requestAPI } from 'services/request';
import { store } from 'react-context-hook';
import { STORE } from 'store';

// GENERIC API
const meAPI = ({ ...rest }) => requestAPI('POST', '/user/me', {}, { ...rest });

const browsePreviousAccountAPI = ({ payload, ...rest }) =>
  requestAPI('POST', '/user/browse-previous-account', payload, { ...rest });

const forgotPasswordAPI = ({ payload }) =>
  requestAPI('POST', '/user/forgot-password', payload, { auth: false });

const verifyOTPPasswordAPI = ({ payload }) =>
  requestAPI('POST', '/user/verify-reset-password-otp', payload, {
    auth: false,
  });

const referralsAPI = ({ ...rest }) =>
  requestAPI('GET', '/user/referrals', {}, { ...rest });

const resetPasswordAPI = ({ payload }) =>
  requestAPI('POST', '/user/reset-password', payload, { auth: false });

const updateAPI = ({ payload, ...rest }) =>
  requestAPI('PUT', '/user/update', payload, { ...rest });

// PROCESS API
const me = async (args = {}) => {
  const res = await meAPI({ ...args });
  const data = get(res, 'data', {});
  store.set(STORE.USER_DETAILS, data);

  return data;
};

const forgotPassword = async payload => {
  const res = await forgotPasswordAPI({ payload });
  return get(res, 'data', {});
};

const verifyOTPPassword = async payload => {
  const res = await verifyOTPPasswordAPI({ payload });
  return get(res, 'data', {});
};

const resetPassword = async payload => {
  const res = await resetPasswordAPI({ payload });
  return get(res, 'data', {});
};

const update = async payload => {
  const res = await updateAPI({ payload });
  return get(res, 'data', {});
};

const browsePreviousAccount = async payload => {
  const res = await browsePreviousAccountAPI({ payload });
  return get(res, 'data', {});
};

const referrals = async () => {
  const res = await referralsAPI({});
  return get(res, 'data', {});
};

export const USERS_API = {
  me,
  referrals,
  forgotPassword,
  verifyOTPPassword,
  resetPassword,
  update,
  browsePreviousAccount,
};
