import { get } from 'lodash';
import { requestAPI } from 'services/request';
import { normalize } from 'normalizr';
import { SCHEMA } from 'store/schema';

const browseAPI = async ({ ...rest }) =>
  requestAPI('GET', '/balances/browse', {}, { ...rest });

const checkAPI = async ({ payload, ...rest }) => {
  return requestAPI('GET', '/balances/check', payload, { ...rest });
};

const nftAPI = async ({ ...rest }) =>
  requestAPI('GET', '/balances/nft', {}, { ...rest });

const check = async payload => {
  const res = await checkAPI({ payload });
  return get(res, 'data', {});
};

const browse = async () => {
  const res = await browseAPI({});
  const data = get(res, 'data.balances', []);

  if (!data.length) {
    return {};
  }

  const balances = normalize(data, SCHEMA.balances);
  return get(balances, 'entities.balance', {});
};

const nft = async ({ ...rest }) => {
  const res = await nftAPI({ ...rest });
  return get(res, 'data', {});
};

export const BALANCES_API = {
  check,
  browse,
  nft,
};
