import theme from 'theme';
import useStyles from './styles';
import MyNFTS from './components/MyNFTS';
import { LOGIC_HELPERS } from 'helpers/logic';
import Wallet from '../Shop/components/Wallet';
import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Tab, Typography } from '@material-ui/core';

const NFTWallet = props => {
  const { history } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [hasPromo, setHasPromo] = useState('closed');

  // eslint-disable-next-line no-undef
  const env = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (env !== 'production') {
      setDisabled(false);
    }
  }, [env]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { index } = props;

    return LOGIC_HELPERS.switchCase(value, {
      0: renderMyNFTS(value, index),
    });
  };

  const renderMyNFTS = (value, index) => () => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        <MyNFTS history={history} />
      </div>
    );
  };

  const renderHeader = () => (
    <div className={classes.header}>
      <Wallet hasPromo={hasPromo} setHasPromo={setHasPromo} />
    </div>
  );

  const renderContent = () => {
    if (disabled) {
      return (
        <>
          <Tabs
            className={classes.tabRoot}
            value={value}
            onChange={handleChange}
          >
            <Tab
              value={0}
              label={
                <Typography className={value === 0 ? classes.tab : ''}>
                  MY NFTS
                </Typography>
              }
            />
            <Tab
              value={1}
              disabled
              label={<Typography>NFTS MERGING - Coming Soon</Typography>}
            />
          </Tabs>
          <TabPanel value={value} index={0} />
          <TabPanel value={value} index={1} />
          {renderHeader()}
        </>
      );
    }
    return (
      <>
        <Tabs
          className={classes.tabRoot}
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
              height: 4,
            },
          }}
        >
          <Tab
            value={0}
            label={
              <Typography className={value === 0 ? classes.tab : ''}>
                MY NFTS
              </Typography>
            }
          />
          <Tab
            value={1}
            disabled
            label={
              <Typography className={value === 1 ? classes.tab : ''}>
                NFTS MERGING - Coming Soon
              </Typography>
            }
          />
        </Tabs>
        <TabPanel value={value} index={0} />
        <TabPanel value={value} index={1} />
        {renderHeader()}
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Grid
        md
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <MyNFTS history={history} />
      </Grid>
    </>
  );
};

export default NFTWallet;
