import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
  },
  headerRoot: {
    paddingBottom: 32,
  },
  header: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  image: {
    width: 200,
    borderRadius: 8,
  },
  content: {
    padding: 40,
    maxWidth: 400,
    minHeight: 620,
    display: 'flex',
    borderRadius: 20,
    flexDirection: 'column',
    backgroundColor: theme.palette.dark,
    marginTop: 16,
  },
  note: {
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  noteText: {
    textAlign: 'justify',
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
  lightButton: {
    [theme.breakpoints.up('md')]: {
      fontSize: '1',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
    margin: 'auto',
    border: 'solid 2px #fff',
    borderRadius: 10,
    padding: '8px 0',
    fontWeight: 'bolder',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover svg': {
      position: 'relative',
      animationName: '$slide',
      animationDuration: '0.8s',
      animationTimingFunction: 'linear',
      animationIterationCount: '8',
    },
  },
  '@keyframes slide': {
    '0%, 100%': { left: 0 },
    '50%': { left: 4 },
  },
  max: {
    color: theme.palette.secondary.dark,
  },
  balance: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 12,
    alignSelf: 'flex-end',
  },
  balanceValue: {
    color: '#bc9c1b',
  },
  chipBalanceValue: {
    color: '#b182ff',
  },
  pkr: {
    padding: '10px 12px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    color: 'rgba(194, 195, 197, 0.65)',
    textAlign: 'center',
    lineHeight: 2,
    borderRadius: 10,
  },
  chips: {
    padding: '10px 16px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.default,
    color: 'rgba(194, 195, 197, 0.65)',
    textAlign: 'center',
    lineHeight: 2,
    borderRadius: 10,
  },
  splitter: {
    color: theme.palette.secondary.dark,
  },
  inputRoot: {
    display: 'flex',
    minWidth: 60,
    borderRadius: 10,
    alignItems: 'center',
    paddingLeft: 16,
    backgroundColor: theme.palette.background.default,
  },
  input: {
    flex: 1,
    margin: '8px 0',
  },
  arrowIcon: {
    width: 30,
    height: 30,
    padding: 2,
    margin: '16px 0',
    textAlign: 'center',
    borderRadius: '50%',
    alignSelf: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  button: {
    minWidth: '100%',
    marginTop: 16,
    fontSize: 16,
    padding: '8px 0',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
  },
  hash: {
    marginTop: 8,
    fontSize: 12,
    zIndex: 20,
  },
  connectedWallet: {
    color: theme.palette.secondary.dark,
    marginTop: 32,
  },
  inputLabel: {
    color: theme.palette.secondary.dark,
  },
  dealsRoot: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    justifyContent: 'center',
    textAlign: 'center',
    zIndex: 1,
    borderRadius: 20,
    backgroundColor: 'rgba(33, 33, 33, 0.7)',
  },
  dealsHeader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
    marginRight: 8,
  },
  circle: {
    height: 275,
    width: 275,
    borderRadius: 200,
    border: 'solid 2px #fff',
    margin: '0px auto',
    color: theme.palette.secondary.main,
    position: 'relative',
    textAlign: 'center',
  },
  circleContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
  },
  line: {
    maxWidth: 56,
    height: 60,
    borderBottom: '1px solid #fff',
    margin: 'auto',
  },
  dealHeader: {
    marginTop: 14,
    marginRight: -10.8,
    letterSpacing: 10.8,
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  dealsContainer: {
    width: '100%',
    display: 'flex',
    margin: '24px auto',
    padding: '16px 24px',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dealsCard: {},
  chipRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
  },
  imageRoot: {
    position: 'relative',
  },
  priceRoot: {
    position: 'absolute',
    zIndex: 7,
    bottom: 4,
    width: '100%',
  },
  usd: {
    fontWeight: 'bold',
  },
  chipValue: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    // color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  chipText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    marginLeft: 4,
  },
  walletRoot: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 60,
    },
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 140,
  },
  radioButton: {
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0,
    width: '100%',
    cursor: 'pointer',
    position: 'absolute',
    height: '100%',
    zIndex: 8,
    '&:checked + div > div > div > $buttonRoot': {
      display: 'flex',
      zIndex: 100,
    },
    '&:checked + div img': {
      filter: 'brightness(60%)',
    },
  },
  buttonRoot: {
    top: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  chipPrice: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioContainer: {
    position: 'relative',
    padding: '24px 16px',
  },
  approx: {
    marginLeft: 4,
    color: '#e5b839',
  },
  buyChips: {
    zIndex: 100,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 24,
    fontWeight: 'bold',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  logoutButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
    color: '#e5b839',
  },
  noteRoot: {
    width: '100%',
    justifyContent: 'end',
    zIndex: 1,
  },
  center: {
    fontSize: 20,
    fontWeight: 200,
    borderRadius: 30,
    zIndex: 1,
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  networkSwitch: {
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  walletContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8,
    },
    display: 'flex',
    alignItems: 'center',
  },
  balanceInfoRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  displayWallet: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
  syncButton: {
    backgroundColor: theme.palette.primary.main,
  },
  wallet: {
    top: 0,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    padding: '16px 24px',
    width: 'calc(100% - 200px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 2,
  },
}));

export default useStyles;
