import { get } from 'lodash';
import { requestAPI } from 'services/request';

const browsePendingAPI = async ({ ...rest }) =>
  requestAPI('GET', '/transactions/pending-sync', {}, { ...rest });

//===========================================================================
const browsePending = async () => {
  const res = await browsePendingAPI({});
  return get(res, 'data', {});
};

export const SYNC_API = {
  browsePending,
};
