import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    height: '100%',
    fontWeight: 200,
    color: '#fff',
  },
  logout: {
    zIndex: 1,
    display: 'flex',
    borderRadius: 8,
    margin: '0 auto',
    padding: '16px 32px',
    flexDirection: 'column',
    backgroundColor: 'rgb(255, 255, 255, 0.15)',
    alignItems: 'center',
  },
  logoutBtn: {
    marginTop: 16,
    marginBottom: 12,
    maxWidth: 240,
  },
}));

export default useStyles;
