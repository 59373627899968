import { get } from 'lodash';
import { requestAPI } from 'services/request';

const getHealthAPI = async ({ payload }) => {
  return requestAPI('GET', '/', {}, { auth: false });
};

const getHealth = async payload => {
  const res = await getHealthAPI({ payload });
  return get(res, 'data', {});
};

export const GET_HEALTH_API = {
  getHealth,
};
