import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  paper: {
    maxWidth: 655,
    borderRadius: 30,
    padding: '33px 66px',
    textAlign: 'center',
    alignItems: 'center',
    background: '#1F2123',
    border: '2px solid #E6007A',
  },
  howItWorks: {
    textAlign: 'left',
    marginTop: 16,
    marginBottom: 16,
    '& > ol': {
      marginLeft: 24,
    },
  },
  hr: {
    height: 1,
    width: '100%',
    border: 'none',
    margin: '16px 0',
    background: '#5A5A5A',
  },
  header: {
    top: 0,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    padding: '16px 24px',
    width: 'calc(100% - 200px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  tabRoot: {
    marginTop: 80,
    marginLeft: 4,
    marginRight: 4,
    '& div': {
      zIndex: 1,
    },
    borderBottomColor: 'white',
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: 4,
  },
  tab: {
    color: theme.palette.primary.main,
  },
  relative: {
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  registrationRoot: {
    zIndex: 1,
    display: 'flex',
    margin: '80px 62px',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  registrationHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  registrationTitle: {},
  registrationTableRoot: {
    marginTop: 62,
  },
  registrationTable: {
    width: '100%',
    textAlign: 'left',
    borderSpacing: '0 4px',
    borderCollapse: 'separate',
  },
  exportButton: {
    fontSize: 14,
    color: 'white',
    borderRadius: 16,
    padding: '8px 12px',
    fontWeight: 'normal',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tableItem: {
    backgroundColor: 'rgba(31, 33, 35, 0.7)',
    padding: '20px 25px',
    marginBottom: 8,
    '& th, td': {
      padding: 16,
      position: 'relative',
    },
  },
  rowBreak: {
    width: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    right: 0,
    top: 0,
    '& > div': {
      background: '#424242',
      height: '80%',
      width: 1,
    },
  },
  tableImage: {
    width: 32,
    marginRight: 16,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  username: {
    fontSize: 14,
    marginLeft: 16,
    fontWeight: 'bold',
  },
  badge: {
    color: '#44b700',
    backgroundColor: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  infoIcon: {
    position: 'absolute',
    cursor: 'pointer',
    width: 20,
    right: 0,
    top: 0,
  },
  closeButton: {
    fontSize: 14,
    color: 'white',
    marginTop: 32,
    borderRadius: 32,
    padding: '10px 52px',
    border: '1px solid white',
  },
  copyIcon: {
    width: 16,
    height: 16,
    marginLeft: 8,
    cursor: 'pointer',
  },
}));

export default useStyles;
