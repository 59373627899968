import clsx from 'clsx';
import { get } from 'lodash';
import useStyles from '../styles';
import Loading from 'components/Loading';
import { URL_HELPERS } from 'helpers/url';
import { useHistory } from 'react-router-dom';
import { ETHERS_SERVICE } from 'services/ethers';
import React, { useContext, useState } from 'react';
import StakingContext from 'contexts/StakingContext';
import { Button, Grid, Typography } from '@material-ui/core';
import { LOGIC_HELPERS } from 'helpers/logic';
import { useSetStoreValue } from 'react-context-hook';
import { STORE } from 'store';

const ClaimPKR = props => {
  const { handleNext, tokenBalance, handleRedirect, contactAddressIndex } =
    props;
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rejected, setRejected] = useState(false);

  const { provider, validNetwork, wallet } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);

  const renderWallet = () => {
    const connectedWallet = LOGIC_HELPERS.ifElse(
      address,
      address,
      'WALLET NOT CONNECTED',
    );
    const invalidNetwork = LOGIC_HELPERS.ifElse(
      !validNetwork,
      <b>(Invalid Network)</b>,
      '',
    );

    return (
      <Grid direction="column" container alignItems="center">
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Connected wallet:</b> {connectedWallet} {invalidNetwork}
        </Typography>
      </Grid>
    );
  };

  const handleClaim = async () => {
    setLoading(true);
    try {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      await ethersHelper.methods.claimToken(contactAddressIndex);
      setLoading(false);
      setRejected(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setSuccess(false);
      setRejected(true);
      setSnackbar({
        variant: 'error',
        message:
          'Error encountered! Please try checking your address or network and try again.',
      });
    }
  };

  const handleDone = () => {
    history.push(URL_HELPERS.shop);
  };

  if (rejected) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Claim PKR - Polygon Upgrade
        </Typography>
        <Button className={classes.button} onClick={handleRedirect}>
          PKR & Claim Contract Audit Report
        </Button>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.processNum}>
            3.
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.processText, classes.processHeader)}
          >
            Claim PKR
          </Typography>
        </div>
        <div className={clsx(classes.claimContainer, classes.checkContainer)}>
          <img src="/images/rejected.svg" alt="success" />
          <Typography className={classes.rejected}>
            Transaction Rejected!
          </Typography>
          <Typography className={classes.eligibilityText}>
            Transaction was rejected for not approving transaction fee. Click
            Retry to go back to claim.
          </Typography>
        </div>
        <Button
          onClick={handleClaim}
          className={clsx(classes.button, classes.nextButton)}
        >
          Retry
        </Button>
        {renderWallet()}
      </div>
    );
  }

  if (success) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Claim PKR - Polygon Upgrade
        </Typography>
        <Button className={classes.button} onClick={handleRedirect}>
          PKR & Claim Contract Audit Report
        </Button>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.processNum}>
            3.
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.processText, classes.processHeader)}
          >
            Claim PKR
          </Typography>
        </div>
        <div className={clsx(classes.claimContainer, classes.checkContainer)}>
          <img src="/images/success.svg" alt="success" />
          <Typography className={classes.success}>SUCCESS!</Typography>
          <Typography className={classes.eligibilityText}>
            You have claimed your PKR. Thank you for your patience. See you at
            the tables.
          </Typography>
        </div>
        <Button
          onClick={handleDone}
          className={clsx(classes.button, classes.nextButton)}
        >
          Done
        </Button>
        {renderWallet()}
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Claim PKR - Polygon Upgrade
        </Typography>
        <Button className={classes.button} onClick={handleRedirect}>
          PKR & Claim Contract Audit Report
        </Button>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.processNum}>
            3.
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.processText, classes.processHeader)}
          >
            Claim PKR
          </Typography>
        </div>
        <div className={clsx(classes.claimContainer, classes.checkContainer)}>
          <Typography>Claiming PKR...</Typography>
          <Loading
            iconOnly
            size={160}
            color="primary"
            className={classes.marginTop}
          />
        </div>
        <Button
          onClick={handleNext}
          className={clsx(classes.button, classes.nextButton)}
          disabled
        >
          Next
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Claim PKR - Polygon Upgrade
      </Typography>
      <Button className={classes.button} onClick={handleRedirect}>
        PKR & Claim Contract Audit Report
      </Button>
      <div className={classes.row}>
        <Typography variant="h6" className={classes.processNum}>
          3.
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.processText, classes.processHeader)}
        >
          Claim PKR
        </Typography>
      </div>
      <div className={clsx(classes.claimContainer, classes.checkContainer)}>
        <Typography className={classes.eligibilityText}>
          This wallet can claim PKR. Click Claim and please look out for
          notification for transaction fee.
        </Typography>
        <Typography className={classes.amountText}>Amount to claim</Typography>
        <Typography className={classes.amount}>{tokenBalance}</Typography>
        <Button
          className={clsx(classes.button, classes.marginTop)}
          onClick={handleClaim}
        >
          Claim
        </Button>
      </div>
      <Button className={clsx(classes.button, classes.nextButton)} disabled>
        Next
      </Button>
      {renderWallet()}
    </div>
  );
};

export default ClaimPKR;
