import { get } from 'lodash';
import { requestAPI } from 'services/request';

const claimBetaAPI = async ({ ...rest }) =>
  requestAPI('POST', '/nfts/beta/claim', {}, { ...rest });

const checkSupplyAPI = async ({ ...rest }) =>
  requestAPI('GET', '/nfts/beta/check-supply', {}, { ...rest });

const claimBeta = async ({ ...rest }) => {
  const res = await claimBetaAPI({ ...rest });
  return get(res, 'data', {});
};

const checkSupply = async ({ ...rest }) => {
  const res = await checkSupplyAPI({ ...rest });
  return get(res, 'data', {});
};

export const NFT_API = {
  claimBeta,
  checkSupply,
};
