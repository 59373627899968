import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  header: {
    top: 0,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    padding: '16px 24px',
    width: 'calc(100% - 200px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    zIndex: 1,
  },
  tabRoot: {
    marginTop: 80,
    marginLeft: 4,
    marginRight: 4,
    '& div': {
      zIndex: 1,
    },
    borderBottomColor: 'white',
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: 4,
  },
  tab: {
    color: theme.palette.primary.main,
  },
  relative: {
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  chip: {
    marginLeft: 8,
    fontWeight: 'bold',
    backgroundColor: 'rgba(252, 28, 143, 1)',
    boxShadow: '0 5px 20px rgba(252, 28, 143, .5)',
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 0,
      overflow: 'hidden',
    },
  },
  title: {
    color: '#e5b839',
    fontWeight: 200,
    marginLeft: 10,
    padding: 10,
  },
  center: {
    fontSize: 20,
    fontWeight: 200,
    borderRadius: 30,
    zIndex: 1,
    margin: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  disabledContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  disabledHeader: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
}));

export default useStyles;
