export const ETHEREUM_MAINNET_ID = 1;
export const ETHEREUM_TESTNET_ID = 5; // Goerli
export const POLYGON_MAINNET = 137;
export const POLYGON_TESTNET = 80002; // Amoy
export const BSC_MAINNET_ID = 56;
export const BSC_TESTNET_ID = 97;
export const GANACHE_ID = 5777; // devs: change this base on your ganache network id

export const VALID_NETWORK_IDS_DEV = [POLYGON_TESTNET];

export const VALID_NETWORK_IDS_PROD = [POLYGON_MAINNET];

export const RPC_URL_DEV = 'https://rpc-amoy.polygon.technology/';
export const RPC_URL_PROD = 'https://polygon-rpc.com';

export const STAKING_NETWORK_IDS_DEV = {
  ethereum: ETHEREUM_TESTNET_ID,
  bsc: BSC_TESTNET_ID,
  polygon: POLYGON_TESTNET,
};

export const STAKING_NETWORK_IDS_PROD = {
  ethereum: ETHEREUM_MAINNET_ID,
  bsc: BSC_MAINNET_ID,
  polygon: POLYGON_MAINNET,
};
