import React, { useState, useEffect } from 'react';
import { find, get, isEmpty } from 'lodash';
import { LOGIC_HELPERS } from 'helpers/logic';
import {
  Card,
  Grid,
  Tooltip,
  Typography,
  CardContent,
  withStyles,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { ETHERS_SERVICE } from 'services/ethers';
import useStyles from './styles';

import { STRING_HELPERS } from 'helpers/stringAdditions';
import {
  VALID_NETWORK_IDS_DEV,
  VALID_NETWORK_IDS_PROD,
} from 'constants/networkConstants';

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const EthereumWallet = props => {
  const { wallet, activeAddress, provider, loaded, validNetwork, networkId } =
    props;
  const classes = useStyles();
  const address = get(wallet, 'address', '').toLowerCase();
  const connected = wallet.address === activeAddress;
  const [balance, setBalance] = useState(0);
  const displayTextStatus = LOGIC_HELPERS.ifElse(connected, 'Active', '');

  const VALID_NETWORK_IDS =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_ENV === 'production'
      ? VALID_NETWORK_IDS_PROD
      : VALID_NETWORK_IDS_DEV;

  useEffect(() => {
    const fetchBalance = async () => {
      const network = await provider.getNetwork();
      const networkId = network?.chainId || 0;
      const isValid = VALID_NETWORK_IDS.includes(networkId);
      const initialized = LOGIC_HELPERS.ifElse(
        [isValid, validNetwork, activeAddress],
        true,
        false,
      );

      if (initialized) {
        const [, , ethersHelper] = ETHERS_SERVICE(
          provider,
          activeAddress,
          networkId,
        );
        const res = await ethersHelper.methods.checkPKRBalance(address);
        setBalance(res);
      }
    };

    if (provider && loaded) {
      fetchBalance();
    }
  }, [provider, activeAddress, loaded]);

  const renderTooltip = () => (
    <Typography variant="body2">{address}</Typography>
  );

  const renderContent = () => {
    const displayAddress = STRING_HELPERS.renderWalletAddress(address);

    return (
      <div className={classes.content}>
        <div className={classes.addressHeader}>
          <Typography
            variant="button"
            color="textPrimary"
            className={classes.address}
            gutterBottom
          >
            ADDRESS
          </Typography>
        </div>
        <LightTooltip
          title={renderTooltip(address)}
          placement="bottom-start"
          arrow={false}
          interactive
        >
          <Typography
            variant="h4"
            color="textPrimary"
            className={
              connected ? classes.connectedAddress : classes.disconnectedAddress
            }
            gutterBottom
          >
            {displayAddress}
          </Typography>
        </LightTooltip>
        <div className={classes.addressFooter}>
          <div className={classes.statusFooter}>
            {connected ? (
              <FiberManualRecordIcon className={classes.connectedStatus} />
            ) : null}
            <Typography
              className={
                connected ? classes.connectedStatus : classes.disconnectedStatus
              }
            >
              {displayTextStatus}
            </Typography>
          </div>
          <Typography
            className={
              connected ? classes.connectedBalance : classes.disconnectedBalance
            }
          >
            {balance} PKR
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Grid className={classes.item}>
      <Card
        className={connected ? classes.connectedCard : classes.disconnectedCard}
      >
        <CardContent>{renderContent()}</CardContent>
      </Card>
    </Grid>
  );
};

export default EthereumWallet;
