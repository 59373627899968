import React, { useEffect, useState } from 'react';
import Page from 'components/Page';
import { URL_HELPERS } from 'helpers/url';
import { Grid, Button } from '@material-ui/core';
import { EPIC_API } from 'apis/epic';
import useAuth from 'hooks/useAuth';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { SERVER_API } from 'apis/serverSettings';
import useStyles from './styles';
import Home from './components/Home';
import ConnectWallet from './components/ConnectWallet';
import CheckEligibility from './components/CheckEligibility';
import ClaimPKR from './components/ClaimPKR';

const ClaimToken = () => {
  const history = useHistory();
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [step, setStep] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [contactAddressIndex, setContactAddressIndex] = useState(-1);

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const server = await SERVER_API.getServerStatus();
    setIsMaintenance(server[0].is_maintenance);

    if (code) {
      try {
        setLoading(true);
        const payload = {
          code: code,
          app: 'store',
        };
        const { res } = await EPIC_API.request(payload);
        setLoading(false);
        try {
          await auth.signIn(res);
        } catch (error) {
          const payload = {
            refresh_token: res.refresh_token,
            app: 'store',
          };
          await auth.signOut(payload);
          history.push(URL_HELPERS.invalidAccount);
        }
      } catch (e) {
        setLoading(false);
        console.log(e);
        history.push(URL_HELPERS.invalidAccount);
      }
    }
  }, [isMaintenance]);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleRedirect = () => {
    window.open(URL_HELPERS.redirectAudit, '_blank');
  };

  const renderForm = () => {
    if (loading) {
      return (
        <div className={classes.note}>
          <Button className={classes.syncButton}>
            <Loading className={classes.loadingIcon} iconOnly />
            Logging in...
          </Button>
        </div>
      );
    }
    switch (step) {
      case 0:
        return <Home handleNext={handleNext} handleRedirect={handleRedirect} />;
      case 1:
        return (
          <ConnectWallet
            handleNext={handleNext}
            handleRedirect={handleRedirect}
          />
        );
      case 2:
        return (
          <CheckEligibility
            handleNext={handleNext}
            tokenBalance={tokenBalance}
            handleRedirect={handleRedirect}
            setTokenBalance={setTokenBalance}
            setContactAddressIndex={setContactAddressIndex}
          />
        );
      case 3:
        return (
          <ClaimPKR
            tokenBalance={tokenBalance}
            handleRedirect={handleRedirect}
            contactAddressIndex={contactAddressIndex}
          />
        );
    }
  };

  return (
    <>
      <Page title="Claim Token" className={classes.root}>
        <Grid item className={classes.contentRoot}>
          {renderForm()}
        </Grid>
      </Page>
    </>
  );
};

export default ClaimToken;
