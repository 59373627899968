import { get } from 'lodash';
import { requestAPI } from 'services/request';

const requestConnectAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/transactions/connect/request', payload, { ...rest });

const request = async payload => {
  const res = await requestConnectAPI({ payload });
  return get(res, 'data', {});
};

export const TRANSACTIONS_CONNECT_API = {
  request,
};
