import { get } from 'lodash';
import { requestAPI } from 'services/request';

const requestTokenAPI = ({ payload, ...rest }) =>
  requestAPI('POST', '/epic/request', payload, { auth: false, ...rest });

const revokeTokenAPI = ({ payload, ...rest }) =>
  requestAPI('POST', '/epic/revoke', payload, { auth: false, ...rest });

const request = async payload => {
  const res = await requestTokenAPI({ payload });
  return get(res, 'data', {});
};

const revoke = async payload => {
  const res = await revokeTokenAPI({ payload });
  return get(res, 'data', {});
};

export const EPIC_API = {
  request,
  revoke,
};
