import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ListItem, Button, Collapse, Chip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useStyles from './styles';

function Index({
  title,
  href,
  depth,
  target,
  anchor,
  children,
  disabled,
  icon: Icon,
  className,
  open: openProp,
  label: Label,
  comingSoon,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const renderListButton = () => {
    if (disabled && comingSoon) {
      return (
        <>
          <Button
            className={clsx(classes.button, { [classes.disabled]: disabled })}
            onClick={handleToggle}
            style={style}
          >
            {Icon && <Icon className={classes.icon} />}
            {title}
          </Button>
          <div className={classes.soonRoot}>
            <Chip
              className={classes.chip}
              label="Coming Soon"
              color="primary"
              size="small"
            />
          </div>
        </>
      );
    }

    if (disabled) {
      return (
        <>
          <Button
            className={clsx(classes.button, { [classes.disabled]: disabled })}
            onClick={handleToggle}
            style={style}
          >
            {Icon && <Icon className={classes.icon} />}
            {title}
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          className={clsx(classes.button, { [classes.disabled]: disabled })}
          onClick={handleToggle}
          style={style}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  };

  const handleToggle = () => {
    if (!disabled) {
      setOpen(prevOpen => !prevOpen);
    }
  };

  let paddingLeft = 8;

  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
      >
        {renderListButton()}
      </ListItem>
    );
  }

  const linkProps = anchor
    ? { href, component: 'a' }
    : { to: href, component: RouterLink };

  if (disabled && comingSoon) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
      >
        <Button
          {...linkProps}
          disabled={disabled}
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`, {
            [classes.disabled]: disabled,
          })}
          style={style}
          target={target}
          exact
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
        <div className={classes.soonRoot}>
          <Chip
            className={classes.chip}
            label="Coming Soon"
            color="primary"
            size="small"
          />
        </div>
      </ListItem>
    );
  }

  if (disabled) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
      >
        <Button
          {...linkProps}
          disabled={disabled}
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`, {
            [classes.disabled]: disabled,
          })}
          style={style}
          target={target}
          exact
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }

  return (
    <ListItem
      {...rest}
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
    >
      <Button
        {...linkProps}
        disabled={disabled}
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`, {
          [classes.disabled]: disabled,
        })}
        style={style}
        target={target}
        exact
      >
        {Icon && <Icon className={classes.icon} />}
        {title}
        {Label && (
          <span className={classes.label}>
            <Label />
          </span>
        )}
      </Button>
    </ListItem>
  );
}

Index.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  open: false,
};

export default Index;
