import { STORE } from 'store';
import useStyles from './styles';
import ViewNFT from '../ViewNFT';
import { POLYGON_TESTNET } from 'constants/networkConstants';
import { P2E_API } from 'apis/P2E/p2e';
import Loading from 'components/Loading';
import { useWallets } from 'hooks/wallets';
import { find, get, isEmpty } from 'lodash';
import { LOGIC_HELPERS } from 'helpers/logic';
import { useDealersList } from 'hooks/dealers';
import { useTheme } from '@material-ui/styles';
import { ETHERS_SERVICE } from 'services/ethers';
import { SYNC_API } from 'apis/Transactions/sync';
import { DEALERS_API } from 'apis/Dealers/dealer';
import StakingContext from 'contexts/StakingContext';
import { useSetStoreValue } from 'react-context-hook';
import { STRING_HELPERS } from 'helpers/stringAdditions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Input,
  Dialog,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const MyNFTS = () => {
  const classes = useStyles();
  const [hash, setHash] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(-1);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openViewNFT, setOpenViewNFT] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [syncDisabled, setSyncDisabled] = useState(true);
  const [selectedDealer, setSelectedDealer] = useState({});

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const { provider, wallet, validNetwork } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const { data: wallets } = useWallets();
  const { data: dealersList, refetch: refetchDealerList } = useDealersList();
  const displayAddress = STRING_HELPERS.renderWalletAddress(address);

  useEffect(() => {
    const onInitialSync = async () => {
      const res = await SYNC_API.browsePending();
      const isDisabled = !res?.sync_required_for_dealer;
      setSyncDisabled(isDisabled);
    };

    onInitialSync();
  }, []);

  useEffect(() => {
    const initialized = LOGIC_HELPERS.ifElse(
      [provider, validNetwork, wallets?.length],
      true,
      false,
    );
    if (initialized) {
      const connected = find(wallets, { address });
      const status = !isEmpty(connected);
      setEnabled(status);
      setRefetch(false);
    }
  }, [provider, validNetwork, address, wallets, refetch]);

  const handleMouseOver = (e, i) => {
    e.preventDefault();
    setIsHovering(i);
  };

  const handleMouseOut = e => {
    e.preventDefault();
    setIsHovering(-1);
  };

  const enableSync = async () => {
    const res = await SYNC_API.browsePending();
    const isDisabled = !res?.sync_required_for_dealer;
    setSyncDisabled(isDisabled);

    if (isDisabled) {
      setHash(null);
    }
  };

  const onSync = async () => {
    try {
      setLoading(true);
      setRefetch(true);
      await DEALERS_API.sync();
      await refetchDealerList();
      await enableSync();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeText = e => {
    e.preventDefault();
    setTokenId(e.target.value);
  };

  const onDeposit = async () => {
    setLoading(true);
    setOpenDeposit(false);
    if (!tokenId) {
      setSnackbar({
        variant: 'error',
        message: 'Enter valid token ID!',
      });
      setLoading(false);
    } else {
      try {
        const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
        const tx = await ethersHelper.methods.depositDealerNFT(tokenId);
        const payload = { txHash: tx.hash };
        setHash(tx.hash);
        await P2E_API.depositNft(payload);
        await enableSync();
        setTokenId('');
        setLoading(false);
        setSnackbar({
          variant: 'success',
          message: 'Success deposit dealer!',
        });
      } catch (e) {
        setSnackbar({
          variant: 'error',
          message: 'Failed to deposit dealer!',
        });
        setLoading(false);
      }
    }
  };

  const renderDisabled = () => (
    <div className={classes.center}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        WALLET NOT CONNECTED
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please connect your wallet first.
      </Typography>
    </div>
  );

  const renderDepositDialog = () => {
    if (!openDeposit) {
      return null;
    }

    const dealerAddress =
      // eslint-disable-next-line no-undef
      process.env.REACT_APP_POLYGON_DEALERS_SMART_CONTRACT_ADDRESS;

    return (
      <Dialog
        open={openDeposit}
        onClose={() => {
          setOpenDeposit(false);
        }}
        classes={{ paper: classes.depositPaper }}
      >
        <Typography variant="h6" className={classes.dialogTitle}>
          DISCLAIMER
        </Typography>
        <Typography variant="subtitle1">
          Any NFT, cryptocurrency, token, or item sent that is not a valid
          play-to-earn NFT, with contract address {dealerAddress}, will not be
          recoverable.
        </Typography>
        <div className={classes.dialogButton}>
          <Button
            size="large"
            onClick={() => {
              setOpenDeposit(false);
            }}
            className={classes.back}
          >
            Back
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={onDeposit}
          >
            I understand
          </Button>
        </div>
      </Dialog>
    );
  };

  const renderSync = () => {
    if (syncDisabled) {
      return null;
    }

    if (loading) {
      return (
        <div className={classes.note}>
          <Button className={classes.syncButton}>
            <Loading className={classes.loadingIcon} iconOnly />
            Syncing
          </Button>
        </div>
      );
    }
    return (
      <div className={classes.note}>
        <Button className={classes.syncButton} onClick={onSync}>
          SYNC
        </Button>
      </div>
    );
  };

  const renderHash = () => {
    if (!hash || syncDisabled) {
      return null;
    }

    if (networkId === POLYGON_TESTNET) {
      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://amoy.polygonscan.com/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }

    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://polygonscan.com/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderWallet = () => (
    <Grid direction="column" container>
      <Typography color="textPrimary" className={classes.displayWallet}>
        <b>Connected wallet:</b> {displayAddress}
      </Typography>
    </Grid>
  );

  const renderBalance = () => {
    return (
      <Grid direction="row" xs={12} container className={classes.walletRoot}>
        <div className={classes.walletContainer}>{renderWallet()}</div>
        <div className={classes.column}>
          {renderSync()}
          <div className={classes.balanceInfoRoot}>{renderHash()}</div>
        </div>
      </Grid>
    );
  };

  const renderDeposit = () => {
    if (loading) {
      return (
        <div className={classes.tokenField}>
          <Loading loadingText="Please wait..." />
        </div>
      );
    }

    if (!isDesktop) {
      return (
        <div className={classes.tokenField}>
          <Input
            size="small"
            type="number"
            label="Token ID"
            value={tokenId}
            variant="outlined"
            placeholder="Token ID"
            onChange={onChangeText}
            inputProps={{ className: classes.inputStyle }}
            disableUnderline
          />
          <Button
            size="small"
            className={classes.depositButton}
            onClick={() => {
              setOpenDeposit(true);
            }}
            loading={loading}
            variant="outlined"
          >
            Deposit NFT
          </Button>
        </div>
      );
    }

    return (
      <div className={classes.tokenField}>
        <Input
          size="small"
          type="number"
          label="Token ID"
          value={tokenId}
          variant="outlined"
          placeholder="Token ID"
          onChange={onChangeText}
          inputProps={{ className: classes.inputStyle }}
          disableUnderline
        />
        <Button
          size="small"
          className={classes.depositButton}
          onClick={() => {
            setOpenDeposit(true);
          }}
          loading={loading}
          variant="outlined"
        >
          Deposit NFT
        </Button>
      </div>
    );
  };

  const onClickViewNFT = index => () => {
    setSelectedDealer(dealersList.dealers[index]);
    setOpenViewNFT(true);
  };

  const renderDealers = () => {
    if (dealersList === undefined) return null;

    return (
      <Grid xs={12} item className={classes.dealerRoot}>
        <div className={classes.dealerContent}>
          {renderDeposit()}
          <div className={classes.rowHeader}>
            <Typography className={classes.dealHeader}>DEALERS</Typography>
          </div>
          <Grid
            spacing={4}
            alignItems="center"
            direction="column"
            container
            className={classes.dealerContainer}
          >
            {dealersList.dealers.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    margin: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  onMouseEnter={e => handleMouseOver(e, index)}
                  onMouseLeave={handleMouseOut}
                >
                  <LazyLoadImage
                    width={320}
                    height={460}
                    effect="blur"
                    visibleByDefault
                    className={classes.image}
                    src={item.image}
                  />
                  {/* {isHovering === index ? (
                    <div style={{ cursor: 'pointer' }}>
                      <div className={classes.backdrop} />
                      <Typography className={classes.dealerName}>
                        {item.name}
                      </Typography>
                    </div>
                  ) : null} */}
                  <Button
                    onClick={onClickViewNFT(index)}
                    size="small"
                    className={classes.depositButton}
                  >
                    DETAILS
                  </Button>
                </div>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  const renderContent = () => {
    if (!enabled || !validNetwork) {
      return renderDisabled();
    }

    return (
      <>
        {renderBalance()}
        {renderDealers()}
      </>
    );
  };

  return (
    <Grid
      md
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      {renderContent()}
      {renderDepositDialog()}
      <ViewNFT
        open={openViewNFT}
        setOpen={setOpenViewNFT}
        dealer={selectedDealer}
        refetchDealerList={refetchDealerList}
      />
    </Grid>
  );
};

export default MyNFTS;
