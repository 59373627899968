import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { STORE } from 'store';
import { useGetAndSet } from 'react-context-hook';
import Toggle from 'components/Toggle';
import useStyles from './styles';
import StakingContext from 'contexts/StakingContext';
import { Network } from '@haechi-labs/face-types';
import { LOGIC_HELPERS } from 'helpers/logic';

const NetworkSwitch = props => {
  const { reload, className } = props;
  const classes = useStyles();
  const [stakingNetwork, setStakingNetwork] = useGetAndSet(
    STORE.STAKING_NETWORK,
  );

  const { faceWallet, isFaceWallet } = useContext(StakingContext);

  useEffect(() => {
    if (isFaceWallet && stakingNetwork) {
      const switchNetwork = async () => {
        const faceWalletNetwork = LOGIC_HELPERS.ifElse(
          stakingNetwork === 'ethereum',
          { mainnet: Network.ETHEREUM, testnet: Network.GOERLI },
          {
            mainnet: Network.BNB_SMART_CHAIN,
            testnet: Network.BNB_SMART_CHAIN_TESTNET,
          },
        );

        const network = LOGIC_HELPERS.ifElse(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_ENV === 'production',
          faceWalletNetwork.mainnet,
          faceWalletNetwork.testnet,
        );
        await faceWallet.switchNetwork(network);
      };

      switchNetwork();
    }
  }, [stakingNetwork]);

  const onStakingNetworkChange = value => {
    if (value === 'ETH') {
      setStakingNetwork('ethereum');
    } else {
      setStakingNetwork('bsc');
    }
    if (reload) {
      window.location.reload();
    }
  };

  return isFaceWallet ? (
    <Toggle
      className={clsx(classes.toggle, className)}
      value={stakingNetwork.toUpperCase()}
      onChange={onStakingNetworkChange}
      option1="ETH"
      option2="BSC"
    />
  ) : null;
};

NetworkSwitch.defaultProps = {
  reload: true,
};

export default NetworkSwitch;
