import clsx from 'clsx';
import { get } from 'lodash';
import useStyles from '../styles';
import Loading from 'components/Loading';
import { ETHERS_SERVICE } from 'services/ethers';
import React, { useContext, useState } from 'react';
import StakingContext from 'contexts/StakingContext';
import { Button, Grid, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { LOGIC_HELPERS } from 'helpers/logic';
import { STORE } from 'store';
import { useSetStoreValue } from 'react-context-hook';

const CheckEligibility = props => {
  const {
    handleNext,
    tokenBalance,
    handleRedirect,
    setTokenBalance,
    setContactAddressIndex,
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const { provider, validNetwork, wallet, logoutOfWeb3Modal } =
    useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);

  // TODO: Temporary fix for the backdrop issue of w3m-modal by having ESC logic when modal is empty
  const w3mModal = document.querySelector('w3m-modal');
  if (w3mModal.shadowRoot.childNodes.length <= 1) {
    const escapeKeyEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      which: 27,
      bubbles: true,
      cancelable: true,
    });
    // Dispatch the event on the document
    document.dispatchEvent(escapeKeyEvent);
  }
  // TODO: END

  const renderWallet = () => {
    const connectedWallet = LOGIC_HELPERS.ifElse(
      address,
      address,
      'WALLET NOT CONNECTED',
    );
    const invalidNetwork = LOGIC_HELPERS.ifElse(
      !validNetwork,
      <b>(Invalid Network)</b>,
      '',
    );

    return (
      <Grid direction="column" container alignItems="center">
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Connected wallet:</b> {connectedWallet} {invalidNetwork}
        </Typography>
      </Grid>
    );
  };

  const handleCheck = async () => {
    try {
      setLoading(true);
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const { balance, index } = await ethersHelper.methods.getBalance();
      setLoading(false);
      if (balance) {
        setContactAddressIndex(index);
        setTokenBalance(balance);
        setIsEligible(true);
        setRejected(false);
      } else {
        setContactAddressIndex(-1);
        setIsEligible(false);
        setTokenBalance(0);
        setRejected(true);
      }
    } catch (e) {
      console.log('ERROR: e', e);
      setContactAddressIndex(-1);
      setIsEligible(false);
      setTokenBalance(0);
      setRejected(true);
      setLoading(false);
      setSnackbar({
        variant: 'error',
        message:
          'Error encountered! Please try checking your address or network and try again.',
      });
    }
  };

  const onDisconnect = async () => {
    await logoutOfWeb3Modal();
  };

  if (isEligible) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Claim PKR - Polygon Upgrade
        </Typography>
        <Button className={classes.button} onClick={handleRedirect}>
          PKR & Claim Contract Audit Report
        </Button>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.processNum}>
            2.
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.processText, classes.processHeader)}
          >
            Check Eligibility
          </Typography>
        </div>
        <div
          className={clsx(classes.claimContainer, classes.approvedContainer)}
        >
          <img src="/images/approve.svg" alt="approve" />
          <Typography className={classes.approvedText}>Approved</Typography>
          <Typography className={classes.eligibilityText}>
            This wallet is eligible to claim your PKR token
          </Typography>
          <Typography className={classes.amountText}>
            Amount to claim
          </Typography>
          <Typography className={classes.amount}>{tokenBalance}</Typography>
          <div className={classes.footer}>
            <Typography>Click next to continue</Typography>
            <ArrowDropDownIcon />
          </div>
        </div>
        <Button
          onClick={handleNext}
          className={clsx(classes.button, classes.nextButton)}
        >
          Next
        </Button>
        {renderWallet()}
      </div>
    );
  }

  if (rejected) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Claim PKR - Polygon Upgrade
        </Typography>
        <Button className={classes.button} onClick={handleRedirect}>
          PKR & Claim Contract Audit Report
        </Button>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.processNum}>
            2.
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.processText, classes.processHeader)}
          >
            Check Eligibility
          </Typography>
        </div>
        <div className={clsx(classes.claimContainer, classes.checkContainer)}>
          <img src="/images/not-approve.svg" alt="not-approve" />
          <Typography className={classes.notApprovedText}>
            Not Approved
          </Typography>
          <Typography className={classes.eligibilityText}>
            This wallet is not eligible to claim PKR tokens. Please verify your
            wallet, reconnect and try again.
          </Typography>
        </div>
        <Button
          onClick={onDisconnect}
          className={clsx(classes.button, classes.nextButton)}
        >
          Disconnect
        </Button>
        {renderWallet()}
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.header}>
          Claim PKR - Polygon Upgrade
        </Typography>
        <Button className={classes.button} onClick={handleRedirect}>
          PKR & Claim Contract Audit Report
        </Button>
        <div className={classes.row}>
          <Typography variant="h6" className={classes.processNum}>
            2.
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.processText, classes.processHeader)}
            onClick={handleCheck}
          >
            Check Eligibility
          </Typography>
        </div>
        <div className={clsx(classes.claimContainer, classes.checkContainer)}>
          <Typography>
            Checking for wallet eligibility, please wait...
          </Typography>
          <Loading
            iconOnly
            size={160}
            color="primary"
            className={classes.marginTop}
          />
        </div>
        <Button
          onClick={handleNext}
          className={clsx(classes.button, classes.nextButton)}
          disabled
        >
          Next
        </Button>
        {renderWallet()}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Claim PKR - Polygon Upgrade
      </Typography>
      <Button className={classes.button} onClick={handleRedirect}>
        PKR & Claim Contract Audit Report
      </Button>
      <div className={classes.row}>
        <Typography variant="h6" className={classes.processNum}>
          2.
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.processText, classes.processHeader)}
        >
          Check Eligibility
        </Typography>
      </div>
      <div className={clsx(classes.claimContainer, classes.checkContainer)}>
        <Typography className={classes.eligibilityText}>
          We will be verifying your wallet if it matches our snapshot records
          taken on
          <br />
          <b> Monday 24th July 23:59 EDT/03:59 UTC Tuesday 25th July.</b>
        </Typography>
        <Button
          className={clsx(classes.button, classes.marginTop)}
          onClick={handleCheck}
        >
          Check Eligibility
        </Button>
      </div>
      <Button
        onClick={handleNext}
        className={clsx(classes.button, classes.nextButton)}
        disabled
      >
        Next
      </Button>
      {renderWallet()}
    </div>
  );
};

export default CheckEligibility;
