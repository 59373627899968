import React, { useState, useContext, useEffect } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { LOGIC_HELPERS } from 'helpers/logic';
import { useSetStoreValue } from 'react-context-hook';
import StakingContext from 'contexts/StakingContext';
import Loading from 'components/Loading';
import { STORE } from 'store';
import useStyles from '../styles';
import clsx from 'clsx';

const ConnectWallet = props => {
  const { handleNext, handleRedirect } = props;
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const classes = useStyles();

  const {
    provider,
    wallet,
    loaded,
    validNetwork,
    loadWeb3Modal,
    logoutOfWeb3Modal,
  } = useContext(StakingContext);
  const [loading, setLoading] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const address = get(wallet, 'wallet', '').toLowerCase();

  const hasWallet = !isEmpty(wallet);

  useEffect(() => {
    const startConnecting = LOGIC_HELPERS.ifElse(
      [hasWallet, !connecting],
      true,
      false,
    );
    if (startConnecting) {
      setConnecting(true);
    }
  }, [connecting, hasWallet]);

  useEffect(() => {
    const onConnect = async () => {
      try {
        setLoading(true);
        setLoading(false);
        setConnecting(false);
        if (validNetwork) {
          setSnackbar({
            variant: 'success',
            message: 'Wallet connected successfully!',
          });
          handleNext();
        } else {
          setSnackbar({
            variant: 'error',
            message: 'Network is invalid! Please change to polygon network.',
          });
        }
      } catch (e) {
        const code = get(e, 'response.data.code', '');

        let message = '';
        switch (code) {
          case 'E_WALLET_ALREADY_EXISTS':
            message = 'Wallet already used';
            break;
          default:
            message = 'Failed connecting wallet';
        }
        setSnackbar({
          variant: 'error',
          message: message,
        });
        setLoading(false);
        setTimeout(async () => {
          await logoutOfWeb3Modal();
        }, 800);
      }
    };

    if (connecting) {
      onConnect();
    }
  }, [connecting]);

  const disabled = LOGIC_HELPERS.ifElse([loading], true, false, true);

  const handleWallet = () => {
    if (!validNetwork) {
      setSnackbar({
        variant: 'error',
        message: 'Network is invalid! Please change to polygon network.',
      });
    }
    if (!provider) {
      loadWeb3Modal();
    }
  };

  const renderWallet = () => {
    const connectedWallet = LOGIC_HELPERS.ifElse(
      address,
      address,
      'WALLET NOT CONNECTED',
    );
    const invalidNetwork = LOGIC_HELPERS.ifElse(
      !validNetwork,
      <b>(Invalid Network)</b>,
      '',
    );

    return (
      <Grid direction="column" container alignItems="center">
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Connected wallet:</b> {connectedWallet} {invalidNetwork}
        </Typography>
      </Grid>
    );
  };

  const onDisconnect = async () => {
    await logoutOfWeb3Modal();
  };

  if (provider && loaded && validNetwork && !isEmpty(wallet)) {
    if (loading) {
      return (
        <Button
          className={clsx(classes.flexCenter, classes.button)}
          disabled={disabled}
          variant="contained"
          color="primary"
          size="medium"
        >
          <Loading className={classes.loadingIcon} iconOnly />
          Connecting
        </Button>
      );
    }
    return (
      <>
        <div className={classes.root}>
          <Typography variant="h6" className={classes.header}>
            Claim PKR - Polygon Upgrade
          </Typography>
          <Button className={classes.button} onClick={handleRedirect}>
            PKR & Claim Contract Audit Report
          </Button>
          <div className={classes.row}>
            <Typography variant="h6" className={classes.processNum}>
              1.
            </Typography>
            <Typography
              variant="h6"
              className={clsx(classes.processText, classes.processHeader)}
            >
              Connect Wallet
            </Typography>
          </div>

          <div
            className={clsx(classes.claimContainer, classes.connectContainer)}
          >
            <Button
              onClick={onDisconnect}
              disabled={disabled}
              variant="contained"
              color="primary"
              size="small"
              className={clsx(classes.button, classes.noMargin)}
            >
              Disconnect Wallet
            </Button>
          </div>
          <Button
            onClick={handleNext}
            className={clsx(classes.button, classes.nextButton)}
            disabled={!provider && !loaded && !validNetwork && isEmpty(wallet)}
          >
            Next
          </Button>
          {renderWallet()}
        </div>
      </>
    );
  }
  const buttonText = validNetwork ? 'Connect Wallet' : 'Change Network';
  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Claim PKR - Polygon Upgrade
      </Typography>
      <Button className={classes.button} onClick={handleRedirect}>
        PKR & Claim Contract Audit Report
      </Button>
      <div className={classes.row}>
        <Typography variant="h6" className={classes.processNum}>
          1.
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.processText, classes.processHeader)}
        >
          Connect Wallet
        </Typography>
      </div>

      <div className={clsx(classes.claimContainer, classes.connectContainer)}>
        <Button
          onClick={handleWallet}
          variant="contained"
          color="primary"
          size="small"
          className={clsx(classes.button, classes.noMargin)}
        >
          {buttonText}
        </Button>
      </div>
      <Button
        onClick={handleNext}
        className={clsx(classes.button, classes.nextButton)}
        disabled={!provider || !loaded || isEmpty(wallet)}
      >
        Next
      </Button>
      {renderWallet()}
    </div>
  );
};

export default ConnectWallet;
