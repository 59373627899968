import { STORE } from 'store';
import React, { useState, useContext } from 'react';
import {
  Link,
  Modal,
  Button,
  Backdrop,
  IconButton,
  Icon,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import useStyles from './styles';
import { NFT } from 'constants/nft';
import CloseIcon from '@material-ui/icons/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSetStoreValue } from 'react-context-hook';
import { P2E_API } from 'apis/P2E/p2e';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import { Scrollbar } from 'react-scrollbars-custom';
import AccessoryIcon from '../../assets/white_head_gear.svg';
import PinkHeadGearIcon from '../../assets/pink_head_gear.svg';
import PinkChestGearIcon from '../../assets/pink_chest_gear.svg';
import PinkHandGearIcon from '../../assets/pink_hand_gear.svg';
import PinkLegGearIcon from '../../assets/pink_leg_gear.svg';
import PinkFeetGearIcon from '../../assets/pink_foot_gear.svg';
import HeadGearIcon from '../../assets/white_head_gear.svg';
import ChestGearIcon from '../../assets/white_chest_gear.svg';
import HandGearIcon from '../../assets/white_hand_gear.svg';
import LegGearIcon from '../../assets/white_leg_gear.svg';
import FeetGearIcon from '../../assets/white_foot_gear.svg';

const ViewNFT = props => {
  const { open, setOpen, dealer, refetchDealerList } = props;
  // eslint-disable-next-line no-undef
  const nodeEnv = process.env.REACT_APP_ENV;
  const [displayModal, setDisplayModal] = useState(false);
  const [showWithdrawNFT, setShowWithdrawNFT] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWithdrawn, setIsWithdrawn] = useState(false);
  const [hash, setHash] = useState('');
  const [viewAccessories, setViewAccessories] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [values, setValues] = React.useState({
    address: '',
  });
  const classes = useStyles();
  const theme = useTheme();

  // eslint-disable-next-line no-undef
  const contract = process.env.REACT_APP_POLYGON_DEALERS_SMART_CONTRACT_ADDRESS;
  const nft = NFT.find(item => item.name === dealer.name);

  let tokenId = dealer.id;
  let explorerUrl = 'https://amoy.polygonscan.com/token/';
  if (nodeEnv === 'production') {
    tokenId = dealer.id - 1;
    explorerUrl = 'https://polygonscan.com/token/';
  }

  const handleClose = () => {
    setOpen(false);
    setIsSubmitted(false);
    setIsWithdrawn(false);
    setViewAccessories(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setIsSubmitted(false);
    setIsWithdrawn(false);
    setDisplayModal(false);
    setShowWithdrawNFT(false);
  };

  const handleDisplayModal = () => {
    setDisplayModal(!displayModal);
  };

  const handleShowWithdrawNFT = () => {
    setShowWithdrawNFT(!showWithdrawNFT);
    setValues({ ...values, address: '' });
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = async () => {
    try {
      setDisplayModal(false);
      setIsSubmitted(true);
      setLoading(true);
      setHash(null);
      const payload = {
        uuid: dealer.uuid,
        address: values.address,
      };
      const res = await P2E_API.withdrawNft(payload);
      setHash(res.transaction_hash);
      await refetchDealerList();
      setLoading(false);
      setIsWithdrawn(true);
      setHash(res.transaction_hash);
      setSnackbar({
        variant: 'success',
        message: 'Success withdrawing NFT!',
      });
    } catch (e) {
      setSnackbar({
        variant: 'error',
        message: 'Failed withdrawing NFT!',
      });
      setLoading(false);
    }
  };

  const renderHash = () => {
    if (nodeEnv === 'production') {
      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://polygonscan.com/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }

    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://mumbai.polygonscan.com/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderSuccessWithdrawNFT = () => {
    return (
      <Modal
        hideBackdrop
        className={classes.modal}
        open={showWithdrawNFT}
        onClose={handleShowWithdrawNFT}
      >
        <Backdrop className={classes.backdrop} open={showWithdrawNFT}>
          <div
            className={clsx(classes.withdrawContainer, classes.verticalCenter)}
          >
            <Typography variant="h6" className={classes.withdrawHeader}>
              Withdraw POLKER NFT (Polygon Network)
            </Typography>
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.withdrawImage}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
            <div className={classes.withdrawContent}>
              <Typography className={classes.name}>
                NFT will be transferred to:
              </Typography>
              <Typography>{values.address}</Typography>
              <Button
                variant="outlined"
                className={clsx(
                  classes.withdrawButton,
                  classes.cancelWithdrawButton,
                  classes.closeWithdrawButton,
                )}
                onClick={handleCloseModal}
              >
                Close
              </Button>
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderSubmittedWithdrawNFT = () => {
    return (
      <Modal
        hideBackdrop
        className={classes.modal}
        open={showWithdrawNFT}
        onClose={handleShowWithdrawNFT}
      >
        <Backdrop className={classes.backdrop} open={showWithdrawNFT}>
          <div
            className={clsx(classes.withdrawContainer, classes.verticalCenter)}
          >
            {renderConfirmWithdraw()}
            <Typography variant="h6" className={classes.withdrawHeader}>
              Withdraw POLKER NFT (Polygon Network)
            </Typography>
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.withdrawImage}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
            <div className={classes.withdrawContent}>
              <Typography className={classes.name}>Sending to:</Typography>
              <div className={classes.addressRoot}>
                <Typography>{values.address}</Typography>
                <Loading iconOnly />
              </div>
              <Loading className={classes.bottomLoader} determinate size={80} />
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderWithdrawNotice = () => {
    return (
      <div className={classes.noticeRoot}>
        <Typography variant="subtitle1">
          Only Withdraw Polker NFT to a Polygon Network Address.
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          You are withdrawing Polker NFTs on a Polygon network.Verify Wallet
          address matches to the withdraw address.
        </Typography>
      </div>
    );
  };

  const renderConfirmWithdraw = () => {
    if (!displayModal) {
      return null;
    }

    return (
      <Modal
        className={classes.modal}
        open={displayModal}
        onClose={handleDisplayModal}
      >
        <Backdrop className={classes.backdrop} open={displayModal}>
          <div
            className={clsx(
              classes.withdrawContainer,
              classes.verticalCenter,
              classes.withdrawConfirmModal,
            )}
          >
            <Typography variant="body1" color="textPrimary">
              Are you sure you want to withdraw?
            </Typography>
            <div className={classes.buttonContainer}>
              <Button
                className={clsx(
                  classes.withdrawButton,
                  classes.cancelWithdrawButton,
                  classes.withdrawConfirmButton,
                )}
                onClick={handleDisplayModal}
              >
                Cancel
              </Button>
              <Button
                className={clsx(
                  classes.withdrawButton,
                  classes.withdrawConfirmButton,
                )}
                onClick={onSubmit}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderWithdrawNFT = () => {
    if (!showWithdrawNFT) {
      return null;
    }

    if (isSubmitted && loading) {
      return renderSubmittedWithdrawNFT();
    }

    if (isSubmitted && isWithdrawn) {
      return renderSuccessWithdrawNFT();
    }

    return (
      <Modal
        hideBackdrop
        className={classes.modal}
        open={showWithdrawNFT}
        onClose={handleShowWithdrawNFT}
      >
        <Backdrop className={classes.backdrop} open={showWithdrawNFT}>
          <div
            className={clsx(classes.withdrawContainer, classes.verticalCenter)}
          >
            {renderConfirmWithdraw()}
            <Typography variant="h6" className={classes.withdrawHeader}>
              Withdraw POLKER NFT (Polygon Network)
            </Typography>
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.withdrawImage}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
            <div className={classes.withdrawContent}>
              <Typography className={classes.name}>
                Polker NFT Wallet Address:
              </Typography>
              <MuiThemeProvider theme={theme}>
                <FormControl
                  className={classes.formField}
                  fullWidth
                  variant="standard"
                >
                  <OutlinedInput
                    type="text"
                    value={values.address}
                    className={classes.field}
                    onChange={handleChange('address')}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon edge="end">
                          {values.address ? (
                            <CheckCircleIcon className={classes.icon} />
                          ) : null}
                        </Icon>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </MuiThemeProvider>
              {renderWithdrawNotice()}
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={clsx(
                    classes.withdrawButton,
                    classes.cancelWithdrawButton,
                  )}
                  onClick={handleShowWithdrawNFT}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  className={classes.withdrawButton}
                  onClick={handleDisplayModal}
                  disabled={values.address === ''}
                >
                  Withdraw
                </Button>
              </div>
            </div>
          </div>
        </Backdrop>
      </Modal>
    );
  };

  const renderAccessoryDetails = (data, type) => {
    const acceType = type.toUpperCase();
    const res = data.accessories.find(val => val.type === acceType);
    let icon;
    if (res) {
      switch (type) {
        case 'Head':
          icon = (
            <img className={classes.accessoryIcon} src={PinkHeadGearIcon} />
          );
          break;
        case 'Chest':
          icon = (
            <img className={classes.accessoryIcon} src={PinkChestGearIcon} />
          );
          break;
        case 'Hand':
          icon = (
            <img className={classes.accessoryIcon} src={PinkHandGearIcon} />
          );
          break;
        case 'Legs':
          icon = (
            <img className={classes.accessoryIcon} src={PinkLegGearIcon} />
          );
          break;
        case 'Feet':
          icon = (
            <img className={classes.accessoryIcon} src={PinkFeetGearIcon} />
          );
          break;
        default:
          break;
      }

      return (
        <>
          {icon}
          <Typography variant="body1">NAME: {res.name}</Typography>
          <Typography variant="body1">COLOR: {res.color}</Typography>
          <Typography variant="body1">
            MODIFIERS: {res.modifiers.length > 0 ? null : 'NONE'}
          </Typography>
          {res.modifiers.map((v, i) => {
            return (
              <>
                <Typography variant="body1">
                  +{v.value} {v.type.replace('_', ' ')}
                </Typography>
              </>
            );
          })}
        </>
      );
    } else {
      switch (type) {
        case 'Head':
          icon = <img className={classes.accessoryIcon} src={HeadGearIcon} />;
          break;
        case 'Chest':
          icon = <img className={classes.accessoryIcon} src={ChestGearIcon} />;
          break;
        case 'Hand':
          icon = <img className={classes.accessoryIcon} src={HandGearIcon} />;
          break;
        case 'Legs':
          icon = <img className={classes.accessoryIcon} src={LegGearIcon} />;
          break;
        case 'Feet':
          icon = <img className={classes.accessoryIcon} src={FeetGearIcon} />;
          break;
        default:
          break;
      }
      return (
        <>
          {icon}
          <Typography variant="body1">{type} Gear - Default</Typography>
        </>
      );
    }
  };

  return (
    <Modal
      hideBackdrop
      className={classes.modal}
      open={open}
      onClose={handleClose}
    >
      <Backdrop className={classes.backdrop} open>
        <IconButton
          color="secondary"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        {renderWithdrawNFT()}
        <div className={classes.container}>
          {!viewAccessories ? (
            <div className={classes.imageRoot}>
              <LazyLoadImage
                className={classes.image}
                effect="blur"
                visibleByDefault
                src={dealer.image}
              />
            </div>
          ) : (
            <div className={classes.accessoryDetails}>
              <div className={classes.accessoryList}>
                <Scrollbar>
                  <div style={{ textAlign: 'center' }}>
                    <Typography variant="body1" className={classes.name}>
                      NFT BREAKDOWN
                    </Typography>
                  </div>
                  <div className={classes.accessoryDesc}>
                    {renderAccessoryDetails(dealer, 'Head')}
                  </div>
                  <div className={classes.accessoryDesc}>
                    {renderAccessoryDetails(dealer, 'Chest')}
                  </div>
                  <div className={classes.accessoryDesc}>
                    {renderAccessoryDetails(dealer, 'Hand')}
                  </div>
                  <div className={classes.accessoryDesc}>
                    {renderAccessoryDetails(dealer, 'Legs')}
                  </div>
                  <div className={classes.accessoryDesc}>
                    {renderAccessoryDetails(dealer, 'Feet')}
                  </div>
                </Scrollbar>
              </div>
            </div>
          )}
          <div className={classes.content}>
            <Typography variant="h6" className={classes.name}>
              {dealer.name}
            </Typography>
            <Typography variant="body1" className={classes.location}>
              {nft?.location}
            </Typography>
            <Typography variant="body1" className={classes.description}>
              {nft?.description}
            </Typography>
            <div className={classes.attribute}>
              {dealer.name == 'Iris' ||
                (dealer.name == 'Felicity' && (
                  <div className={classes.card}>
                    <Typography className={classes.attributeName}>
                      Standard
                    </Typography>
                    <img src="./images/S.png" />
                  </div>
                ))}
              {dealer.name == 'Saho' && (
                <div className={classes.card}>
                  <Typography className={classes.attributeName}>
                    Rare
                  </Typography>
                  <img src="./images/R.png" />
                </div>
              )}
              <div className={classes.card}>
                <Typography className={classes.attributeName}>Level</Typography>
                <div className={classes.level}>
                  <Typography variant="h5">1</Typography>
                </div>
              </div>
              {/* <div
                className={classes.accessoryCard}
                onClick={() =>
                  setViewAccessories(viewAccessories ? false : true)
                }
              >
                <Typography className={classes.attributeName}>
                  ACCESSORIES
                </Typography>
                <div>
                  <Typography variant="h3">
                    <img src={AccessoryIcon} />
                  </Typography>
                </div>
              </div> */}
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Token ID:
              </Typography>
              <Typography variant="body1">{tokenId}</Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Token Standard:
              </Typography>
              <Typography variant="body1">ERC-1155</Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Blockchain:
              </Typography>
              <Typography variant="body1">Polygon</Typography>
            </div>
            <div className={classes.row}>
              <Typography variant="body1" className={classes.title}>
                Contract Address:
              </Typography>
              <Link
                href={`${explorerUrl}${contract}?a=${tokenId}`}
                underline="none"
                target="_blank"
              >
                <Typography variant="body1">{contract}</Typography>
              </Link>
            </div>
            <Button
              variant="outlined"
              className={classes.withdrawButton}
              onClick={handleShowWithdrawNFT}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </Backdrop>
    </Modal>
  );
};

export default ViewNFT;
