import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Typography, Modal, Input } from '@material-ui/core';
import { STORE } from 'store';
import useAuth from 'hooks/useAuth';
import Loading from 'components/Loading';
import { useWallets } from 'hooks/wallets';
import { find, get, isEmpty } from 'lodash';
import { MOMENT } from 'helpers/moment';
import { LOGIC_HELPERS } from 'helpers/logic';
import { ETHERS_SERVICE } from 'services/ethers';
import { PKR_PASS_API } from 'apis/PKRPass/pass';
import StakingContext from 'contexts/StakingContext';
import { usePKRConversions } from 'hooks/assets';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSetStoreValue } from 'react-context-hook';
import { useCheckPending, usePasses, usePassesList } from 'hooks/passes';
import { SYNC_API } from 'apis/Transactions/sync';
import useStyles from './styles';
import {
  BSC_MAINNET_ID,
  BSC_TESTNET_ID,
  ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
  POLYGON_MAINNET,
  POLYGON_TESTNET,
} from 'constants/networkConstants';
import { USERS_API } from 'apis/user';
import Wallet from 'views/Shop/components/Wallet';
import clsx from 'clsx';

const BuyPkrPass = () => {
  // eslint-disable-next-line no-undef
  const nodeEnv = process.env.REACT_APP_ENV;

  const classes = useStyles();
  const [hash, setHash] = useState('');
  const [balance, setBalance] = useState('0.0');
  const [refetch, setRefetch] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nuveiCode, setNuveiCode] = useState('');
  const [isPending, setIsPending] = useState(false);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const [passList, setPassList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [syncDisabled, setSyncDisabled] = useState(true);
  const [ownedPassCost, setOwnedPassCost] = useState(0);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [displayNuveiCodeModal, setDisplayNuveiCodeModal] = useState(false);
  const [previousPkrPass, setPreviousPkrPass] = useState(null);
  const [userHasPreviousAcct, setUserHasPreviousAcct] = useState(null);
  const [hasPromo, setHasPromo] = useState();

  const { provider, wallet, loaded, validNetwork } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const walletNetworkId = LOGIC_HELPERS.switchCase(networkId, {
    [ETHEREUM_MAINNET_ID]: 1,
    [ETHEREUM_TESTNET_ID]: 1,
    [BSC_MAINNET_ID]: 2,
    [BSC_TESTNET_ID]: 2,
    [POLYGON_MAINNET]: 4,
    [POLYGON_TESTNET]: 4,
    default: 0,
  });
  const slippage = 0.025;
  const { data: wallets } = useWallets(walletNetworkId);
  const { data: initialList, isLoading: initialLoading } = usePassesList();
  const { data: userHasPass, refetch: refretchUserHasPass } = usePasses();
  const { data: is_pending, refetch: refetchCheckPending } = useCheckPending();
  const { data: pkrConversion, refetch: refetchPkrConversion } =
    usePKRConversions({ refetchInterval: 30000 });
  const standardRate = get(pkrConversion, 'conversion_rate', 0);
  const rate = standardRate - standardRate * slippage;
  const marketBonus = {
    Free: 0,
    Bronze: 3,
    Silver: 5,
    Gold: 20,
    Platinum: 30,
    Diamond: 50,
  };
  const [itemId, setItemId] = useState(null);
  let userPassName = get(userHasPass, '0.pkrPasses.name', '');
  const auth = useAuth();

  useEffect(() => {
    const fetchBalance = async () => {
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const res = await ethersHelper.methods.checkPKRBalance();
      setBalance(res);
    };

    const initialized = LOGIC_HELPERS.ifElse(
      [provider, validNetwork, wallets?.length],
      true,
      false,
    );
    if (initialized && loaded) {
      const connected = find(wallets, { address });
      const status = !isEmpty(connected);
      setEnabled(status);
      setRefetch(false);
      if (status) {
        fetchBalance();
      }
    }

    const checkUserPreviousAccount = async () => {
      const userRecord = await USERS_API.browsePreviousAccount({
        email: auth?.user?.email,
      });
      if (userRecord.length > 0 && userRecord[0].is_migrated === false) {
        setUserHasPreviousAcct(true);
        let pkrPass;
        switch (userRecord[0].pkr_pass_id) {
          case 1:
            pkrPass = 'Bronze';
            break;
          case 2:
            pkrPass = 'Silver';
            break;
          case 3:
            pkrPass = 'Gold';
            break;
          case 4:
            pkrPass = 'Platinum';
            break;
          case 5:
            pkrPass = 'Diamond';
            break;
        }
        setPreviousPkrPass(pkrPass);
      } else {
        setUserHasPreviousAcct(false);
      }
    };
    checkUserPreviousAccount();
  }, [
    provider,
    loaded,
    validNetwork,
    address,
    hash,
    wallets,
    refetch,
    userHasPreviousAcct,
    previousPkrPass,
  ]);

  useEffect(() => {
    const setListByOwnedPass = async () => {
      const ownedPass = get(userHasPass, '0.pkrPasses.name', '');
      let updatedList = initialList.map(item => {
        switch (ownedPass) {
          case 'Bronze':
            if (item.name === 'Bronze') {
              setOwnedPassCost(item.config.amount);
              return {
                ...item,
                disabled: true,
                buyDisabled: true,
              };
            }
            return {
              ...item,
              disabled: false,
              buyDisabled: true,
            };
          case 'Silver':
            if (item.name === 'Silver') {
              setOwnedPassCost(item.config.amount);
            }
            if (item.name === 'Bronze' || item.name === 'Silver') {
              return {
                ...item,
                disabled: true,
                buyDisabled: true,
              };
            }
            return {
              ...item,
              disabled: false,
              buyDisabled: true,
            };
          case 'Gold':
            if (item.name === 'Gold') {
              setOwnedPassCost(item.config.amount);
            }
            if (
              item.name === 'Bronze' ||
              item.name === 'Silver' ||
              item.name === 'Gold'
            ) {
              return {
                ...item,
                disabled: true,
                buyDisabled: true,
              };
            }
            return {
              ...item,
              disabled: false,
              buyDisabled: true,
            };
          case 'Platinum':
            if (item.name === 'Platinum') {
              setOwnedPassCost(item.config.amount);
            }
            if (
              item.name === 'Bronze' ||
              item.name === 'Silver' ||
              item.name === 'Gold' ||
              item.name === 'Platinum'
            ) {
              return {
                ...item,
                disabled: true,
                buyDisabled: true,
              };
            }
            return {
              ...item,
              disabled: false,
              buyDisabled: true,
            };
          case 'Diamond':
            if (item.name === 'Diamond') {
              setOwnedPassCost(item.config.amount);
            }
            if (
              item.name === 'Bronze' ||
              item.name === 'Silver' ||
              item.name === 'Gold' ||
              item.name === 'Platinum' ||
              item.name === 'Diamond'
            ) {
              return {
                ...item,
                disabled: true,
                buyDisabled: true,
              };
            }
            return {
              ...item,
              disabled: false,
              buyDisabled: true,
            };
          default:
            return {
              ...item,
              disabled: false,
              buyDisabled: true,
            };
        }
      });

      updatedList = updatedList.map(item => ({
        ...item,
        image: `${item.name.toLowerCase()}.png`,
        back: `${item.name.toLowerCase()}-back-v2.png`,
      }));

      setPassList(updatedList);
    };

    if (userHasPass && initialList) {
      setListByOwnedPass();
    }

    const pending = get(is_pending, 'is_pending', false);
    setIsPending(pending);
  }, [userHasPass, initialList, is_pending]);

  useEffect(() => {
    const onInitialSync = async () => {
      const res = await SYNC_API.browsePending();
      const isDisabled = !res?.sync_required_for_pkr_pass;
      setSyncDisabled(isDisabled);
    };

    onInitialSync();
  }, []);

  const isDisabledButton = () => {
    const today = MOMENT.dateNowUtc();
    const endDate = MOMENT.dateUtcInTimestamp(1647270000000);
    const diffTime = MOMENT.diffInUnit(today, endDate, 's');

    return diffTime > 0;
  };

  const enableSync = async () => {
    const res = await SYNC_API.browsePending();
    const isDisabled = !res?.sync_required_for_pkr_pass;
    setSyncDisabled(isDisabled);

    if (isDisabled) {
      setHash(null);
    }
  };

  const warningModal = () => {
    return (
      <>
        <Modal
          open={displayWarningModal}
          onClose={() => setDisplayWarningModal(false)}
        >
          <div className={classes.modalContainer}>
            <Typography variant="h5" style={{ color: 'red' }}>
              Ooops!
            </Typography>
            <br />
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              color="textPrimary"
            >
              You have an existing {previousPkrPass} PKR PASS on your previous
              account.
            </Typography>
            <br />
            <Button
              fullWidth
              color="primary"
              // eslint-disable-next-line no-undef
              href={process.env.REACT_APP_ACCOUNT_MIGRATION_URL}
              target="_blank"
              onClick={() => setDisplayWarningModal(false)}
              className={classes.migrateBtn}
            >
              Migrate your previous account?
            </Button>
          </div>
        </Modal>
      </>
    );
  };

  const onSubmit = async (passId, amount) => {
    try {
      setLoading(true);
      const res = await refetchPkrConversion();
      setHash(null);
      const { is_valid } = await PKR_PASS_API.check(passId);
      if (is_valid) {
        const marketRate = get(res, 'data.conversion_rate', rate);
        const passAmount = parseFloat(marketRate - marketRate * slippage);
        const convertedAmount = parseFloat(amount / passAmount);
        const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
        const tx = await ethersHelper.methods.depositPKR(
          convertedAmount.toString(),
        );
        const payload = {
          txHash: tx.hash,
          network_type_id: walletNetworkId,
          pkr_pass_id: passId,
        };
        await PKR_PASS_API.buy(payload);
        setHash(tx.hash);

        setSnackbar({
          variant: 'success',
          message: 'Success buying PKR Pass!',
        });
      }
      setRefetch(true);
      await refetchCheckPending();
      await enableSync();
      setLoading(false);
    } catch (e) {
      const code = get(e, 'response.data.code', '');
      console.log({ e });

      if (e.code === 4001) {
        setSnackbar({
          variant: 'error',
          message: 'User has cancelled/rejected the transaction.',
        });
      } else {
        let message = '';
        switch (code) {
          case 'E_TRANSACTION_ALREADY_EXISTS':
            message = 'Transaction already exists';
            break;
          case 'E_UPGRADE_NOT_ELIGIBLE':
            message = 'Invalid pass tier or 72 hours has not passed';
            break;
          default:
            message = 'Failed buying PKR Pass!';
        }
        setSnackbar({
          variant: 'error',
          message: message,
        });
      }
      setLoading(false);
    }
  };

  const onSubmitCard = async (passId, code) => {
    try {
      setLoading(true);
      setHash(null);
      const { is_valid } = await PKR_PASS_API.check(passId);
      if (is_valid) {
        const payload = {
          pkr_pass_id: passId,
          code,
        };
        const data = await PKR_PASS_API.nuveiBuy(payload);
        window.location.replace(data.url);
      }
    } catch (e) {
      const code = get(e, 'response.data.code', '');
      console.log({ e });

      if (e.code === 4001) {
        setSnackbar({
          variant: 'error',
          message: 'User has cancelled/rejected the transaction.',
        });
      } else {
        let message = '';
        switch (code) {
          case 'E_TRANSACTION_ALREADY_EXISTS':
            message = 'Transaction already exists';
            break;
          case 'E_UPGRADE_NOT_ELIGIBLE':
            message = 'Invalid pass tier or 72 hours has not passed';
            break;
          case 'E_DISCOUNT_NOT_ELIGIBLE':
            message = 'Discount not eligible';
            break;
          case 'E_DISCOUNT_CODE_ALREADY_USED':
            message = 'Discount code already used';
            break;
          case 'E_INVALID_CODE':
            message = 'Invalid discount code';
            break;
          default:
            message = 'Failed buying PKR Pass!';
        }
        setSnackbar({
          variant: 'error',
          message: message,
        });
      }
      setLoading(false);
    }
  };

  const onSync = async () => {
    try {
      setLoading(true);
      setRefetch(true);
      await PKR_PASS_API.sync();
      await refretchUserHasPass();
      await refetchCheckPending();
      await enableSync();
      setHash(null);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onSelectPass = i => {
    const updatedPassList = passList.map((item, index) => {
      if (index === i) {
        return {
          ...item,
          buyDisabled: false,
        };
      } else {
        return {
          ...item,
          buyDisabled: true,
        };
      }
    });
    setPassList(updatedPassList);
  };

  const onChangeCode = e => {
    e.preventDefault();
    setNuveiCode(e.target.value);
  };

  const renderInputNuveiCode = () => {
    return (
      <Modal
        open={displayNuveiCodeModal}
        onClose={() => setDisplayNuveiCodeModal(false)}
      >
        <div className={classes.modalContainer}>
          <Input
            size="small"
            value={nuveiCode}
            disableUnderline
            variant="outlined"
            onChange={onChangeCode}
            label="P2P Code (Optional)"
            placeholder="P2P Code (Optional)"
            inputProps={{ className: classes.input }}
          />
          <Button
            size="small"
            className={classes.proceedButton}
            onClick={() => {
              onSubmitCard(itemId, nuveiCode);
              setDisplayNuveiCodeModal(false);
            }}
            loading={loading}
            variant="outlined"
          >
            Proceed
          </Button>
        </div>
      </Modal>
    );
  };

  const renderSync = () => {
    if (syncDisabled) {
      return null;
    }

    if (loading) {
      return (
        <Button className={classes.syncButton}>
          <Loading className={classes.loadingIcon} iconOnly />
          Syncing
        </Button>
      );
    }
    return (
      <div className={classes.note}>
        <Button className={classes.syncButton} onClick={onSync}>
          SYNC
        </Button>
      </div>
    );
  };

  const renderHash = () => {
    if (!hash) {
      return null;
    }

    if (walletNetworkId === 1) {
      if (nodeEnv === 'production') {
        return (
          <Typography className={classes.hash} color="textPrimary">
            You can check if the transaction is done{' '}
            <a
              href={`https://etherscan.io/tx/${hash}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            .
          </Typography>
        );
      }

      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://goerli.etherscan.io/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }

    if (nodeEnv === 'production') {
      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://bscscan.com/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }
    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://testnet.bscscan.com/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderWallet = () => {
    const ownedPass = get(userHasPass, '0.pkrPasses.name', '');

    const connectedWallet = LOGIC_HELPERS.ifElse(
      address,
      address,
      'WALLET NOT CONNECTED',
    );
    const invalidNetwork = LOGIC_HELPERS.ifElse(
      !validNetwork,
      <b>(Invalid Network)</b>,
      '',
    );

    if (!userHasPass?.length) {
      return (
        <Grid direction="column" container>
          <Typography color="textPrimary" className={classes.displayWallet}>
            <b>Connected wallet:</b> {connectedWallet} {invalidNetwork}
          </Typography>
          <div className={classes.passInfoRoot}>
            <div className={classes.row}>
              <Typography color="textPrimary" className={classes.dealsHeader}>
                <b>PKR Pass:</b>
              </Typography>
              <Typography className={classes.dealsHeader} variant="subtitle1">
                None
              </Typography>
            </div>
          </div>
        </Grid>
      );
    }

    return (
      <Grid direction="column" container>
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Connected wallet:</b> {connectedWallet}
        </Typography>
        <div className={classes.passInfoRoot}>
          <div className={classes.row}>
            <Typography color="textPrimary" className={classes.dealsHeader}>
              <b>PKR Pass:</b>
            </Typography>
            <Typography className={classes.dealsHeader} variant="subtitle1">
              {ownedPass}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  const renderBalance = () => {
    return (
      <Grid
        direction="row"
        xs={12}
        container
        className={clsx(classes.walletRoot, {
          [classes.hasPromoClosed]: hasPromo === 'closed',
        })}
      >
        <div className={classes.walletContainer}>{renderWallet()}</div>
        <div className={classes.column}>
          {renderSync()}
          <div className={classes.balanceInfoRoot}>{renderHash()}</div>
        </div>
      </Grid>
    );
  };

  const renderCost = (item, pkrPassAmnt, deductedPassCost) => {
    if (deductedPassCost === 0) {
      return (
        <div className={classes.owned}>
          <Typography color="textPrimary" className={classes.usd}>
            Owned
          </Typography>
        </div>
      );
    }

    if (!item.is_enabled) {
      return (
        <div>
          <Typography color="textPrimary" className={classes.soon}>
            Coming soon
          </Typography>
        </div>
      );
    }

    let pkrDetails = null;
    if (userHasPass.length !== 0) {
      pkrDetails = (
        <div>
          <Typography color="textPrimary" className={classes.usd}>
            {deductedPassCost} USD
          </Typography>
          <Typography color="textSecondary" className={classes.from}>
            from
            <Typography color="textSecondary" className={classes.price}>
              {pkrPassAmnt} USD
            </Typography>
          </Typography>
        </div>
      );
    } else {
      pkrDetails = (
        <div>
          <Typography color="textPrimary" className={classes.usd}>
            {deductedPassCost} USD
          </Typography>
        </div>
      );
    }
    return pkrDetails;
  };

  const handleBuyNow = (itemId, i) => {
    setItemId(itemId);
    onSelectPass(i);
  };

  const renderBuyNow = (item, deductedPassCost, index) => {
    // USD Cost
    const pkrPassAmount = parseInt(item.config.amount) - ownedPassCost;
    const ownedPassId = get(userHasPass, '0.pkrPasses.id', '');
    let passUsdCost = null;
    if (userPassName !== '') {
      passUsdCost = Math.max(
        pkrPassAmount - (pkrPassAmount * marketBonus[userPassName]) / 100,
        0,
      );
    } else {
      passUsdCost = Math.max(parseInt(item.config.amount) - ownedPassCost, 0);
    }

    // PKR Cost
    const marketRate = get(pkrConversion, 'conversion_rate', rate);
    const passAmount = parseFloat(marketRate - marketRate * slippage);
    const convertedAmount = parseFloat(deductedPassCost / passAmount);

    let pkrPassButton = (
      <div className={classes.subBtnContainer}>
        <Button
          className={classes.buyNowSubBtn}
          disabled={item.buyDisabled || !rate}
          style={{ marginRight: 12 }}
          onClick={() =>
            userHasPreviousAcct
              ? setDisplayWarningModal(true)
              : onSubmit(item.id, deductedPassCost)
          }
        >
          {convertedAmount}
        </Button>
        <div style={{ marginRight: 12 }}>PKR</div>
      </div>
    );

    if (!enabled || !validNetwork) {
      pkrPassButton = null;
    }

    return (
      <>
        {item.is_enabled && item.id > ownedPassId ? (
          <Button
            className={classes.buyNowBtn}
            disabled={loading}
            onClick={() => {
              handleBuyNow(item.id, index);
            }}
          >
            Buy Now
          </Button>
        ) : null}
        {itemId === item.id ? (
          <div className={classes.buyNowContainer}>
            {pkrPassButton}
            <div className={classes.subBtnContainer}>
              <Button
                className={classes.buyNowSubBtn}
                disabled={item.buyDisabled || !rate}
                onClick={() =>
                  userHasPreviousAcct
                    ? setDisplayWarningModal(true)
                    : setDisplayNuveiCodeModal(true)
                }
              >
                {'$' + passUsdCost}
              </Button>
              <div>USD</div>
            </div>
          </div>
        ) : (
          <div style={{ height: 60 }} />
        )}
      </>
    );
  };

  const renderBuyButtons = (item, deductedPassCost) => {
    let buyUsingPKR = (
      <Button
        className={classes.buyChips}
        disabled={item.buyDisabled || !rate}
        onClick={() =>
          userHasPreviousAcct
            ? setDisplayWarningModal(true)
            : onSubmit(item.id, deductedPassCost)
        }
      >
        Buy using PKR
      </Button>
    );

    if (!enabled || !validNetwork) {
      buyUsingPKR = null;
    }

    return (
      <>
        {buyUsingPKR}
        <Button
          className={classes.buyPass}
          disabled={item.buyDisabled || !rate}
          onClick={() =>
            userHasPreviousAcct
              ? setDisplayWarningModal(true)
              : onSubmitCard(item.id, deductedPassCost)
          }
        >
          Buy using card
        </Button>
      </>
    );
  };

  const renderItem = (item, index) => {
    const pkrPassAmount = parseInt(item.config.amount) - ownedPassCost;
    let deductedPassCost = null;
    if (userPassName !== '') {
      deductedPassCost = Math.max(
        pkrPassAmount - (pkrPassAmount * marketBonus[userPassName]) / 100,
        0,
      );
    } else {
      deductedPassCost = Math.max(
        parseInt(item.config.amount) - ownedPassCost,
        0,
      );
    }
    if (index !== selected) {
      return (
        <div className={classes.dealsCard}>
          <div className={classes.chipPrice}>
            <LazyLoadImage
              width={320}
              height={591}
              effect="blur"
              visibleByDefault
              className={classes.image}
              src={`/images/pass/${item.image}`}
            />
            <img
              alt="pass-button"
              src="/images/info-icon.png"
              onClick={() => setSelected(index)}
              className={classes.passIcon}
            />
            <div className={classes.priceRoot}>
              {renderCost(item, pkrPassAmount, deductedPassCost)}
              {loading && !item.buyDisabled ? (
                <Button
                  className={classes.buyChips}
                  disabled={item.buyDisabled || !rate}
                >
                  <Loading
                    loadingText="Please wait..."
                    textClassName={classes.loading}
                  />
                </Button>
              ) : (
                // renderBuyButtons(item, deductedPassCost)
                renderBuyNow(item, deductedPassCost, index)
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.dealsCard}>
        <div className={classes.chipPrice}>
          <LazyLoadImage
            width={320}
            height={591}
            effect="blur"
            visibleByDefault
            className={classes.image}
            src={`/images/pass/${item.back}`}
          />
          <img
            alt="pass-button"
            src="/images/back-icon.png"
            onClick={() => {
              setSelected(null);
            }}
            className={classes.passIcon}
          />
        </div>
      </div>
    );
  };

  const renderPasses = () => (
    <Grid item className={classes.dealsRoot}>
      <div className={classes.dealsContent}>
        <Typography className={classes.dealHeader}>PASS TIERS</Typography>
        <Grid
          spacing={4}
          alignItems="center"
          direction="column"
          container
          className={classes.dealsContainer}
        >
          {passList.map((item, index) => {
            if (!item.is_enabled) {
              item.disabled = true;
              item.buyDisabled = true;
            }
            return (
              <div className={classes.radioContainer}>
                {/* <input
                  id={item.id}
                  value={item.id}
                  name="priceId"
                  type="radio"
                  onClick={() => onSelectPass(index)}
                  className={classes.radioButton}
                  autoComplete="off"
                  disabled={item.disabled || isPending}
                /> */}
                {renderItem(item, index)}
              </div>
            );
          })}
        </Grid>
      </div>
    </Grid>
  );

  return (
    <Grid
      md
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <div className={classes.wallet}>
        <Wallet setHasPromo={setHasPromo} />
      </div>
      {renderBalance()}
      {renderPasses()}
      {warningModal()}
      {renderInputNuveiCode()}
    </Grid>
  );
};

export default BuyPkrPass;
