import React from 'react';
import SimpleDialog from 'components/SimpleDialog';
import Terms from 'components/Terms';
import useStyles from './styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

const TermsModal = props => {
  const { open, setOpen } = props;
  const classes = useStyles();

  const dialogProps = {
    open,
    setOpen,
    maxWidth: 'md',
    noTitle: true,
    noButtons: true,
    autoClose: true,
  };

  if (!open) {
    return null;
  }

  return (
    <SimpleDialog {...dialogProps}>
      <PerfectScrollbar>
        <div className={classes.statement}>
          <Terms />
        </div>
      </PerfectScrollbar>
    </SimpleDialog>
  );
};

export default TermsModal;
