import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    height: '100%',
    fontWeight: 200,
    color: '#fff',
    flexDirection: 'column',
  },
  button: {
    border: '2px solid #E6007A',
    borderRadius: 30,
    minWidth: 160,
    zIndex: 1,
    marginBottom: 24,
  },
  header: {
    marginBottom: 16,
    fontWeight: 700,
  },
  claimContainer: {
    zIndex: 1,
    display: 'flex',
    borderRadius: 8,
    margin: '0 auto',
    padding: '52px 48px 12px 48px',
    flexDirection: 'column',
    backgroundColor: 'rgb(255, 255, 255, 0.15)',
    maxWidth: 520,
  },
  loginBtn: {
    marginTop: 16,
    marginBottom: 24,
  },
  banner: {
    fontSize: 17,
    display: 'flex',
    borderRadius: 4,
    fontWeight: 'bold',
    padding: '12px 16px',
    alignItems: 'center',
    backgroundColor: '#f31f90',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
    },
  },
  bannerContent: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoIcon: {
    width: 24,
    marginRight: 8,
  },
  coloredText: {
    color: '#E6007A',
    margin: '12px 0px',
  },
  nextButton: {
    marginTop: 24,
    '&:disabled': {
      border: '2px solid #333',
      color: '#333',
    },
  },
  process: {
    marginTop: 16,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  processNum: {
    color: '#E6007A',
    fontSize: 25,
    marginRight: 4,
  },
  processText: {
    background: 'rgba(230, 0, 122, 0.20)',
    width: '100%',
    fontSize: 25,
    marginBottom: 8,
    padding: '0px 4px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  connectContainer: {
    padding: '52px 48px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkContainer: {
    padding: '52px 48px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  processHeader: {
    background: 'none',
  },
  noMargin: {
    margin: 0,
  },
  eligibilityText: {
    textAlign: 'center',
    fontSize: 20,
  },
  marginTop: {
    marginTop: 24,
    marginBottom: 0,
  },
  approvedText: {
    color: '#0FA958',
    fontSize: 25,
    marginBottom: 24,
  },
  notApprovedText: {
    color: '#ED4141',
    fontSize: 25,
    marginBottom: 24,
  },
  amountText: {
    fontSize: 15,
    marginTop: 24,
  },
  amount: {
    color: '#E6007A',
    fontSize: 25,
  },
  success: {
    color: '#E6007A',
    fontSize: 30,
    marginBottom: 20,
  },
  rejected: {
    color: '#ED4141',
    fontSize: 30,
    marginBottom: 20,
  },
  approvedContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  displayWallet: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
    },
  },
}));
