// eslint-disable-next-line no-undef
const redirectUrl = `${process.env.REACT_APP_URL}/login`;
// eslint-disable-next-line no-undef
const stakingPortal = process.env.REACT_APP_STAKING_PORTAL_URL;
// eslint-disable-next-line no-undef
const CLIENT_ID = process.env.REACT_APP_EOS_CLIENT_ID;

export const URL_HELPERS = {
  store: '/store',
  login: '/login',
  notFound: '/not-found',
  settings: '/settings',
  shop: '/shop',
  stakingPortal,
  pkrPass: '/pkr-pass',
  spades: '/spades',
  chips: '/chips',
  referrals: '/referrals',
  nftWallet: '/nft-wallet',
  home: '/nft-wallet',
  redirect: `https://www.epicgames.com/id/authorize?client_id=${CLIENT_ID}&response_type=code&scope=basic_profile%20presence%20friends_list&redirect_uri=${redirectUrl}`,
  invalidAccount: '/invalid-account',
  paymentSuccess: '/payment-success',
  paymentPending: '/payment-pending',
  paymentFailed: '/payment-failed',
  claimToken: '/claim-token',
  redirectAudit:
    'https://polker-veridise-audit.s3.amazonaws.com/Veridise+Auditing+Report.pdf',
};

export const URL_TOGGLE = {
  portal: [
    URL_HELPERS.store,
    URL_HELPERS.buy,
    URL_HELPERS.login,
    URL_HELPERS.register,
  ],
};
