import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingIcon: {
    marginRight: 4,
  },
  button: {
    border: '2px solid #E6007A',
    borderRadius: 30,
    backgroundColor: 'transparent',
  },
  showWalletButton: {
    marginLeft: 8,
  },
}));
