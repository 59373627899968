import { useState, useEffect } from 'react';
import { useStoreValue } from 'react-context-hook';
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalAccount,
  useWeb3ModalSigner,
} from '@web3modal/ethers5/react';
import {
  POLYGON_MAINNET,
  POLYGON_TESTNET,
  RPC_URL_DEV,
  RPC_URL_PROD,
} from 'constants/networkConstants';
import { STORE } from 'store';

const NETWORK_ID =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_ENV === 'production'
    ? POLYGON_MAINNET
    : POLYGON_TESTNET;

const RPC_URL =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_ENV === 'production' ? RPC_URL_PROD : RPC_URL_DEV;

export default function useNewWeb3Modal(config = {}) {
  const [provider, setProvider] = useState(null);
  const stakingNetwork = useStoreValue(STORE.STAKING_NETWORK);
  const [externalProvider, setExternalProvider] = useState(null);
  const [autoLoaded, setAutoLoaded] = useState(false);
  const [logout, setLogout] = useState(false);
  const { autoLoad = true } = config;
  const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID;
  const ethersConfig = defaultConfig({
    metadata: {
      name: 'Polker Game',
      description: 'The Ultimate Play-to-Earn NFT Online Poker Game',
      url: 'https://polker.game',
      icons: ['https://avatars.githubusercontent.com/u/37784886'],
    },
    defaultChainId: NETWORK_ID,
    rpcUrl: RPC_URL,
  });
  const modal = createWeb3Modal({
    ethersConfig,
    chains: [NETWORK_ID],
    projectId,
    enableAnalytics: false,
    themeMode: 'dark',
  });

  // const modal = useWeb3Modal();
  const signer = useWeb3ModalSigner();
  const account = useWeb3ModalAccount();

  const loadWeb3Modal = async () => {
    try {
      if (!signer?.signer) {
        modal.open({ view: 'Connect' });
      }
      setProvider(signer.walletProvider);
    } catch (e) {
      console.log(e);
    }
  };

  const logoutOfWeb3Modal = async () => {
    try {
      setLogout(true);
      await setExternalProvider(null);
      await setProvider(null);
      if (account.isConnected === true) {
        modal.open({ view: 'Account' });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const switchNetwork = async provider => {
      try {
        await provider.send('wallet_switchEthereumChain', [
          { chainId: `0x${NETWORK_ID.toString(16)}` },
        ]);
      } catch (error) {
        console.error(error);
      }
    };
    if (autoLoad && !autoLoaded && signer.walletProvider !== undefined) {
      loadWeb3Modal().then(async () => {
        if (provider) {
          const { chainId } = await provider.getNetwork();
          if (chainId !== NETWORK_ID) {
            await switchNetwork(provider);
          }
        }
        setAutoLoaded(true);
      });
    }
    if (logout && provider === null) {
      logoutOfWeb3Modal().then(async () => {
        if (account.isConnected === false) {
          window.location.reload();
        }
      });
    }
  }, [autoLoad, autoLoaded, loadWeb3Modal, setAutoLoaded]);

  return [provider, loadWeb3Modal, logoutOfWeb3Modal, '', ''];
}
