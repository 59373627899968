import React from 'react';
import Route from 'components/Route';
import { URL_HELPERS } from 'helpers/url';
import ProvideAuth from 'components/ProvideAuth';
import { Switch, Redirect } from 'react-router-dom';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import {
  Settings,
  Store,
  NotFound,
  NFTWallet,
  ClaimToken,
  LogIn,
  InvalidAccount,
  // Referrals,
  // PKRPass,
  // BuyChips,
  // BuySpades,
  // PaymentFailed,
  // PaymentSuccess,
  // PaymentPending,
} from 'views';

const Routes = () => {
  return (
    <ProvideAuth>
      <Switch>
        <Route
          path={URL_HELPERS.notFound}
          component={NotFound}
          layout={MinimalLayout}
          exact
        />
        <Route
          component={Settings}
          path={URL_HELPERS.settings}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={LogIn}
          layout={MainLayout}
          path={URL_HELPERS.login}
          publicRoute
          exact
        />
        <Route
          component={InvalidAccount}
          layout={MainLayout}
          path={URL_HELPERS.invalidAccount}
          publicRoute
          exact
        />
        {/* <Route
          component={PKRPass}
          path={URL_HELPERS.pkrPass}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={BuySpades}
          path={URL_HELPERS.spades}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={BuyChips}
          path={URL_HELPERS.chips}
          layout={MainLayout}
          protectedRoute
          exact
        /> */}
        {/* <Route
          component={Referrals}
          path={URL_HELPERS.referrals}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={PaymentSuccess}
          path={URL_HELPERS.paymentSuccess}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={PaymentPending}
          path={URL_HELPERS.paymentPending}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Route
          component={PaymentFailed}
          path={URL_HELPERS.paymentFailed}
          layout={MainLayout}
          protectedRoute
          exact
        /> */}
        {/* <Redirect from={URL_HELPERS.home} to={URL_HELPERS.pkrPass} />
        <Redirect from="/" to={URL_HELPERS.pkrPass} /> */}
        <Route component={Store} path={URL_HELPERS.store} exact />
        <Route
          component={ClaimToken}
          path={URL_HELPERS.claimToken}
          layout={MainLayout}
          exact
        />
        <Route
          component={NFTWallet}
          path={URL_HELPERS.nftWallet}
          layout={MainLayout}
          protectedRoute
          exact
        />
        <Redirect from="/" to={URL_HELPERS.claimToken} />
        <Redirect to={URL_HELPERS.notFound} />
      </Switch>
    </ProvideAuth>
  );
};

export default Routes;
