import { useQuery, useQueryClient } from 'react-query';
import { BALANCES_API } from 'apis/balances';

export const BALANCES_CACHE_KEY = 'balances';

export const useBalances = () =>
  useQuery(BALANCES_CACHE_KEY, BALANCES_API.browse);

export const invalidateBalances = async () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();
  await queryClient.invalidateQueries(BALANCES_CACHE_KEY);
};
