import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 16,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 16,
  },
  button: {
    color: theme.palette.secondary.dark,
    padding: '10px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    borderRadius: 2,
    letterSpacing: 0,
    width: '100%',
    height: 69,
    fontWeight: 200,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#e5b839',
      '& $icon': {
        color: '#e5b839',
      },
    },
  },
  buttonLeaf: {
    color: theme.palette.secondary.dark,
    padding: '10px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: 8,
    width: '100%',
    height: 69,
    fontWeight: 200,
    fontSize: 14,
    '&.depth-0': {
      fontSize: 14,
      fontWeight: 200,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#e5b839',
      '& $icon': {
        color: '#e5b839',
      },
    },
  },
  icon: {
    color: theme.palette.gray,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(29, 25, 27, 0.5)',
    color: '#e5b839',
    '& $icon': {
      color: '#e5b839',
    },
  },
  disabled: {
    borderRadius: 8,
    cursor: 'default',
    // backdropFilter: 'blur(6px)',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.dark,

      '& $icon': {
        color: theme.palette.secondary.dark,
      },
    },
    color: 'rgba(227,227,227, 0.26) !important',
  },
  soonRoot: {
    top: -8,
    right: -8,
    position: 'absolute',
  },
  chip: {
    fontWeight: 'bold',
    backgroundColor: 'rgba(252, 28, 143, 1)',
    boxShadow: '0 5px 20px rgba(252, 28, 143, .5)',
  },
}));

export default useStyles;
