import React from 'react';
import useStyles from './styles';
import Page from 'components/Page';
import { Grid, Typography } from '@material-ui/core';

const InvalidAccount = () => {
  const classes = useStyles();

  const renderForm = () => {
    return (
      <div className={classes.root}>
        <div className={classes.logout}>
          <Typography variant="h6">
            Invalid token or session expired, please login again.
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <>
      <Page title="Invalid Account" className={classes.root}>
        <Grid item className={classes.contentRoot}>
          {renderForm()}
        </Grid>
      </Page>
    </>
  );
};

export default InvalidAccount;
