import React, { Fragment } from 'react';
import WalletProvider from 'providers/WalletProvider';
import StakingProvider from 'providers/StakingProvider';
import useNewWeb3Modal from 'hooks/useNewWeb3Modal';

// include providers here
const baseProviders = [];

export const Compose = ({ components, children }) => (
  <Fragment>
    {components.reverse().reduce((acc, curr) => {
      const [Provider, props] = Array.isArray(curr)
        ? [curr[0], curr[1]]
        : [curr, {}];
      return <Provider {...props}>{acc}</Provider>;
    }, children)}
  </Fragment>
);

export const ComposeProvider = ({ children }) => {
  // TODO: WalletConnectv3 Integration
  const [provider, loadWeb3Modal, logoutOfWeb3Modal, isFaceWallet, faceWallet] =
    useNewWeb3Modal();

  const providers = [
    [
      WalletProvider,
      { provider, loadWeb3Modal, logoutOfWeb3Modal, isFaceWallet, faceWallet },
    ],
    [
      StakingProvider,
      { provider, loadWeb3Modal, logoutOfWeb3Modal, isFaceWallet, faceWallet },
    ],
    ...baseProviders,
  ];
  return <Compose components={providers}>{children}</Compose>;
};

export default ComposeProvider;
