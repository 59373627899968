import { get } from 'lodash';
import { requestAPI } from 'services/request';

const buyAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/spades/buy', payload, { ...rest });

const syncAPI = async ({ ...rest }) =>
  requestAPI('POST', '/spades/sync', {}, { ...rest });

const nuveiBuyAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/nuvei/spades/buy', payload, { ...rest });

const buy = async payload => {
  const res = await buyAPI({ payload });
  return get(res, 'data', {});
};

const sync = async () => {
  const res = await syncAPI({});
  return get(res, 'data', {});
};

const nuveiBuy = async payload => {
  const res = await nuveiBuyAPI({ payload });
  return get(res, 'data', {});
};

export const SPADES_API = {
  buy,
  sync,
  nuveiBuy,
};
