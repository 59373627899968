import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Tab, Typography } from '@material-ui/core';
import { BuyChips, BuySpades, Dealers, PKRPass } from 'views';
import Logo from 'views/Stake/assets/logo.svg';
import { LOGIC_HELPERS } from 'helpers/logic';
import Wallet from './components/Wallet';
import useStyles from './styles';

const Shop = props => {
  const { history } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [disabled, setDisabled] = useState(true);

  // eslint-disable-next-line no-undef
  const env = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (env !== 'production') {
      setDisabled(false);
    }
  }, [env]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { index } = props;

    return LOGIC_HELPERS.switchCase(value, {
      // 0: renderBuyPKRx(value, index),
      0: renderPKRPass(value, index),
      1: renderBuyChips(value, index),
      2: renderBuySpades(value, index),
      3: renderDealers(value, index),
    });
  };

  const renderBuyChips = (value, index) => () =>
    (
      <div role="tabpanel" hidden={value !== index}>
        <BuyChips history={history} />
      </div>
    );

  const renderPKRPass = (value, index) => () =>
    (
      <div role="tabpanel" hidden={value !== index}>
        <PKRPass history={history} />
      </div>
    );

  const renderDealers = (value, index) => () =>
    (
      <div role="tabpanel" hidden={value !== index}>
        <Dealers history={history} />
      </div>
    );

  const renderBuySpades = (value, index) => () =>
    (
      <div role="tabpanel" hidden={value !== index}>
        <BuySpades history={history} />
      </div>
    );

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.mainHeader}>
        <img className="text-logo" src={Logo} />
        <Typography variant="h6" className={classes.title}>
          MARKETPLACE
        </Typography>
      </div>
      <Wallet />
    </div>
  );

  const renderContent = () => {
    if (disabled) {
      return (
        <>
          <Tabs
            className={classes.tabRoot}
            value={value}
            onChange={handleChange}
          >
            {/* <Tab value={0} label="PKRx" /> */}
            <Tab value={0} label={<Typography>PKR PASS</Typography>} />
            <Tab value={1} label={<Typography>PLAY CHIPS</Typography>} />
            <Tab value={2} label={<Typography>SPADES</Typography>} />
            <Tab
              value={3}
              disabled
              label={<Typography>My Dealers - Coming Soon</Typography>}
            />
          </Tabs>
          {/* <TabPanel value={value} index={0} /> */}
          <TabPanel value={value} index={0} />
          <TabPanel value={value} index={1} />
          <TabPanel value={value} index={2} />
          {/* <TabPanel value={value} index={3} /> */}
          {renderHeader()}
        </>
      );
    }
    return (
      <>
        <Tabs className={classes.tabRoot} value={value} onChange={handleChange}>
          {/* <Tab value={0} label="PKRx" /> */}
          <Tab value={0} label={<Typography>PKR PASS</Typography>} />
          <Tab value={1} label={<Typography>PLAY CHIPS</Typography>} />
          <Tab value={2} label={<Typography>SPADES</Typography>} />
          <Tab value={3} label={<Typography>My Dealers</Typography>} />
        </Tabs>
        {/* <TabPanel value={value} index={0} /> */}
        <TabPanel value={value} index={0} />
        <TabPanel value={value} index={1} />
        <TabPanel value={value} index={2} />
        <TabPanel value={value} index={3} />
        {renderHeader()}
      </>
    );
  };

  return (
    <>
      <Grid
        md
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        {renderContent()}
      </Grid>
    </>
  );
};

export default Shop;
