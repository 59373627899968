import { USERS_API } from 'apis/user';
import { EPIC_API } from 'apis/epic';
import { setCookie, forceLogout, STORE } from 'store';
import { useGetAndSet } from 'react-context-hook';
import { TOKENS_API } from 'apis/tokens';

const useProvideAuth = () => {
  const [user, setUser] = useGetAndSet(STORE.USER_DETAILS);

  const verifyToken = async payload => {
    const data = await TOKENS_API.verify(payload);
    if (data.is_valid) {
      setCookie('jwtToken', payload.token);
      setUser(data);
    }
  };

  const signIn = async payload => {
    const { access_token, refresh_token } = payload;
    if (access_token && refresh_token) {
      setCookie('jwtToken', access_token);
      setCookie('refreshToken', refresh_token);
      const data = await USERS_API.me({ access_token });
      setUser(data);
    }
  };

  const signOut = async payload => {
    try {
      await EPIC_API.revoke(payload);
    } catch (e) {
      console.log('Invalid token, logging out instead');
    }
    setUser({});
    forceLogout();
  };

  const setTokens = async tokens => {
    const { token, refresh_token } = tokens;
    if (token && refresh_token) {
      setCookie('jwtToken', token);
      setCookie('refreshToken', refresh_token);
      const data = await USERS_API.me({ token });
      setUser(data);
    }
  };

  return {
    user,
    signIn,
    signOut,
    setTokens,
    verifyToken,
    forceLogout,
  };
};

export default useProvideAuth;
