import React from 'react';

const StakingContext = React.createContext({
  wallet: {},
  provider: {},
  loaded: false,
  validNetwork: true,
  faceWallet: {},
  isFaceWallet: false,
  loadWeb3Modal: async () => {},
  logoutOfWeb3Modal: async () => {},
});

export default StakingContext;
