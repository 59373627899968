import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import { URL_HELPERS } from 'helpers/url';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import { EPIC_API } from 'apis/epic';
import useAuth from 'hooks/useAuth';
import Loading from 'components/Loading';
import { useHistory } from 'react-router-dom';
import { SERVER_API } from 'apis/serverSettings';
import { TOKENS_API } from 'apis/tokens';

const useStyles = makeStyles(theme => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    height: '100%',
    fontWeight: 200,
    color: '#fff',
  },
  login: {
    zIndex: 1,
    display: 'flex',
    borderRadius: 8,
    margin: '0 auto',
    padding: '16px 32px',
    flexDirection: 'column',
    backgroundColor: 'rgb(255, 255, 255, 0.15)',
  },
  loginBtn: {
    marginTop: 16,
    marginBottom: 24,
  },
  banner: {
    fontSize: 17,
    display: 'flex',
    borderRadius: 4,
    fontWeight: 'bold',
    padding: '12px 16px',
    alignItems: 'center',
    backgroundColor: '#f31f90',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
    },
  },
  bannerContent: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  infoIcon: {
    width: 24,
    marginRight: 8,
  },
}));

const LogIn = () => {
  const history = useHistory();
  const classes = useStyles();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const server = await SERVER_API.getServerStatus();
    setIsMaintenance(server[0].is_maintenance);

    const token = urlParams.get('token');
    if (token) {
      try {
        setLoading(true);
        try {
          await auth.verifyToken({ token });
        } catch (error) {
          await auth.forceLogout();
          history.push(URL_HELPERS.invalidAccount);
        }
      } catch (e) {
        console.log(e);
        history.push(URL_HELPERS.invalidAccount);
      }
      setLoading(false);
    }

    // const code = urlParams.get('code');
    // if (code) {
    //   try {
    //     setLoading(true);
    //     const payload = {
    //       code: code,
    //       app: 'store',
    //     };
    //     const { res } = await EPIC_API.request(payload);
    //     setLoading(false);
    //     try {
    //       await auth.signIn(res);
    //     } catch (error) {
    //       const payload = {
    //         refresh_token: res.refresh_token,
    //         app: 'store',
    //       };
    //       await auth.signOut(payload);
    //       history.push(URL_HELPERS.invalidAccount);
    //     }
    //   } catch (e) {
    //     setLoading(false);
    //     console.log(e);
    //     history.push(URL_HELPERS.invalidAccount);
    //   }
    // }
  }, [isMaintenance]);

  const renderMaintenanceBanner = () => {
    if (isMaintenance === false) {
      return null;
    }

    return (
      <div className={classes.banner}>
        <div className={classes.bannerContent}>
          <img
            alt="pass-button"
            src="/images/info-icon.png"
            className={classes.infoIcon}
          />
          <Typography>
            <b>SERVER IS CURRENTLY ON MAINTENANCE</b>
          </Typography>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    const onRedirect = () => {
      window.location.replace(URL_HELPERS.redirect);
    };

    if (loading) {
      return (
        <div className={classes.note}>
          <Button className={classes.syncButton}>
            <Loading className={classes.loadingIcon} iconOnly />
            Logging in...
          </Button>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.login}>
          <Typography variant="h6">Welcome to Store Portal!</Typography>
          {/* <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={onRedirect}
            className={classes.loginBtn}
            disabled={isMaintenance}
          >
            Login using Epic Games
          </Button> */}
        </div>
      </div>
    );
  };

  return (
    <>
      <Page title="Log In" className={classes.root}>
        <Grid item className={classes.contentRoot}>
          {renderMaintenanceBanner()}
          {renderForm()}
        </Grid>
      </Page>
    </>
  );
};

LogIn.propTypes = {
  history: PropTypes.object,
};

export default LogIn;
