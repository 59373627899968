import { get } from 'lodash';
import { requestAPI } from 'services/request';

const serverSettingsAPI = async ({ payload }) => {
  return requestAPI('GET', '/server/status', {}, { auth: false });
};

const getServerStatus = async payload => {
  const res = await serverSettingsAPI({ payload });
  return get(res, 'data', {});
};

export const SERVER_API = {
  getServerStatus,
};
