import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: 4,
  },
  text: {
    color: theme.palette.white,
    marginLeft: 12,
  },
  circle: {
    marginTop: 7,
  },
}));
