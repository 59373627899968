import React from 'react';
import Page from 'components/Page';
import { URL_HELPERS } from 'helpers/url';
import { LOGIC_HELPERS } from 'helpers/logic';
import { Grid, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const PaymentSuccess = props => {
  const { history, location } = props;
  const classes = useStyles();

  const params = LOGIC_HELPERS.getQueryParams(location.search);

  const renderForm = () => {
    const onRedirect = () => {
      params.productId.includes('SPADES-BUNDLE')
        ? history.push(URL_HELPERS.spades)
        : history.push(URL_HELPERS.shop);
    };

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h4">Payment Success!</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Please wait for a few minutes for your purchase to reflect
          </Typography>
          <div className={classes.items}>
            <div className={classes.item}>
              <Typography className={classes.label}>ITEM</Typography>
              <Typography>{params.productId}</Typography>
            </div>
            <div className={classes.item}>
              <Typography className={classes.label}>AMOUNT</Typography>
              <Typography>$ {params.totalAmount}</Typography>
            </div>
            <div className={classes.item}>
              <Typography className={classes.label}>TRANSACTION ID</Typography>
              <Typography>{params.TransactionID}</Typography>
            </div>
            <div className={classes.item}>
              <Typography className={classes.label}>INVOICE ID</Typography>
              <Typography>{params.invoice_id}</Typography>
            </div>
          </div>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={onRedirect}
            className={classes.proceedBtn}
          >
            CONTINUE
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Page title="Payment Success" className={classes.root}>
        <Grid item className={classes.contentRoot}>
          {renderForm()}
        </Grid>
      </Page>
    </>
  );
};

export default PaymentSuccess;
