import { get } from 'lodash';
import { getRefreshToken, STORE } from 'store';
import React, { useContext, useEffect } from 'react';
import { useWallets } from 'hooks/wallets';
import { Grid, Button } from '@material-ui/core';
import StakingContext from 'contexts/StakingContext';
import ConnectWalletButton from 'components/ConnectWallet';
import EthereumWallet from './components/EthereumWallet';
import { useStoreValue } from 'react-context-hook';
import { LOGIC_HELPERS } from 'helpers/logic';
import useAuth from 'hooks/useAuth';
import useStyles from './styles';

const Wallet = props => {
  const { location, history } = props;
  const classes = useStyles();
  const stakingNetwork = useStoreValue(STORE.STAKING_NETWORK);
  const walletNetworkId = LOGIC_HELPERS.ifElse(
    stakingNetwork === 'ethereum',
    1,
    2,
  );
  const { data } = useWallets(walletNetworkId);
  const { provider, wallet, loaded, validNetwork } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const auth = useAuth();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const redirect = params.get('redirect');
      if (redirect) {
        history.replace(redirect);
      }
    }
  }, []);

  const onLogout = async () => {
    const refresh_token = getRefreshToken();
    const payload = { refresh_token, app: 'store' };
    await auth.signOut(payload);
  };

  const renderWallets = () => {
    if (!data) {
      return null;
    }
    return data.map((item, key) => (
      <EthereumWallet
        key={key}
        wallet={item}
        loaded={loaded}
        provider={provider}
        networkId={networkId}
        activeAddress={address}
        validNetwork={validNetwork}
      />
    ));
  };
  const renderLogout = () => (
    <div className={classes.note}>
      <Button
        onClick={onLogout}
        variant="text"
        className={classes.logoutButton}
      >
        Logout
      </Button>
    </div>
  );

  return (
    <div className={classes.root}>
      {renderLogout()}
      <Grid container spacing={2} justifyContent="center">
        {renderWallets()}
        <Grid className={classes.item}>
          <ConnectWalletButton />
        </Grid>
      </Grid>
    </div>
  );
};

export default Wallet;
