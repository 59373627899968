import {
  BSC_MAINNET_ID,
  BSC_TESTNET_ID,
  ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
  POLYGON_MAINNET,
  POLYGON_TESTNET,
  // BSC_MAINNET_ID,
  // BSC_TESTNET_ID,
} from 'constants/networkConstants';
import { ABI } from 'contracts/artifacts';

const defaultContract = {
  serverAddress: '',
  pkr: {
    abi: [],
    address: '',
  },
  stakePKR: {
    abi: [],
    address: '',
  },
  countdownUrl: 'https://ropsten.etherscan.io/block/countdown',
};

export const SMART_CONTRACTS = {
  [ETHEREUM_MAINNET_ID]: {
    // eslint-disable-next-line no-undef
    serverAddress: process.env.REACT_APP_ETH_SERVER_WALLET_ADDRESS,
    pkr: {
      abi: ABI.PKRTokenERC20,
      address: '0x001a8ffcb0f03e99141652ebcdecdb0384e3bd6c',
    },
    stakePKR: {
      abi: ABI.PKRStaking,
      // eslint-disable-next-line no-undef
      address: process.env.REACT_APP_ETH_STAKING_SMART_CONTRACT,
    },
    countdownUrl: 'https://www.etherscan.io/block/countdown',
  },
  [ETHEREUM_TESTNET_ID]: {
    // eslint-disable-next-line no-undef
    serverAddress: process.env.REACT_APP_ETH_SERVER_WALLET_ADDRESS,
    pkr: {
      abi: ABI.PKRTokenERC20,
      address: '0x453d8822fa6ddc7fbffe081633fd2f1c647476d5',
    },
    stakePKR: {
      abi: ABI.PKRStaking,
      // eslint-disable-next-line no-undef
      address: process.env.REACT_APP_ETH_STAKING_SMART_CONTRACT,
    },
    countdownUrl: 'https://ropsten.etherscan.io/block/countdown',
  },
  [BSC_MAINNET_ID]: {
    // eslint-disable-next-line no-undef
    serverAddress: process.env.REACT_APP_BSC_SERVER_WALLET_ADDRESS,
    pkr: {
      abi: ABI.PKRTokenBEP20,
      address: '0xc49dde62b4a0810074721faca54aab52369f486a',
    },
    stakePKR: {
      abi: ABI.PKRStaking,
      // eslint-disable-next-line no-undef
      address: process.env.REACT_APP_BSC_STAKING_SMART_CONTRACT,
    },
    countdownUrl: 'https://bscscan.com/block/countdown',
  },
  [BSC_TESTNET_ID]: {
    // eslint-disable-next-line no-undef
    serverAddress: process.env.REACT_APP_BSC_SERVER_WALLET_ADDRESS,
    pkr: {
      abi: ABI.PKRTokenBEP20,
      address: '0x18c517675a52ca81d543cfd19231201cea51093d',
    },
    stakePKR: {
      abi: ABI.PKRStaking,
      // eslint-disable-next-line no-undef
      address: process.env.REACT_APP_BSC_STAKING_SMART_CONTRACT,
    },
    countdownUrl: 'https://testnet.bscscan.com/block/countdown',
  },
  [POLYGON_TESTNET]: {
    // eslint-disable-next-line no-undef
    serverAddress: process.env.REACT_APP_POLYGON_ACCOUNT_ADDRESS,
    dealerNFT: {
      abi: ABI.PKRDealerNFT,
      // eslint-disable-next-line no-undef
      address: process.env.REACT_APP_POLYGON_DEALERS_SMART_CONTRACT_ADDRESS,
    },
    claimToken: {
      abi: ABI.PKRClaimToken,
      addresses: [
        '0x35D9AdA034Eb3a97e2892e1B1BD166AB1f6a2CcF',
        '0x7A4780049CE22317dbe7c9874641170Bb56E27b2',
      ],
    },
  },
  [POLYGON_MAINNET]: {
    // eslint-disable-next-line no-undef
    serverAddress: process.env.REACT_APP_POLYGON_ACCOUNT_ADDRESS,
    dealerNFT: {
      abi: ABI.PKRDealerNFT,
      // eslint-disable-next-line no-undef
      address: process.env.REACT_APP_POLYGON_DEALERS_SMART_CONTRACT_ADDRESS,
    },
    claimToken: {
      abi: ABI.PKRClaimToken,
      // eslint-disable-next-line no-undef
      addresses: [
        '0xcc9Ffc3Aa47788E6BCFCd66B11AFaa07EA030ccF',
        '0x4Cfe7DDf9a0958E61bE3c59Aa8A540FEAf1e8566',
        '0x35Ca3B689161b6A42b5246b295F13e1E5a5ca4cf',
      ],
    },
  },
};
