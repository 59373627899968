import { STORE } from 'store';
import useStyles from './styles';
import { P2E_API } from 'apis/P2E/p2e';
import Loading from 'components/Loading';
import { useWallets } from 'hooks/wallets';
import { find, get, isEmpty } from 'lodash';
import { LOGIC_HELPERS } from 'helpers/logic';
import { useDealersList } from 'hooks/dealers';
import StakingContext from 'contexts/StakingContext';
import { STRING_HELPERS } from 'helpers/stringAdditions';
import { SYNC_API } from 'apis/Transactions/sync';
import { DEALERS_API } from 'apis/Dealers/dealer';
import { Grid, Button, Typography, Input, Modal } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSetStoreValue } from 'react-context-hook';
import {
  BSC_MAINNET_ID,
  BSC_TESTNET_ID,
  ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
  POLYGON_MAINNET,
  POLYGON_TESTNET,
} from 'constants/networkConstants';
import { ETHERS_SERVICE } from 'services/ethers';

const Dealers = () => {
  const classes = useStyles();
  const [hash, setHash] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [withdrawItem, setWithdrawItem] = useState(false);
  const [syncDisabled, setSyncDisabled] = useState(true);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const { provider, wallet, validNetwork } = useContext(StakingContext);
  const address = get(wallet, 'wallet', '').toLowerCase();
  const networkId = get(wallet, 'networkId', 0);
  const walletNetworkId = LOGIC_HELPERS.switchCase(networkId, {
    [ETHEREUM_MAINNET_ID]: 1,
    [ETHEREUM_TESTNET_ID]: 1,
    [BSC_MAINNET_ID]: 2,
    [BSC_TESTNET_ID]: 2,
    [POLYGON_MAINNET]: 4,
    [POLYGON_TESTNET]: 4,
    default: 0,
  });
  const { data: wallets } = useWallets(walletNetworkId);
  const { data: dealersList, refetch: refetchDealerList } = useDealersList();
  const displayAddress = STRING_HELPERS.renderWalletAddress(address);

  useEffect(() => {
    const onInitialSync = async () => {
      const res = await SYNC_API.browsePending();
      const isDisabled = !res?.sync_required_for_dealer;
      setSyncDisabled(isDisabled);
    };

    onInitialSync();
  }, []);

  useEffect(() => {
    const initialized = LOGIC_HELPERS.ifElse(
      [provider, validNetwork, wallets?.length],
      true,
      false,
    );
    if (initialized) {
      const connected = find(wallets, { address });
      const status = !isEmpty(connected);
      setEnabled(status);
      setRefetch(false);
    }
  }, [provider, validNetwork, address, wallets, refetch]);

  const onShowDisplaymodal = item => {
    setDisplayModal(true);
    setWithdrawItem(item);
  };

  const onCloseModal = () => {
    setDisplayModal(false);
    setWithdrawItem('');
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setHash(null);
      const payload = {
        uuid: withdrawItem.uuid,
        address: address,
      };
      const res = await P2E_API.withdrawNft(payload);
      setHash(res.transaction_hash);
      await refetchDealerList();
      setLoading(false);
      setSnackbar({
        variant: 'success',
        message: 'Success withdrawing NFT!',
      });
    } catch (e) {
      setSnackbar({
        variant: 'error',
        message: 'Failed withdrawing NFT!',
      });
      setLoading(false);
    }
  };

  const enableSync = async () => {
    const res = await SYNC_API.browsePending();
    const isDisabled = !res?.sync_required_for_dealer;
    setSyncDisabled(isDisabled);

    if (isDisabled) {
      setHash(null);
    }
  };

  const onSync = async () => {
    try {
      setLoading(true);
      setRefetch(true);
      await DEALERS_API.sync();
      await refetchDealerList();
      await enableSync();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeText = e => {
    e.preventDefault();
    setTokenId(e.target.value);
  };

  const onDeposit = async () => {
    setLoading(true);
    if (!tokenId) {
      setSnackbar({
        variant: 'error',
        message: 'Enter valid token ID!',
      });
      setLoading(false);
    } else {
      try {
        const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
        const tx = await ethersHelper.methods.depositDealerNFT(tokenId);
        const payload = { txHash: tx.hash };
        setHash(tx.hash);
        await P2E_API.depositNft(payload);
        await enableSync();
        setTokenId('');
        setLoading(false);
        setSnackbar({
          variant: 'success',
          message: 'Success deposit dealer!',
        });
      } catch (e) {
        setSnackbar({
          variant: 'error',
          message: 'Failed to deposit dealer!',
        });
        setLoading(false);
      }
    }
  };

  const renderDisabled = () => (
    <div className={classes.center}>
      <Typography variant="h6" color="textPrimary" className={classes.header}>
        WALLET NOT CONNECTED
      </Typography>
      <Typography variant="subtitle1" color="textPrimary">
        Please connect your wallet first.
      </Typography>
    </div>
  );

  const renderSync = () => {
    if (syncDisabled) {
      return null;
    }

    if (loading) {
      return (
        <div className={classes.note}>
          <Button className={classes.syncButton}>
            <Loading className={classes.loadingIcon} iconOnly />
            Syncing
          </Button>
        </div>
      );
    }
    return (
      <div className={classes.note}>
        <Button className={classes.syncButton} onClick={onSync}>
          SYNC
        </Button>
      </div>
    );
  };

  const renderHash = () => {
    if (!hash || syncDisabled) {
      return null;
    }

    if (networkId === POLYGON_TESTNET) {
      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://mumbai.polygonscan.com/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }

    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://polygonscan.com/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderWallet = () => (
    <Grid direction="column" container>
      <Typography color="textPrimary" className={classes.displayWallet}>
        <b>Connected wallet:</b> {displayAddress}
      </Typography>
    </Grid>
  );

  const renderBalance = () => {
    return (
      <Grid direction="row" xs={12} container className={classes.walletRoot}>
        <div className={classes.walletContainer}>{renderWallet()}</div>
        <div className={classes.column}>
          {renderSync()}
          <div className={classes.balanceInfoRoot}>{renderHash()}</div>
        </div>
      </Grid>
    );
  };

  const renderConfirmWithdraw = () => {
    if (!displayModal) {
      return null;
    }

    return (
      <div className={classes.center}>
        <Modal open={displayModal} onClose={onCloseModal}>
          <div className={classes.modalContainer}>
            <Typography color="textPrimary">
              Are you sure you want to withdraw?
            </Typography>
            <div className={classes.buttonContainer}>
              <Button className={classes.cancelButton} onClick={onCloseModal}>
                Cancel
              </Button>
              <Button className={classes.syncButton} onClick={onSubmit}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const renderDeposit = () => {
    if (loading) {
      return (
        <div className={classes.tokenField}>
          <Loading loadingText="Please wait..." />
        </div>
      );
    }
    return (
      <div className={classes.tokenField}>
        <Input
          size="small"
          type="number"
          label="Token ID"
          value={tokenId}
          variant="outlined"
          placeholder="Token ID"
          onChange={onChangeText}
          inputProps={{ className: classes.inputStyle }}
          disableUnderline
        />
        <Button
          size="small"
          className={classes.depositButton}
          onClick={onDeposit}
          loading={loading}
        >
          Deposit Dealer
        </Button>
      </div>
    );
  };

  const renderDealers = () => {
    if (dealersList === undefined) return null;

    return (
      <Grid xs={12} item className={classes.dealerRoot}>
        <div className={classes.dealerContent}>
          <div className={classes.rowHeader}>
            <Typography className={classes.dealHeader}>MY DEALERS</Typography>
            {renderDeposit()}
          </div>
          <Grid
            spacing={4}
            alignItems="center"
            direction="column"
            container
            className={classes.dealerContainer}
          >
            {renderConfirmWithdraw()}
            {dealersList.dealers.map((item, index) => {
              return (
                <div className={classes.radioContainer}>
                  <input
                    id={index}
                    type="radio"
                    name="priceId"
                    value={item.value}
                    className={classes.radioButton}
                    // onClick={() => onSelectDealer(index)}
                    autoComplete="off"
                  />
                  <div className={classes.dealerCard}>
                    <div className={classes.withdrawPrice}>
                      <div className={classes.imageRoot}>
                        <LazyLoadImage
                          width={320}
                          height={460}
                          effect="blur"
                          visibleByDefault
                          className={classes.image}
                          src={item.image}
                        />
                        <div className={classes.buttonRoot}>
                          {loading && !item.has_withdrawn ? (
                            <Loading loadingText="Please wait..." />
                          ) : (
                            <Button
                              onClick={() => onShowDisplaymodal(item)}
                              className={classes.withdrawDealer}
                              disabled={item.has_withdrawn}
                            >
                              Withdraw
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  const renderContent = () => {
    if (!enabled || !validNetwork) {
      return renderDisabled();
    }

    return (
      <>
        {renderBalance()}
        {renderDealers()}
      </>
    );
  };

  return (
    <Grid
      md
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      {renderContent()}
    </Grid>
  );
};

export default Dealers;
