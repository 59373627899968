import SecureLS from 'secure-ls';
import Cookie from 'universal-cookie';
import { store } from 'react-context-hook';

const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: true,
});

const STORE_KEY = 'gameLocalStorage';

export const STORE = {
  WALLET: 'wallet',
  SNACKBAR: 'snackbar',
  USER_DETAILS: 'userDetails',
  VALID_NETWORK: 'validNetwork',
  STAKING_WALLET: 'stakingWallet',
  STAKING_NETWORK: 'stakingNetwork',
  VALID_STAKING_NETWORK: 'validStakingNetwork',
};

export const initialState = {
  [STORE.WALLET]: {},
  [STORE.SNACKBAR]: {},
  [STORE.USER_DETAILS]: {},
  [STORE.STAKING_WALLET]: {},
  [STORE.VALID_NETWORK]: true,
  [STORE.STAKING_NETWORK]: 'bsc',
  [STORE.VALID_STAKING_NETWORK]: true,
};

export const resetStore = () => {
  store.reset({ ...initialState });
};

export const forceLogout = () => {
  const cookies = new Cookie();

  cookies.remove('jwtToken');
  cookies.remove('refreshToken');
  resetStore();
};

export const setCookie = (key, value, expiry = true) => {
  const cookies = new Cookie();

  let expireProps = {};

  if (expiry) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 3 * 60 * 60 * 1000 * 100);
    expireProps = {
      expires,
    };
  }
  // eslint-disable-next-line no-undef
  const secure = process.env.NODE_ENV === 'production';

  const options = {
    path: '/',
    secure,
    sameSite: 'strict',
    ...expireProps,
  };

  cookies.set(key, value, options);
};

export const getCookie = key => {
  const cookies = new Cookie();
  return cookies.get(key);
};

export const getToken = () => getCookie('jwtToken');

export const getRefreshToken = () => getCookie('refreshToken');

export const getPKRPassPromoCookie = () => getCookie('pkr_pass_promo');

export const getAuthTokens = () => {
  const token = getToken();
  const refreshToken = getRefreshToken();

  return token && refreshToken;
};

export const getInitialState = () => {
  const state = ls.get(STORE_KEY);

  if (!state) {
    return initialState;
  }

  let savedState = JSON.parse(state);

  return { ...initialState, ...savedState };
};

export const storeConfig = {
  listener: state => {
    ls.set(STORE_KEY, JSON.stringify(state));
  },
  proxyStore: true,
};
