/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { URL_HELPERS } from 'helpers/url';
import { LOGIC_HELPERS } from 'helpers/logic';
import { Route, Redirect } from 'react-router-dom';

const RouteComponent = props => {
  const {
    component: Component,
    layout: Layout,
    protectedRoute,
    layoutProps,
    publicRoute,
    hasLogout,
    noFooter,
    navbar,
    ...rest
  } = props;

  const auth = useAuth();

  const renderComponent = props => {
    if (!Layout) {
      return <Component {...props} />;
    }

    return (
      <Layout
        noFooter={noFooter}
        hasLogout={hasLogout}
        navbar={navbar}
        {...layoutProps}
        {...props}
      >
        <Component {...props} />
      </Layout>
    );
  };

  const renderRoute = props => {
    // if protectedRoute and no user details
    const isRedirectLogin = LOGIC_HELPERS.ifElse(
      [protectedRoute, !auth?.user?.is_valid],
      true,
      false,
    );
    // if publicRoute and has user details
    const isRedirectHome = LOGIC_HELPERS.ifElse(
      [publicRoute, auth?.user?.is_valid],
      true,
      false,
    );

    if (isRedirectLogin) {
      const redirect = encodeURIComponent(location.pathname);
      const search = location.search ? location.search : '';
      const params = redirect ? `${search}&redirect=${redirect}` : search;
      return <Redirect to={`${URL_HELPERS.login}${params}`} />;
    }

    if (isRedirectHome) {
      const params = new URLSearchParams(location.search);
      const redirect = params.get('redirect');
      return <Redirect to={`${URL_HELPERS.home}?redirect=${redirect}`} />;
    }

    return renderComponent(props);
  };

  return <Route {...rest} render={renderRoute} />;
};

RouteComponent.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
  noFooter: PropTypes.bool,
  navbar: PropTypes.string,
  hasLogout: PropTypes.bool,
  publicRoute: PropTypes.bool,
  protectedRoute: PropTypes.bool,
};

export default RouteComponent;
