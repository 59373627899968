import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Grid, Button, Typography, useMediaQuery } from '@material-ui/core';
import StakingContext from 'contexts/StakingContext';
import { find, get, isEmpty } from 'lodash';
import { useWallets } from 'hooks/wallets';
import { useBalances } from 'hooks/balances';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSetStoreValue } from 'react-context-hook';
import { usePasses } from 'hooks/passes';
import { ETHERS_SERVICE } from 'services/ethers';
import { LOGIC_HELPERS } from 'helpers/logic';
import Loading from 'components/Loading';
import { useTheme } from '@material-ui/styles';
import { usePKRConversions } from 'hooks/assets';
import { MOMENT } from 'helpers/moment';
import { SPADES_API } from 'apis/spades';
import { SYNC_API } from 'apis/Transactions/sync';
import { STORE } from 'store';
import useStyles from './styles';
import {
  BSC_MAINNET_ID,
  BSC_TESTNET_ID,
  ETHEREUM_MAINNET_ID,
  ETHEREUM_TESTNET_ID,
  POLYGON_MAINNET,
  POLYGON_TESTNET,
} from 'constants/networkConstants';
import Wallet from 'views/Shop/components/Wallet';

const BuySpades = () => {
  // eslint-disable-next-line no-undef
  const nodeEnv = process.env.REACT_APP_ENV;
  const classes = useStyles();
  const [hash, setHash] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState([]);
  const setSnackbar = useSetStoreValue(STORE.SNACKBAR);
  const { provider, wallet, validNetwork } = useContext(StakingContext);
  const { data: userHasPass, refetch: refretchUserHasPass } = usePasses();
  const networkId = get(wallet, 'networkId', 0);
  const walletNetworkId = LOGIC_HELPERS.switchCase(networkId, {
    [ETHEREUM_MAINNET_ID]: 1,
    [ETHEREUM_TESTNET_ID]: 1,
    [BSC_MAINNET_ID]: 2,
    [BSC_TESTNET_ID]: 2,
    [POLYGON_MAINNET]: 4,
    [POLYGON_TESTNET]: 4,
    default: 0,
  });
  const { data: wallets } = useWallets(walletNetworkId);
  const { data: balances, refetch: refetchBalances } = useBalances();
  const { data: pkrConversion, refetch: refetchPkrConversion } =
    usePKRConversions({ refetchInterval: 30000 });
  const theme = useTheme();

  const slippage = 0.025;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const address = get(wallet, 'wallet', '').toLowerCase();
  const spadesBalance = get(balances, '2.amount', '0.0');
  const standardRate = get(pkrConversion, 'conversion_rate', 0);
  const rate = standardRate - standardRate * slippage;
  const [syncDisabled, setSyncDisabled] = useState(true);
  const [hasPromo, setHasPromo] = useState();

  useEffect(() => {
    const initialized = LOGIC_HELPERS.ifElse(
      [provider, validNetwork, wallets?.length],
      true,
      false,
    );
    if (initialized) {
      const connected = find(wallets, { address });
      const status = !isEmpty(connected);

      setEnabled(status);
      setRefetch(false);
    }
  }, [provider, validNetwork, address, hash, wallets, refetch]);

  useEffect(() => {
    const setDealValues = async () => {
      if (userHasPass?.length > 0) {
        const ownedPass = get(userHasPass, '0.pkrPasses.name', '');
        if (ownedPass === 'Bronze') {
          setDeals([
            {
              id: 1,
              value: 4.99,
              spades: '515',
              disabled: true,
            },
            {
              id: 2,
              value: 19.99,
              spades: '2,060',
              disabled: true,
            },
            {
              id: 3,
              value: 49.99,
              spades: '5,150',
              disabled: true,
            },
            {
              id: 4,
              value: 99.99,
              spades: '10,300',
              disabled: true,
            },
            {
              id: 5,
              value: 249.99,
              spades: '25,700',
              disabled: true,
            },
            {
              id: 6,
              value: 499.99,
              spades: '51,500',
              disabled: true,
            },
            {
              id: 7,
              value: 999.99,
              spades: '103,000',
              disabled: true,
            },
          ]);
        } else if (ownedPass === 'Silver') {
          setDeals([
            {
              id: 1,
              value: 4.99,
              spades: '525',
              disabled: true,
            },
            {
              id: 2,
              value: 19.99,
              spades: '2,100',
              disabled: true,
            },
            {
              id: 3,
              value: 49.99,
              spades: '5,250',
              disabled: true,
            },
            {
              id: 4,
              value: 99.99,
              spades: '10,500',
              disabled: true,
            },
            {
              id: 5,
              value: 249.99,
              spades: '26,250',
              disabled: true,
            },
            {
              id: 6,
              value: 499.99,
              spades: '52,500',
              disabled: true,
            },
            {
              id: 7,
              value: 999.99,
              spades: '105,000',
              disabled: true,
            },
          ]);
        } else if (ownedPass === 'Gold') {
          setDeals([
            {
              id: 1,
              value: 4.99,
              spades: '600',
              disabled: true,
            },
            {
              id: 2,
              value: 19.99,
              spades: '2,400',
              disabled: true,
            },
            {
              id: 3,
              value: 49.99,
              spades: '6,000',
              disabled: true,
            },
            {
              id: 4,
              value: 99.99,
              spades: '12,000',
              disabled: true,
            },
            {
              id: 5,
              value: 249.99,
              spades: '30,000',
              disabled: true,
            },
            {
              id: 6,
              value: 499.99,
              spades: '60,000',
              disabled: true,
            },
            {
              id: 7,
              value: 999.99,
              spades: '120,000',
              disabled: true,
            },
          ]);
        } else if (ownedPass === 'Platinum') {
          setDeals([
            {
              id: 1,
              value: 4.99,
              spades: '650',
              disabled: true,
            },
            {
              id: 2,
              value: 19.99,
              spades: '2,600',
              disabled: true,
            },
            {
              id: 3,
              value: 49.99,
              spades: '6,500',
              disabled: true,
            },
            {
              id: 4,
              value: 99.99,
              spades: '13,000',
              disabled: true,
            },
            {
              id: 5,
              value: 249.99,
              spades: '32,500',
              disabled: true,
            },
            {
              id: 6,
              value: 499.99,
              spades: '65,000',
              disabled: true,
            },
            {
              id: 7,
              value: 999.99,
              spades: '130,000',
              disabled: true,
            },
          ]);
        } else if (ownedPass === 'Diamond') {
          setDeals([
            {
              id: 1,
              value: 4.99,
              spades: '750',
              disabled: true,
            },
            {
              id: 2,
              value: 19.99,
              spades: '3,000',
              disabled: true,
            },
            {
              id: 3,
              value: 49.99,
              spades: '7,500',
              disabled: true,
            },
            {
              id: 4,
              value: 99.99,
              spades: '15,000',
              disabled: true,
            },
            {
              id: 5,
              value: 249.99,
              spades: '37,500',
              disabled: true,
            },
            {
              id: 6,
              value: 499.99,
              spades: '75,000',
              disabled: true,
            },
            {
              id: 7,
              value: 999.99,
              spades: '150,000',
              disabled: true,
            },
          ]);
        }
      } else {
        setDeals([
          {
            id: 1,
            value: 4.99,
            spades: '500',
            disabled: true,
          },
          {
            id: 2,
            value: 19.99,
            spades: '2,000',
            disabled: true,
          },
          {
            id: 3,
            value: 49.99,
            spades: '5,000',
            disabled: true,
          },
          {
            id: 4,
            value: 99.99,
            spades: '10,000',
            disabled: true,
          },
          {
            id: 5,
            value: 249.99,
            spades: '25,000',
            disabled: true,
          },
          {
            id: 6,
            value: 499.99,
            spades: '50,000',
            disabled: true,
          },
          {
            id: 7,
            value: 999.99,
            spades: '100,000',
            disabled: true,
          },
        ]);
      }
    };
    setDealValues();
  }, [rate, userHasPass]);

  useEffect(() => {
    const onInitialSync = async () => {
      const res = await SYNC_API.browsePending();
      const isDisabled = !res?.sync_required_for_spades;
      setSyncDisabled(isDisabled);
    };

    onInitialSync();
  }, []);

  const isDisabledButton = () => {
    const today = MOMENT.dateNowUtc();
    const endDate = MOMENT.dateUtcInTimestamp(1647270000000);
    const diffTime = MOMENT.diffInUnit(today, endDate, 's');

    return diffTime > 0;
  };

  const enableSync = async () => {
    const res = await SYNC_API.browsePending();
    const isDisabled = !res?.sync_required_for_spades;
    setSyncDisabled(isDisabled);

    if (isDisabled) {
      setHash(null);
    }
  };

  const onSync = async () => {
    try {
      setLoading(true);
      setRefetch(true);
      await SPADES_API.sync();
      await refetchBalances();
      await enableSync();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onSelectBundle = i => {
    const updatedDeals = deals.map((item, index) =>
      LOGIC_HELPERS.ifElse(
        index === i,
        { ...item, disabled: false },
        { ...item, disabled: true },
      ),
    );
    setDeals(updatedDeals);
  };

  const onSubmit = item => async () => {
    try {
      setLoading(true);
      setHash(null);
      const res = await refetchPkrConversion();
      const marketRate = get(res, 'data.conversion_rate', rate);
      const adjustedRate = marketRate - marketRate * slippage;
      const amount = parseFloat(item.value / adjustedRate);
      const [, , ethersHelper] = ETHERS_SERVICE(provider, address, networkId);
      const tx = await ethersHelper.methods.depositPKR(amount.toString());
      const payload = {
        txHash: tx.hash,
        bundle_id: item.id,
        network_type_id: walletNetworkId,
      };
      await SPADES_API.buy(payload);
      setHash(tx.hash);

      setSnackbar({
        variant: 'success',
        message: 'Success buying spades!',
      });
      await enableSync();
      setLoading(false);
    } catch (e) {
      console.log(e);
      if (e.code === 4001) {
        setSnackbar({
          variant: 'error',
          message: 'User has cancelled/rejected the transaction.',
        });
      } else {
        setSnackbar({
          variant: 'error',
          message: 'Failed buying spades!',
        });
      }
      setLoading(false);
    }
  };

  const buyViaNuvei = item => async () => {
    setLoading(true);
    const data = { bundle_id: item.id };

    try {
      const res = await SPADES_API.nuveiBuy(data);
      window.location.replace(res.url);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const renderSync = () => {
    if (syncDisabled) {
      return null;
    }

    if (loading) {
      return (
        <div className={classes.note}>
          <Button className={classes.syncButton}>
            <Loading className={classes.loadingIcon} iconOnly />
            Syncing
          </Button>
        </div>
      );
    }
    return (
      <div className={classes.note}>
        <Button className={classes.syncButton} onClick={onSync}>
          SYNC
        </Button>
      </div>
    );
  };

  const renderHash = () => {
    if (!hash) {
      return null;
    }

    if (walletNetworkId === 1) {
      if (nodeEnv === 'production') {
        return (
          <Typography className={classes.hash} color="textPrimary">
            You can check if the transaction is done{' '}
            <a
              href={`https://etherscan.io/tx/${hash}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            .
          </Typography>
        );
      }

      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://goerli.etherscan.io/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }

    if (nodeEnv === 'production') {
      return (
        <Typography className={classes.hash} color="textPrimary">
          You can check if the transaction is done{' '}
          <a
            href={`https://bscscan.com/tx/${hash}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Typography>
      );
    }
    return (
      <Typography className={classes.hash} color="textPrimary">
        You can check if the transaction is done{' '}
        <a
          href={`https://testnet.bscscan.com/tx/${hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        .
      </Typography>
    );
  };

  const renderWallet = () => {
    const convertToInt = parseInt(spadesBalance);
    const connectedWallet = LOGIC_HELPERS.ifElse(
      address,
      address,
      'WALLET NOT CONNECTED',
    );
    const invalidNetwork = LOGIC_HELPERS.ifElse(
      !validNetwork,
      <b>(Invalid Network)</b>,
      '',
    );

    return (
      <Grid direction="column" container>
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Connected wallet:</b> {connectedWallet} {invalidNetwork}
        </Typography>
        <Typography color="textPrimary" className={classes.displayWallet}>
          <b>Balance:</b> {convertToInt} SPADES
        </Typography>
      </Grid>
    );
  };

  const renderBalance = () => (
    <Grid
      direction="row"
      xs={12}
      container
      className={clsx(classes.walletRoot, {
        [classes.hasPromoClosed]: hasPromo === 'closed',
      })}
    >
      <div className={classes.walletContainer}>{renderWallet()}</div>
      <div className={classes.column}>
        {renderSync()}
        <div className={classes.balanceInfoRoot}>{renderHash()}</div>
      </div>
    </Grid>
  );

  const renderBuyButtons = item => {
    let buyUsingPKR = (
      <Grid item xs={12}>
        <Button
          onClick={onSubmit(item)}
          className={classes.buySpades}
          disabled={item.disabled || !rate || isDisabledButton()}
        >
          Buy Using PKR
        </Button>
      </Grid>
    );

    if (!enabled || !validNetwork) {
      buyUsingPKR = null;
    }

    return (
      <div className={classes.buttonRoot}>
        {loading && !item.disabled ? (
          <Loading loadingText="Please wait..." />
        ) : (
          <Grid container alignItems="center">
            {buyUsingPKR}
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Button className={classes.buySpades} onClick={buyViaNuvei(item)}>
                Buy using Card
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  const renderDeals = () => {
    return (
      <Grid xs={12} item className={classes.dealsRoot}>
        <div className={clsx({ [classes.dealsContent]: isDesktop })}>
          <Typography className={classes.dealHeader}>BUY SPADES</Typography>
          <Grid
            spacing={4}
            alignItems="center"
            direction="column"
            container
            className={classes.dealsContainer}
          >
            {deals.map((item, index) => {
              return (
                <div className={classes.radioContainer}>
                  <input
                    id={index}
                    type="radio"
                    name="priceId"
                    value={item.value}
                    className={classes.radioButton}
                    onClick={() => onSelectBundle(index)}
                    autoComplete="off"
                  />
                  <div className={classes.dealsCard}>
                    <div className={classes.spadesPrice}>
                      <div className={classes.spadesRoot}>
                        <Typography
                          variant="h6"
                          className={classes.spadesValue}
                        >
                          {item.spades}
                        </Typography>
                        <Typography variant="h6" className={classes.spadesText}>
                          SPADES
                        </Typography>
                      </div>
                      <div className={classes.imageRoot}>
                        <LazyLoadImage
                          width={200}
                          height={185}
                          effect="blur"
                          visibleByDefault
                          className={classes.image}
                          src="/images/spades.png"
                        />
                        <div className={classes.priceRoot}>
                          <Typography
                            color="textPrimary"
                            className={classes.usd}
                          >
                            {item.value} USD
                          </Typography>
                        </div>
                        {renderBuyButtons(item)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Grid>
        </div>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        md
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <div className={classes.wallet}>
          <Wallet />
        </div>
        {renderBalance()}
        {renderDeals()}
      </Grid>
    </>
  );
};

export default BuySpades;
