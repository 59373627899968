import { get } from 'lodash';
import { requestAPI } from 'services/request';

const requestBrowsePKRPass = async ({ ...rest }) =>
  requestAPI('GET', '/pkr-pass/browse-pkr-pass', {}, { ...rest });

const requestBrowse = async ({ ...rest }) =>
  requestAPI('GET', '/pkr-pass/browse', {}, { ...rest });

const checkAPI = async ({ payload, ...rest }) =>
  requestAPI('GET', '/pkr-pass/check', payload, { ...rest });

const buyAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/pkr-pass/buy', payload, { ...rest });

const nuveiBuyAPI = async ({ payload, ...rest }) =>
  requestAPI('POST', '/nuvei/pkr-pass/buy', payload, { ...rest });

const syncAPI = async ({ ...rest }) =>
  requestAPI('POST', '/pkr-pass/sync', {}, { ...rest });

const checkPendingAPI = async ({ ...rest }) =>
  requestAPI('GET', '/pkr-pass/check-pending', {}, { ...rest });

const browsePKRPass = async () => {
  const res = await requestBrowsePKRPass({});
  return get(res, 'data', {});
};

const browse = async () => {
  const res = await requestBrowse({});
  return get(res, 'data', {});
};

const check = async passId => {
  const params = `?pkr_pass_id=${passId}`;
  const res = await checkAPI({ params });
  return get(res, 'data', {});
};

const buy = async payload => {
  const res = await buyAPI({ payload });
  return get(res, 'data', {});
};

const nuveiBuy = async payload => {
  const res = await nuveiBuyAPI({ payload });
  return get(res, 'data', {});
};

const sync = async () => {
  const res = await syncAPI({});
  return get(res, 'data', {});
};

const checkPending = async () => {
  const res = await checkPendingAPI({});
  return get(res, 'data', {});
};

export const PKR_PASS_API = {
  buy,
  sync,
  check,
  browse,
  nuveiBuy,
  browsePKRPass,
  checkPending,
};
