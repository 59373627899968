export default {
  root: {
    '& $notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover $notchedOutline': {
      borderColor: 'transparent',
    },
    '&$focused $notchedOutline': {
      borderColor: 'transparent',
    },
    color: '#fff4f4',
  },
};
