import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  statement: {
    margin: '16px 0',
    paddingBottom: 16,
  },
  buttonRoot: {
    display: 'flex',
    width: '100%',
    marginTop: 24,
    marginBottom: 8,
    '& > button:first-child': {
      marginRight: 4,
    },
    '& > button:last-child': {
      marginLeft: 4,
    },
  },
}));
