import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    position: 'relative',
    padding: theme.spacing(4),
  },
  grid: {
    height: '100%',
  },
  headerRoot: {
    paddingBottom: 32,
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 1,
    width: '100%',
    justifyContent: 'space-between',
  },
  networkSwitch: {
    width: '100%',
    display: 'flex',
    marginLeft: -32,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  note: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  connectedHeader: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 'bold',
  },
  network: {
    padding: '16px 16px 4px',
    fontWeight: 'bold',
  },
  address: {
    fontWeight: 'bold',
  },
  addressHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 88,
  },
  image: {
    display: 'inline-block',
    maxWidth: 900,
    width: '100%',
  },
  item: {
    alignItems: 'center',
    zIndex: 1,
  },
  card: {
    borderRadius: 4,
    minHeight: 120,
    boxShadow: '1px 1px 0 rgba(255,255,255,.1) inset',
    backgroundColor: '#be39a3',
    background: '#0f0f0f',
  },
  logoutButton: {
    color: '#e5b839',
    zIndex: 1,
  },
}));

export default useStyles;
